import { call, put, takeLatest } from "redux-saga/effects";
import { actions as RecipesAction } from '../../store/recipes/reducer';
import { callGetRecipes } from '../../api/recipes';

export function* getRecipes(action) {
  try {
    yield put({ type: "GET_RECIPES_REQUEST" });
    const response = yield call(callGetRecipes, action.page);

    action.onSuccess({
      page: Number(response.data.page),
      pages: Number(response.data.pages),
    });

    yield put(RecipesAction.setRecipes(response.data.results));
    yield put({ type: "GET_RECIPES_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_RECIPES_FAILURE",
      error: error.message
    });
  }
}


const authSaga = [
  takeLatest("GET_RECIPES", getRecipes),
];

export default authSaga;
