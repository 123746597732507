
export type TOptimizeImage = {
    common: string
    retina: string
}

export const useOptimizerImage = ({ common, retina }: TOptimizeImage) => (
    {
        backgroundImage: `-webkit-image-set(url("${common}") 1x, url("${retina}") 2x), url("${common}")`,
    }
)