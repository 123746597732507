import RecipeFunctions from "./recipeFunctions";
export const ShoppingListUnitConversionsTable = {
  tsp: {
    tbsp: 0.33,
    cup: 0.0208,
    tsp: 1,
    flOz: 0.166667,
    "fl oz": 0.166667,
    ml: 4.92892,
  },
  tbsp: {
    tsp: 3,
    tbsp: 1,
    cup: 0.0625,
    flOz: 0.5,
    "fl oz": 0.5,
    ml: 14.7868,
  },
  cup: {
    tsp: 48,
    tbsp: 16,
    cup: 1,
    flOz: 8,
    "fl oz": 8,
    ml: 237,
  },
  flOz: {
    cup: 0.125,
    tsp: 6,
    tbsp: 2,
    flOz: 1,
    ml: 29.5735,
    "fl oz": 1,
  },
  "fl oz": {
    cup: 0.125,
    tsp: 6,
    tbsp: 2,
    flOz: 1,
    ml: 29.5735,
    "fl oz": 1,
  },
  ml: {
    flOz: 0.033814,
    "fl oz": 0.033814,
    ml: 1,
    cup: 0.00422675,
    tbsp: 0.067628,
    tsp: 0.202884,
  },
};
export const ShoppingListUnitsEnum = {
  tsp: "tsp",
  tbsp: "tbsp",
  cup: "cup",
  whole: "whole",
  flOz: "fl oz",
  ml: "ml",
  oz: "oz",
};

export const NonConvertibleUnitsEnum = {
  can: "can",
  lbs: "lbs",
  inch: "inch",
  drops: "drops",
  flOz: "fl oz",
  ml: "ml",
  oz: "oz",
};

export const convertShoppingUnits = (amount, fromUnit, toUnit) => {
  if (fromUnit === toUnit) {
    return amount;
  } else if (
    fromUnit === ShoppingListUnitsEnum.whole ||
    fromUnit === ShoppingListUnitsEnum.oz
  ) {
    return -1;
  } else if (
    !ShoppingListUnitConversionsTable[fromUnit] ||
    !ShoppingListUnitConversionsTable[fromUnit][toUnit]
  ) {
    return -1;
  } else {
    return amount * ShoppingListUnitConversionsTable[fromUnit][toUnit];
  }
};
export const displayUnit = (ingredientAmount, unitName) => {
  if (
    unitName.toLowerCase() === "oz" ||
    NonConvertibleUnitsEnum.hasOwnProperty(unitName.toLowerCase())
  ) {
    return unitName;
  } else if (
    unitName === ShoppingListUnitsEnum.cup &&
    RecipeFunctions.roundToStandardFraction(ingredientAmount) > 1
  ) {
    return "cups";
  } else if (unitName === ShoppingListUnitsEnum.flOz) {
    return "fl oz";
  } else if (!ShoppingListUnitConversionsTable[unitName.toLowerCase()]) {
    return "";
  }
  return unitName;
};
export const roundValue = (value, decimals = 5) => {
  return parseFloat(value.toString()).toFixed(decimals);
};

export const getIngredientsListAmount = (amount, coef, measurementUnit) => {
  if (NonConvertibleUnitsEnum.hasOwnProperty(measurementUnit)) {
    return RecipeFunctions.getIngredientQuantity(amount, coef, measurementUnit);
  }

  if (
    !measurementUnit ||
    measurementUnit === "whole" ||
    measurementUnit === "oz" ||
    !ShoppingListUnitConversionsTable[measurementUnit.toLowerCase()]
  ) {
    return RecipeFunctions.getIngredientQuantity(
      amount,
      coef,
      displayUnit(
        amount,
        measurementUnit === "whole" || !measurementUnit ? "" : measurementUnit
      )
    );
  }

  const calcAmount = RecipeFunctions.calculateAmount(amount, coef, false);

  const mapper =
    ShoppingListUnitConversionsTable[measurementUnit.toLowerCase()];

  const cupAmount = roundValue(calcAmount * mapper.cup);
  if (cupAmount >= 0.249) {
    const cups = RecipeFunctions.roundToStandardFraction(cupAmount);
    const cupsQuantity = RecipeFunctions.getIngredientQuantity(
      cups,
      1,
      displayUnit(cups, "cup")
    );

    if (cupsQuantity) {
      return cupsQuantity;
    }
  }

  const tbspAmount = roundValue(calcAmount * mapper.tbsp);
  const tbsp = RecipeFunctions.roundToStandardFraction(tbspAmount);
  if (tbsp >= 1) {
    const tbspQuantity = RecipeFunctions.getIngredientQuantity(
      tbsp,
      1,
      displayUnit(tbsp, "tbsp")
    );

    if (tbspQuantity) {
      return tbspQuantity;
    }
  }
  const tspAmount = roundValue(calcAmount * mapper.tsp, 0);

  if (tspAmount >= 0.249) {
    const tsp = RecipeFunctions.roundToStandardFraction(tspAmount);
    const tspQuantity = RecipeFunctions.getIngredientQuantity(
      tsp,
      1,
      displayUnit(tsp, "tsp")
    );

    if (tspQuantity) {
      return tspQuantity;
    }
  }

  return "dash of";
};
