import { call, put, takeLatest } from "redux-saga/effects";
import { actions as PlansAction } from "../../store/plans/reducer";
import {
  callGetPlans,
  callGetAdminPlans,
  callDeletePlan,
  callGetCurrentWeekPlan,
  callSavePlan,
  callGetCustomPlan,
  callUpdatePlan,
  callGetGrocery,
  callgetAACPPlans,
  callSaveCustomAACPPlan
} from "../../api/plans";
import {
  googleAnalitics,
  CATEGORY_TYPES,
  ACTION_TYPES
} from "../../utils/GoogleAnalitics";

export function* getPlans(action) {
  try {
    yield put({ type: "GET_PLANS_REQUEST" });
    const response = yield call(callGetPlans, action.start, action.end);

    action.onGetPlans && action.onGetPlans(response.data);

    yield put(PlansAction.setPlans({ plans: [] }));
    yield put(PlansAction.setPlans({ plans: response.data.reverse() }));

    yield put({ type: "GET_PLANS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_PLANS_FAILURE",
      error: error.message
    });
  }
}

export function* getWeeklyPlans(action) {
  try {
    yield put(PlansAction.setWeeklyPlans({ plans: [] }));
    yield put({ type: "GET_WEEKLY_PLANS_REQUEST" });
    const response = yield call(callGetAdminPlans);

    action.onGetPlans && action.onGetPlans(response.data.adminPlans);
    yield put(PlansAction.setWeeklyPlans({ plans: response.data.adminPlans }));
    yield put({ type: "GET_WEEKLY_PLANS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_WEEKLY_PLANS_FAILURE",
      error: error.message
    });
  }
}

export function* getGroceryList(action) {
  try {
    yield put({ type: "GET_GROCERY_REQUEST" });
    const response = yield call(callGetGrocery, action.params);

    yield put(PlansAction.setGrocery({ grocery: response.data }));
    yield put({ type: "GET_GROCERY_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_GROCERY_FAILURE",
      error: error.message
    });
  }
}

export function* getPlan(action) {
  try {
    yield put({ type: "GET_PLAN_REQUEST" });

    const response = yield call(
      callGetCurrentWeekPlan,
      action.start,
      action.end
    );

    action.onCustomPlan && action.onCustomPlan(response.data._id);
    yield put(PlansAction.setCreatedFromAACP(response.data.createdFromAACP));
    if (response.data.mealPlans && response.data.mealPlans.length > 0) {
      yield put(PlansAction.setPlan({ data: response.data.mealPlans }));
    }
    yield put({ type: "GET_PLAN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_PLAN_FAILURE",
      error: error.message
    });
  }
}

export function* getCustomPlan(action) {
  try {
    yield put({ type: "GET_CUSTOM_PLAN_REQUEST" });

    const response = yield call(callGetCustomPlan, action.id);

    if (action.onSuccess) {
      action.onSuccess(
        response.data.name,
        response.data.start,
        response.data.end
      );
    }

    yield put(PlansAction.setPlan({ data: response.data.mealPlans }));
    yield put(PlansAction.setCreatedFromAACP(response.data.createdFromAACP));
    yield put({ type: "GET_CUSTOM_PLAN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_CUSTOM_PLAN_FAILURE",
      error: error.message
    });
  }
}

export function* savePlan(action) {
  try {
    yield put({ type: "SAVE_PLAN_REQUEST" });

    const response = yield call(callSavePlan, action.body);

    if (action.onSuccess) {
      action.onSuccess(response.data);
    }

    if (action.getMeals) {
      action.getMeals();
    }

    yield put({ type: "SAVE_PLAN_SUCCESS" });
    googleAnalitics.logEvent({
      category: CATEGORY_TYPES.plans,
      action: ACTION_TYPES.createPlan
    });
  } catch (error) {
    action.onError(error.message);
    console.log(error);

    yield put({
      type: "SAVE_PLAN_FAILURE",
      error: error.message
    });
  }
}

export function* updatePlan(action) {
  try {
    yield put({ type: "UPDATE_PLAN_REQUEST" });

    const response = yield call(
      callUpdatePlan,
      action.id,
      action.meals,
      action.name
    );

    if (action.onSuccess) {
      action.onSuccess();
    }

    if (action.getMeals) {
      action.getMeals();
    }

    yield put({ type: "UPDATE_PLAN_SUCCESS" });
  } catch (error) {
    action.onError(error.message);
    yield put({
      type: "UPDATE_PLAN_FAILURE",
      error: error.message
    });
  }
}

export function* deletePlan(action) {
  try {
    yield put({ type: "DELETE_PLAN_REQUEST" });
    const response = yield call(callDeletePlan, action.id);

    if (action.onSuccess) {
      action.onSuccess();
    }

    yield put({ type: "DELETE_PLAN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "DELETE_PLAN_FAILURE",
      error: error.message
    });
  }
}

export function* getAACPPlans(action) {
  try {
    yield put({ type: "GET_AACP_PLANS_REQUEST" });
    const response = yield call(callgetAACPPlans, action.slug);
    yield put(
      action.slug
        ? PlansAction.setCurrentAACPPlan(response.data[0])
        : PlansAction.setAACPPlans(response.data)
    );

    yield put({ type: "GET_AACP_PLANS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_AACP_PLANS_FAILURE",
      error: error.message
    });
  }
}

export function* saveCustomAACPPlan(action) {
  try {
    yield put({ type: "SAVE_OR_UPDATE_CUSTOM_AACP_PLAN_REQUEST" });
    const response = yield call(
      callSaveCustomAACPPlan,
      action.mealPlans,
      action.nameSlug
    );
    // yield put(action.slug ? PlansAction.setCurrentAACPPlan(response.data[0]) : PlansAction.setAACPPlans(response.data));
    action.onSuccess(response.data._id);
    yield put({ type: "SAVE_OR_UPDATE_CUSTOM_AACP_PLAN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "SAVE_OR_UPDATE_CUSTOM_AACP_PLAN_FAILURE",
      error: error.message
    });
  }
}

const authSaga = [
  takeLatest("GET_PLANS", getPlans),
  takeLatest("GET_WEEKLY_PLANS", getWeeklyPlans),
  takeLatest("GET_PLAN", getPlan),
  takeLatest("GET_CUSTOM_PLAN", getCustomPlan),
  takeLatest("SAVE_PLAN", savePlan),
  takeLatest("UPDATE_CUSTOM_PLAN", updatePlan),
  takeLatest("GET_GROCERY_LIST", getGroceryList),
  takeLatest("DELETE_PLAN", deletePlan),
  takeLatest("GET_AACP_PLANS", getAACPPlans),
  takeLatest("SAVE_OR_UPDATE_CUSTOM_AACP_PLAN", saveCustomAACPPlan)
];

export default authSaga;
