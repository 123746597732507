import { call } from "../core";

export const callGetUsers = (page: number, search: string) => {
  return call(`/user/list/${page}`, "GET", { params: { search }});
};

export const callGetReviews = (page: number) => {
  return call(`/review/list/${page}`, "GET", {});
};

export const callGetUsersStatistics = () => {
  return call('/country/stats', 'GET', {});
};
