import { useEffect } from "react";


export const useScript = (code: string) => {
  useEffect(() => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerText = code;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [code]);
};
