import React, { SyntheticEvent, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Layout from "../../components/LoginLayout";
import PageHeader from "../../components/PageHeader";
import Input from "../Input";
import Button from "../../components/Button";

import { isValidEmail } from "../../utils";
import check from "../../assets/images/common/check-w.svg";

type Props = {
  loading?: boolean;
  error?: string;
  sendResetLink: (email: string, onSuccess: () => void) => void;
};

type State = {
  email: string;
  errors: {
    email?: string | null;
  };
  success: boolean;
};

const Forgot = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    email: "",
    errors: {},
    success: false,
  });

  const setState = (newState: Partial<State>) => setCurrentState((prevState) => ({ ...prevState, ...newState }));

  const updateField = (e: SyntheticEvent<HTMLInputElement>, field: string) => {
    setState({
      [field]: e.currentTarget.value,
      errors: {},
    });
  };

  const validateFields = () => {
    const isEmailValid = isValidEmail(state.email);

    if (isEmailValid) return true;

    setState({ errors: { email: !isEmailValid ? "Email is invalid" : null }});
  };

  const onSuccess = () => setState({ success: true, });

  const resetRequest = () => {
    if (validateFields()) props.sendResetLink(state.email, onSuccess);
  };

  let actionButtonText = props.loading ? "Resetting..." : "Reset Password";
  if (state.success) actionButtonText = "Sent";

  return (
    <Layout>
      <div className="login-box login-form">
        <PageHeader title="Reset Password" />
        <Input
          disabled={props.loading}
          error={state.errors.email}
          label="Email"
          placeholder="Your Email"
          type="email"
          value={state.email}
          onChange={(e) => updateField(e, "email")}
        />
        <Button
          className="action-button"
          disabled={props.loading || state.success}
          primary={state.success}
          limited
          onClick={resetRequest}
        >
          {state.success && <img src={check} alt="Sent" />}
          {actionButtonText}
        </Button>
        <p className="error">{props.error}</p>
        <div className="form-buttons">
          <p>
            Back to <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading["POST_RESET"],
  error: state.error["POST_RESET"],
});

const mapDispatchToProps = (dispatch) => ({
  sendResetLink: (email: string, onSuccess: () => void) =>
    dispatch({ type: "REQUEST_RESET", email, onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
