import { combineReducers } from "redux";

import sessionReducer from "./session/reducer";
import loadingReducer from "./handlers/loading";
import errorReducer from "./handlers/error";
import userReducer from "./user/reducer";
import eventsReducer from "./events/reducer";
import articlesReducer from "./articles/reducer";
import enumReducer from "./enum/reducer";
import subscriptionsReducer from "./subscriptions/reducer";
import recipesReducer from "./recipes/reducer";
import rawkstarsReducer from "./rawkstars/reducer";
import plansReducer from "./plans/reducer";
import appReducer from "./app/reducer";
import ingredientsReducer from "./ingredients/reducer";
import crossSellssReducer from "./crossSells/reducer";

export default combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  session: sessionReducer,
  user: userReducer,
  events: eventsReducer,
  articles: articlesReducer,
  enum: enumReducer,
  subscriptions: subscriptionsReducer,
  recipes: recipesReducer,
  plans: plansReducer,
  rawkstars: rawkstarsReducer,
  app: appReducer,
  ingredients: ingredientsReducer,
  crossSells: crossSellssReducer,
});
