import CountrySelect from "forms/CountrySelect";
import Input from "forms/Input";
import React, { useState } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";

export interface ISignSelectProps {
  name: string;
  control: Control<any, any>;
  value?: string;
}

export function CardCountrySelect(props: ISignSelectProps) {
  const { name, control, value } = props;
  const [inputValue, setInputValue] = useState(value);

  const methods = useFormContext();
  const hasError = methods.formState.errors?.[name]?.message?.toString();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <>
            <CountrySelect
              value={inputValue}
              onChange={(country, code) => {
                methods.setValue(name, code);
                setInputValue(country);
                methods.trigger(name);
              }}
              placeholder={"Country"}
              className={`card_country_select__input ${
                hasError ? "card_country_select__input-error" : ""
              }`}
              onChangeInputValue={(value) => {
                setInputValue(value);
                methods.setValue(name, undefined);
              }}
            />
            <Input
              {...field}
              classNames={{ root: "card_country_select__input-hidden" }}
            />
          </>
        );
      }}
    />
  );
}
