import Layout from "components/Layout";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import GroceryList from "../MealPlan/components/GroceryList";
import Pagination from "containers/MealPlan/components/Pagination";
import RecipeViewModal from "containers/MealPlan/components/RecipeViewModal";
import Header from "./Components/Header";
import { useAppDispatch, useAppSelector } from "store/hooks";
import moment from "moment";
import Column from "containers/MealPlan/components/Column";
import AddRecipe from "../../containers/MealPlan/components/AddRecipeModal";
import SavePlanModal from "components/SavePlanModal";
import DeliveryPage from "containers/MealPlan/components/DeliveryPage";
import { actions as IngredientsActions } from "../../store/ingredients/reducer";
import { actions as PlansActions } from "../../store/plans/reducer";
import AcceptModal from "containers/MealPlan/components/AcceptModal";
import { callUpdateMealInPlan } from "api/plans";
import DeletePlanModal from "components/DeletePlanModal";
import { useMediaQuery } from "utils/customHooks/use-media-query";
import { paramCase } from "change-case";

type State = {
  id: string;
  titleSlug: string;
  name: string;
  nameSlug: string;
  loading: boolean;
  addRecipeVisible: boolean;
  shoppingCartVisible: boolean;
  deliveryPageVisible: boolean;
  savePlanModalVisible: boolean;
  successModalVisible: boolean;
  deleteModalVisible: boolean;
  acceptModalVisible: boolean;
  acceptModalTitle: unknown;
  acceptModalOnSuccess: Function;
  recipe: unknown;
  isEditMode: boolean;
  excludedIngredients: string[];
  desktopView: boolean;
  menuSavedPlans: any;
  menuWeeklyPlans: any;
  plansMenuOpen: boolean;
  needUpdate: boolean;
  needGetMeals: boolean;
  needManage: boolean;
  justMounted: boolean;
  isWeekPLan: boolean;
  addingCategory?: string;
  swappingId?: string;
  mealIndex?: number;
  movingId?: string;
  draggingId?: string;
  currentPage: number;
  saveModalTitle?: ReactNode;
  redirectId?: string | null;
};

const AacpModalText = (
  <div className="aacp-save">
    <p className="aacp-save_text">
      To modify a cleanse plan, you must save it first
    </p>
  </div>
);

const DefaultModalText = "What would you like to name this plan?";

const AACPMealPlan = ({}) => {
  const [state, setCurrentState] = useState<State>({
    id: "",
    titleSlug: "",
    name: "",
    nameSlug: "",
    loading: false,
    addRecipeVisible: false,
    shoppingCartVisible: false,
    deliveryPageVisible: false,
    savePlanModalVisible: false,
    successModalVisible: false,
    deleteModalVisible: false,
    acceptModalVisible: false,
    acceptModalTitle: null,
    acceptModalOnSuccess: null,
    recipe: null,
    isEditMode: false,
    excludedIngredients: [],
    desktopView: true,
    menuSavedPlans: [],
    menuWeeklyPlans: [],
    plansMenuOpen: false,
    needUpdate: false,
    needGetMeals: false,
    needManage: false,
    justMounted: true,
    isWeekPLan: false,
    addingCategory: undefined,
    swappingId: undefined,
    mealIndex: undefined,
    currentPage: 0,
    saveModalTitle: DefaultModalText,
    redirectId: null
  });
  const [errorModal, setErrorModal] = useState("");
  const isMobile = useMediaQuery("(max-width: 500px)");
  const onPageChange = (page: number) => {
    setCurrentState(prev => ({ ...prev, currentPage: page }));
  };

  const dispatch = useAppDispatch();
  const { currentAACP: plan, grocery, updateAACP } = useAppSelector(
    state => state.plans
  );
  const { isAACPActive } = useAppSelector(state => state.user);
  const excludedIngredients = useAppSelector(
    state => state.ingredients.excludedIngredients
  );
  const params = useParams();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (!isAACPActive) {
      history.push("/home");
    }
  }, []);

  useEffect(() => {
    if (updateAACP) {
      dispatch({
        type: "SAVE_OR_UPDATE_CUSTOM_AACP_PLAN",
        mealPlans: plan.mealPlans,
        nameSlug: plan.nameSlug,
        onSuccess: revalidatePlan
      });
      dispatch(PlansActions.resetAACPUpdate());
    }
  }, [updateAACP, plan]);

  useEffect(() => {
    revalidatePlan();
  }, []);

  const handleRecipeModalClose = (initialPortions, newPortions) => {};

  const week = useMemo(() => {
    const value = new Array(7).fill(0).map((_, i) =>
      moment(moment().startOf("isoWeek"))
        .add(i, "d")
        .startOf("d")
        .toString()
    );
    return value;
  }, []);

  const data = useMemo(() => {
    const days = {};

    if (plan) {
      [...plan.mealPlans].forEach(meal => {
        if (days[meal.index]) {
          days[meal.index].push(meal);
        } else {
          days[meal.index] = [meal];
        }
      });
    }

    return days;
  }, [plan]);

  const getColumnDates = useCallback(index => {
    return moment
      .utc()
      .weekday(index + 1)
      .format("MM/DD/YYYY");
  }, []);

  const openAddRecipe = (index: number) => (date, category: string) => {
    if (!plan.isAACPSpecial) {
      openSavePlanModal(AacpModalText);
    } else {
      setCurrentState(prev => ({
        ...prev,
        addRecipeVisible: true,
        addingCategory: category,
        mealIndex: index
      }));
    }
  };

  const closeAddRecipe = () => {
    setCurrentState(prev => ({
      ...prev,
      addRecipeVisible: false,
      addingCategory: undefined,
      swappingId: undefined,
      mealIndex: undefined
    }));
  };

  const goRecipePage = (id: string, e) => {
    e.preventDefault();
    e.stopPropagation();

    const win = window.open(`/recipes/${id}`, "_blank");
    win.focus();
  };

  const openSwapRecipe = (id: string) => {
    setCurrentState(prev => ({
      ...prev,
      addRecipeVisible: true,
      swappingId: id
    }));
  };

  const revalidatePlan = () => {
    dispatch({ type: "GET_AACP_PLANS", slug: params.id });
    getGrocery();
  };

  const closeSavePlanModal = () =>
    setCurrentState(prev => ({
      ...prev,
      savePlanModalVisible: false
    }));

  const openSavePlanModal = (title?: ReactNode) =>
    setCurrentState(prev => ({
      ...prev,
      savePlanModalVisible: true,
      saveModalTitle: (!(title as any).target && title) || DefaultModalText
    }));

  const closeSuccessModal = () => {
    setCurrentState(prev => ({
      ...prev,
      successModalVisible: false,
      redirectId: null
    }));
    closeSavePlanModal();
    history.replace(`/plans/${state.redirectId}`);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (state.successModalVisible) {
      timer = setTimeout(closeSuccessModal, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [state.successModalVisible]);

  const openDeliveryPage = () => {
    setCurrentState(prev => ({
      ...prev,
      deliveryPageVisible: true
    }));
  };

  const closeDeliveryPage = () => {
    setCurrentState(prev => ({
      ...prev,
      deliveryPageVisible: false
    }));
  };

  const openShoppingCart = () => {
    setCurrentState(prev => ({
      ...prev,
      shoppingCartVisible: true
    }));
  };

  const openDeleteModal = () => {
    setCurrentState(prev => ({
      ...prev,
      deleteModalVisible: true
    }));
  };

  const closeDeleteModal = () => {
    setCurrentState(prev => ({
      ...prev,
      deleteModalVisible: false
    }));
  };

  const closeShoppingCart = () => {
    setCurrentState(prev => ({
      ...prev,
      shoppingCartVisible: false
    }));
  };

  const deletePlan = () => {
    dispatch({
      type: "DELETE_PLAN",
      id: plan.nameSlug,
      onSuccess: () => history.push("/plans")
    });
  };

  const setExcludedIngredients = ingredients => {
    const ing = { ...excludedIngredients };
    ing[params.id] = ingredients;
    dispatch(
      IngredientsActions.setExcludedIngredients({ excludedIngredients: ing })
    );
    setCurrentState(prev => ({
      ...prev,
      excludedIngredients: ingredients
    }));
  };

  const getGrocery = () => {
    dispatch({
      type: "GET_GROCERY_LIST",
      params: {
        nameSlug: params.id,
        isAACP: true
      }
    });
  };

  const addRecipe = recipe => {
    let planMeals = [];
    if (state.swappingId) {
      const updatedMeals = plan.mealPlans.map(el => {
        if (el._id === state.swappingId) {
          return {
            ...el,
            recipe
          };
        }
        return el;
      });
      dispatch({
        type: "SAVE_OR_UPDATE_CUSTOM_AACP_PLAN",
        mealPlans: updatedMeals,
        nameSlug: plan.nameSlug,
        onSuccess: revalidatePlan
      });
    } else {
      const meal = {
        portions: recipe?.portions || 1,
        recipe,
        category: state.addingCategory,
        index: state.mealIndex
      };

      planMeals = [...plan.mealPlans, meal];
      dispatch({
        type: "SAVE_OR_UPDATE_CUSTOM_AACP_PLAN",
        mealPlans: planMeals,
        nameSlug: plan.nameSlug,
        onSuccess: revalidatePlan
      });
    }

    closeAddRecipe();
  };

  const openSuccessModal = () => {
    setCurrentState(prev => ({
      ...prev,
      successModalVisible: true
    }));
  };

  const onSuccessUpdate = data => {
    setCurrentState(prev => ({ ...prev, redirectId: data.nameSlug }));
    openSuccessModal();
  };

  const savePlan = (name: string) => {
    dispatch({
      type: "SAVE_PLAN",
      body: {
        name: name,
        start: moment().format("MM/DD/YYYY"),
        end: moment().format("MM/DD/YYYY"),
        mealPlans: [...plan.mealPlans].map(plan => ({
          ...plan,
          _id: undefined,
          recipe: plan.recipe._id
        })),
        createdFromAACP: true
      },
      onSuccess: onSuccessUpdate,
      getMeals: () => {},
      onError: error => setErrorModal(error)
    });
  };

  const handleRemoveMeal = (id: string) => {
    const mealPlans = plan.mealPlans.filter(el => el._id !== id);
    dispatch({
      type: "SAVE_OR_UPDATE_CUSTOM_AACP_PLAN",
      mealPlans,
      nameSlug: plan.nameSlug,
      onSuccess: revalidatePlan
    });
  };

  const goToRecipePage = async (mealPlan, portions) => {
    if (plan.isAACPSpecial) {
      dispatch(PlansActions.setSelectedMeal({ meal: mealPlan }));
      dispatch(PlansActions.setSelectedPlan({ id: plan._id }));
      dispatch(PlansActions.setMealPortions({ count: mealPlan.portions }));
      history.push(`/recipes/${paramCase(mealPlan.recipe.title)}`);
    } else {
      dispatch({
        type: "SAVE_OR_UPDATE_CUSTOM_AACP_PLAN",
        mealPlans: plan.mealPlans,
        nameSlug: plan.nameSlug,
        onSuccess: (id: string) => {
          dispatch(PlansActions.setSelectedPlan({ id }));
          dispatch(PlansActions.setSelectedMeal({ meal: mealPlan }));
          dispatch(PlansActions.setMealPortions({ count: mealPlan.portions }));
          revalidatePlan();
          history.push(`/recipes/${paramCase(mealPlan.recipe.title)}`);
        }
      });
    }
  };

  const acceptMove = (date, category, currentMeal, newIndex) => {
    if (!plan.isAACPSpecial) {
      openSavePlanModal(AacpModalText);
    } else {
      dispatch(
        PlansActions.moveAACPMeal({
          id: state.draggingId || state.movingId,
          date: moment(date).format("MM/DD/YYYY"),
          category,
          currentMeal,
          newIndex
        })
      );

      setCurrentState(prev => ({
        ...prev,
        movingId: null,
        draggingId: null
      }));
    }
  };

  const turnMovingMode = id => {
    setCurrentState(prev => ({
      ...prev,
      movingId: id
    }));
  };

  const turnDraggingMode = id => {
    setCurrentState(prev => ({
      ...prev,
      draggingId: id
    }));
  };

  const closeAcceptModal = () => {
    setCurrentState(prev => ({
      ...prev,
      acceptModalVisible: false,
      acceptModalTitle: null,
      acceptModalOnSuccess: null,
      draggingId: null
    }));
  };

  const onAcceptModalSuccess = () => {
    state.acceptModalOnSuccess();
    closeAcceptModal();
  };

  const showAcceptModal = (title, onSuccess, targetId) => {
    if (targetId === state.draggingId) {
      return;
    }

    setCurrentState(prev => ({
      ...prev,
      acceptModalVisible: true,
      acceptModalTitle: title,
      acceptModalOnSuccess: onSuccess
    }));
  };

  const updatePortions = async (id: string, count: number) => {
    if (!plan.isAACPSpecial) {
      openSavePlanModal(AacpModalText);
    } else {
      dispatch(PlansActions.updateAACPPortions({ id, count }));
      setExcludedIngredients([]);
      await callUpdateMealInPlan(
        plan.nameSlug || plan._id || match.params.id,
        id,
        { portions: count }
      );
    }
  };

  const onTooltipClick = (cb: () => void) => {
    if (!plan.isAACPSpecial) {
      openSavePlanModal(AacpModalText);
    } else {
      cb();
    }
  };

  return (
    <>
      <Layout
        noScroll={!!state.recipe}
        wide
        lessPadding
        close={state.deliveryPageVisible || state.shoppingCartVisible}
        withoutSpacing={state.shoppingCartVisible && isMobile}
        full={state.shoppingCartVisible && isMobile}
      >
        <ToastContainer
          position="bottom-right"
          autoClose={500}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          className="toast-container-img"
          toastClassName="toast"
          limit={1}
        />

        {state.shoppingCartVisible ? (
          <GroceryList
            close={closeShoppingCart}
            openDeliveryPage={openDeliveryPage}
            date={""}
            list={grocery}
            planId={plan.nameSlug}
            excludedIngredients={state.excludedIngredients}
            setExcludedIngredients={setExcludedIngredients}
            visible={state.deliveryPageVisible}
          />
        ) : state.deliveryPageVisible ? (
          <DeliveryPage
            close={closeDeliveryPage}
            date={""}
            list={grocery}
            start={""}
            end={""}
            visible={state.deliveryPageVisible}
            planId={plan.nameSlug}
            excludedIngredients={state.excludedIngredients}
            isAACP={true}
          />
        ) : (
          <>
            {state.recipe && (
              <RecipeViewModal
                recipe={state.recipe}
                onClose={handleRecipeModalClose}
              />
            )}
            <Header
              name={plan ? plan.name : ""}
              openSavePlanModal={openSavePlanModal}
              openDeliveryPage={openDeliveryPage}
              openShoppingCart={openShoppingCart}
              openDeleteModal={openDeleteModal}
              openEditModal={openSavePlanModal}
              isCustomPlan={plan?.isAACPSpecial}
              onPageChange={onPageChange}
            />
            <Pagination
              className="plan-grid"
              plansMenuOpen={state.plansMenuOpen}
              outerCurrentPage={state.currentPage}
            >
              {week.map((date, index) => {
                return (
                  <Column
                    onShowRecipe={(recipe, portions) => {
                      goToRecipePage(recipe, portions);
                    }}
                    key={date}
                    data={data[index] || []}
                    date={getColumnDates(index)}
                    index={index}
                    onAddRecipe={openAddRecipe(index)}
                    onSwapClick={openSwapRecipe}
                    omMoveClick={turnMovingMode}
                    onRemoveClick={handleRemoveMeal}
                    updatePortions={updatePortions}
                    movingId={state.movingId}
                    acceptMove={acceptMove}
                    draggingId={state.draggingId}
                    onDragStart={turnDraggingMode}
                    showAcceptModal={showAcceptModal}
                    onTooltipClick={onTooltipClick}
                  />
                );
              })}
            </Pagination>
          </>
        )}
      </Layout>
      <AddRecipe
        visible={state.addRecipeVisible}
        close={closeAddRecipe}
        onInfoClick={goRecipePage}
        onRecipeClick={addRecipe}
      />
      <SavePlanModal
        title={state.saveModalTitle}
        isCustomPlan={!!state.name}
        classNames={{ title: "create-plan-title" }}
        isCreatePlan
        visible={state.savePlanModalVisible}
        onSave={name => savePlan(name)}
        close={closeSavePlanModal}
        currentName={state.name}
        confirmRequired={true}
        error={errorModal}
        setError={setErrorModal}
        closeSuccess={closeSuccessModal}
        visibleSuccess={state.successModalVisible}
        isAACP={true}
      />
      <AcceptModal
        close={closeAcceptModal}
        onSuccess={onAcceptModalSuccess}
        visible={state.acceptModalVisible}
        title={state.acceptModalTitle}
      />
      <DeletePlanModal
        visible={state.deleteModalVisible}
        onDelete={deletePlan}
        close={closeDeleteModal}
      />
    </>
  );
};

export default AACPMealPlan;
