import React, { useState, useEffect } from "react";

import info from "../../../../assets/images/common/info-w.svg";
import favourite from "../../../../assets/images/common/heart.svg";
import favouriteEmpty from "../../../../assets/images/common/heart-empty.svg";
import star from "../../../../assets/images/common/star-b.svg";
import clock from "../../../../assets/images/common/clock.svg";
import cooking from "../../../../assets/images/common/cooking.svg";
import toggleFavorite from "../../../../utils/toggleFavorite";
import { IImages } from "api/recipes/type";
import { useOptimizerImage } from "utils/useOptimizerImage";

export type Props = {
  _id: string;
  images: IImages;
  title: string;
  avgRating: number;
  cookingCnt: number;
  goRecipePage: () => void;
  withTooltip?: boolean;
  favorite: boolean;
  onInfoClick: any;
  onClick: any;
  time: number;
  reviewsAmount: number;
  createdAt: number
};

const RecipeCard = (props: Props) => {
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    setIsFavorite(props.favorite);
  }, []);

  const toggleFavourite = async e => {
    e.preventDefault();
    e.stopPropagation();

    const result = await toggleFavorite(isFavorite, props._id);
    setIsFavorite(result);
  };

  const optimizedImageLink = useOptimizerImage({
    common: props?.images?.square,
    retina: props?.images?.squareX2
  });

  return (
    <div className="recipe-card" onClick={props.onClick}>
      <div className="image" style={optimizedImageLink}>
        {!props.withTooltip && (
          <button className="info-button" onClick={props.onInfoClick}>
            <img src={info} alt="Info" />
          </button>
        )}
      </div>
      <div className="info-container">
        <div className="info">
          <p className="title">{props.title}</p>
          {/* <img
            className="heart"
            src={isFavorite ? favourite : favouriteEmpty}
            alt="Favourite"
            onClick={toggleFavourite}
          /> */}
        </div>
        <div className="statistics">
          <div className="element">
            <img src={clock} alt="Star" />
            <p className="text-muted">{props.time} MIN</p>
          </div>
          <div className="element">
            <img src={star} alt="Star" />
            <p className="text-muted">{props.avgRating} ({props.reviewsAmount})</p>
          </div>
          {!props.withTooltip && (
            <button className="info-button" onClick={props.onInfoClick}>
              <img src={info} alt="Info" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipeCard;
