import React, { useState } from "react";

import Input from "../Input";

const PasswordInput = (props) => {
  const [visible, setIsVisible] = useState(false);

  const toggleVisibility = (e) => {
    e.preventDefault();
    setIsVisible(!visible);
  };

  return (
    <Input
      {...props}
      type={visible ? "text" : "password"}
      postfix={
        <button className="password-button" onClick={toggleVisibility}>
          {visible ? "Hide" : "Show"}
        </button>
      }
    />
  );
};

export default PasswordInput;
