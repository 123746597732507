// @ts-nocheck
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StickyContainer } from "react-sticky";
import { paramCase } from "change-case";

import { actions as PlansActions } from "../../store/plans/reducer";
import { actions as AppActions } from "../../store/app/reducer";
import { actions as IngredientsActions } from "../../store/ingredients/reducer";
import Layout from "../../components/Layout";
import { store } from "../../store";

import Header from "./components/Header";
import Column from "./components/Column";

import Pagination from "./components/Pagination";
import AddRecipe from "./components/AddRecipeModal";
import AcceptModal from "./components/AcceptModal";
import DeletePlanModal from "../../components/DeletePlanModal";
import {
  callUpdateCurrentWeekPlan,
  callUpdateDraftPlan,
  callUpdateMealInPlan,
  callUpdatePlanPart
} from "../../api/plans";
import { monthsObject } from "../../utils/index";
import tosterIcon from "../../assets/images/toast.png";

import RecipeViewModal from "./components/RecipeViewModal";
import { withRouter } from "react-router";
import GroceryList from "./components/GroceryList";
import DeliveryPageModal from "./components/DeliveryPage";
import SavePlanModal from "../../components/SavePlanModal";
import { useScript } from "utils/useScript";
import { useMediaQuery } from "utils/customHooks/use-media-query";

type Props = {
  meals: any[];
  match: any;
  history: any;
  getGrocery: any;
  getPlan: any;
  getCustomPlan: any;
  swapRecipe: any;
  setPlan: any;
  setCreatedPlanId;
  updateCustomPlan: any;
  addMeal: any;
  savingPlan: any;
  loadingPlan: boolean;
  updatePortions: any;
  removeMeal: any;
  plan: any;
  savePlan: any;
  deletingPlan: any;
  deleteCustomPlan: any;
  gettingGrocery: any;
  [key: string]: any;
};

type State = {
  id: string;
  titleSlug: string;
  name: string;
  nameSlug: string;
  loading: boolean;
  start: number;
  end: number;
  addRecipeVisible: boolean;
  shoppingCartVisible: boolean;
  deliveryPageVisible: boolean;
  savePlanModalVisible: boolean;
  successModalVisible: boolean;
  deleteModalVisible: boolean;
  acceptModalVisible: boolean;
  acceptModalTitle: unknown;
  acceptModalOnSuccess: unknown;
  recipe: unknown;
  isEditMode: boolean;
  excludedIngredients: string[];
  desktopView: boolean;
  menuSavedPlans: any;
  menuWeeklyPlans: any;
  plansMenuOpen: boolean;
  needUpdate: boolean;
  needGetMeals: boolean;
  needManage: boolean;
  justMounted: boolean;
  isWeekPLan: boolean;
  currentPage: number;
};

const MealPlan = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    id: "",
    titleSlug: "",
    name: "",
    nameSlug: "",
    loading: false,
    start: null,
    end: null,
    addRecipeVisible: false,
    shoppingCartVisible: false,
    deliveryPageVisible: false,
    savePlanModalVisible: false,
    successModalVisible: false,
    deleteModalVisible: false,
    acceptModalVisible: false,
    acceptModalTitle: null,
    acceptModalOnSuccess: null,
    recipe: null,
    isEditMode: false,
    excludedIngredients: [],
    desktopView: true,
    menuSavedPlans: [],
    menuWeeklyPlans: [],
    plansMenuOpen: false,
    needUpdate: false,
    needGetMeals: false,
    needManage: false,
    justMounted: true,
    isWeekPLan: false,
    currentPage: 0
  });
  const [data, setData] = useState({});
  const [hasSlug, setHasSlug] = useState(false);
  const [errorModal, setErrorModal] = useState("");
  const setState = (newState: Partial<State>, cb?: any) => {
    setCurrentState(prevState => {
      const currentState = { ...prevState, ...newState };
      if (cb) cb(currentState);
      return currentState;
    });
  };
  const isMobile = useMediaQuery("(max-width: 500px)");

  useScript(
    `var _learnq = _learnq || [];var page = window.location.href;_learnq.push(['track', 'Viewed Plans', {url: 'https://rawktheyear.com/plans/${props.match.params.id}'}]);`
  );

  useEffect(() => {
    const addingDays = props.match.params.id === "next-week" ? 7 : 0;
    let start = moment()
      .utc()
      .startOf("isoWeek")
      .add(addingDays, "days");
    let end = moment()
      .utc()
      .endOf("isoWeek")
      .add(addingDays, "days");
    const daysSubstract = props.history.location.state
      ? props.history.location.state.daysSubstract
      : 0;
    setState({
      start: start.subtract(7 * daysSubstract, "days"),
      end: end.subtract(7 * daysSubstract, "days")
    });

    if (props.match.params.id === "create") {
      return;
    }

    const slugParams = props.match.params.id.split("-");
    if (
      monthsObject.hasOwnProperty(slugParams[0].toLowerCase()) &&
      slugParams[slugParams.length - 1].length < 6
    ) {
      const startDateString = `${
        slugParams[1]
      }-${slugParams[0].toLowerCase()}-20${slugParams[2]}`;
      start = moment(startDateString.toUpperCase(), "DD-MMM-YYYY");
      end = moment(startDateString.toUpperCase(), "DD-MMM-YYYY").add(6, "days");
      setState({
        start,
        end,
        isWeekPLan: true
      });
    }
    setState({
      desktopView: window.innerWidth > 1200,
      justMounted: false
    });
    getMeals(start, end);
    props.excludedIngredients &&
      setState({
        excludedIngredients:
          props.excludedIngredients[props.match.params.id] || []
      });
    updatePlans();
  }, []);

  const indexedData = useMemo(() => {
    const days = {};

    if (props.plan) {
      [...props.plan].forEach(meal => {
        if (days[meal.index]) {
          days[meal.index].push(meal);
        } else {
          days[meal.index] = [meal];
        }
      });
    }

    return days;
  }, [props.plan]);

  useEffect(() => {
    const getData = getTransformedData();

    setData(getData);
    if (
      !state.justMounted &&
      state.needManage &&
      state.isWeekPLan &&
      props.plan.length
    ) {
      saveUpdatedMealPlans(props.plan);
      setState({
        needGetMeals: true,
        needManage: false
      });
    }
  }, [props.plan]);

  useEffect(() => {
    if (!state.id) {
      return;
    }
    getGrocery();
  }, [state.id]);

  useEffect(() => {
    if (!state.justMounted && !state.draggingId && props.plan.length) {
      saveUpdatedMealPlans(props.plan);
    }
  }, [state.draggingId]);

  useEffect(() => {
    if (!state.justMounted && state.needUpdate && props.plan.length) {
      saveUpdatedMealPlans(props.plan);
    }
    setState({
      needUpdate: false
    });
  }, [state.needUpdate]);

  useEffect(() => {
    state.needGetMeals && getMeals(state.start, state.end);
    setState({
      needGetMeals: false
    });
  }, [state.needGetMeals]);

  const updatePlans = () => {
    const start = moment().startOf("isoWeek");
    const end = moment().endOf("isoWeek");

    props.getPlans(
      start.format("MM/DD/YYYY"),
      end.format("MM/DD/YYYY"),
      setSavedPLans
    );
    props.getWeeklyPlans(setWeeklyPLans);
  };

  const setSavedPLans = plans => {
    setState({
      menuSavedPlans: plans ? plans.filter(plan => plan.name) : []
    });
  };

  const setWeeklyPLans = plans => {
    setState({
      menuWeeklyPlans: plans
        ? plans.map(plan => {
            return {
              ...plan,
              title: transformDate(
                moment(plan.start).utc(),
                moment(plan.end).utc()
              ),
              dateSlug: getWeekPlanNameSlug(plan.start)
            };
          })
        : []
    });
  };

  const getWeekPlanNameSlug = startTime => {
    const start = moment.utc(startTime);
    return `${start.format("MMM-DD-YY")}`;
  };

  const setCustomId = id => {
    setState({
      id
    });
    // if (!id) {
    //   setState({
    //     needManage: true
    //   });
    // }
  };

  const getMeals = (start, end) => {
    const slugParams = props.match.params.id.split("-");
    if (
      ["this-week", "next-week"].includes(props.match.params.id) ||
      (monthsObject.hasOwnProperty(slugParams[0].toLowerCase()) &&
        slugParams[slugParams.length - 1].length < 6)
    ) {
      setHasSlug(false);

      props.getPlan(
        start.format("MM/DD/YYYY"),
        end.format("MM/DD/YYYY"),
        setCustomId
      );
    } else {
      setHasSlug(true);

      props.getCustomPlan(
        props.match.params.id,
        (name, startCustom, endCustom) => {
          setName(name);
          setState({ start: moment(startCustom), end: moment(endCustom) });
        }
      );
    }
    getGrocery(start, end);
  };

  const goToPlan = plan => {
    const id = plan.name ? plan.nameSlug : plan.dateSlug;
    const start = plan.name
      ? moment()
          .utc()
          .startOf("isoWeek")
      : moment(plan.start).utc();
    const end = plan.name
      ? moment()
          .utc()
          .endOf("isoWeek")
      : moment(plan.end).utc();
    props.history.push(`/plans/${id}`);
    setState({
      name: plan.name || "",
      nameSlug: plan.nameSlug || "",
      start,
      end,
      needGetMeals: true
    });
  };

  const changeWeek = action => {
    const start = moment(state.start)[action](7, "day");
    const end = moment(state.end)[action](7, "day");

    setState(
      {
        start,
        end
      },
      () => {
        if (props.match.params.id === "create") {
          return;
        }

        getMeals(start, end);
      }
    );
    replaceUrlParams(start.format("MMM-DD-YY"));
  };

  const getGrocery = (startMoment, endMoment) => {
    const start = startMoment || state.start;
    const end = endMoment || state.end;
    const slugParams = props.match.params.id.split("-");

    if (
      props.match.params.id !== "this-week" &&
      !monthsObject.hasOwnProperty(slugParams[0].toLowerCase()) &&
      !slugParams[slugParams.length - 1].length < 6 &&
      props.match.params.id !== "create"
    ) {
      props.getGrocery({
        nameSlug: state.nameSlug || props.match.params.id,
        // start: start.format("MM/DD/YYYY"),
        // end: end.format("MM/DD/YYYY")
      });
    } else {
      props.getGrocery({
        start: start.format("MM/DD/YYYY"),
        end: end.format("MM/DD/YYYY")
      });
    }
  };

  const getTransformedDate = () => {
    if (state.start && state.end) {
      return transformDate(state.start, state.end);
    }
  };

  const transformDate = (startDate, endDate) => {
    let start = startDate.format("MMM D");
    let end = endDate.format("D");

    if (startDate.format("MMM") !== endDate.format("MMM")) {
      start = startDate.format("MMM D ");
      end = endDate.format(" MMM D");
    }
    return `${start} - ${end}`;
  };

  const getTransformedData = () => {
    const days = {};
    [...props.plan].forEach(meal => {
      let date = meal.date;

      if (typeof date === "number") {
        date = moment(meal.date)
          .utc()
          .format("MM/DD/YYYY");
      }

      if (days[date]) {
        days[date].push(meal);
      } else {
        days[date] = [meal];
      }
    });

    return days;
  };

  const openAddRecipe = (index: number) => (date, category) => {
    setState({
      addRecipeVisible: true,
      addingDate: date,
      addingCategory: category,
      mealIndex: index
    });
  };

  const addRecipe = recipe => {
    let planMeals = [];
    if (state.swappingId) {
      props.swapRecipe(state.swappingId, recipe);
    } else {
      const meal = {
        _id: state.addingCategory + Math.random() * 1000000000,
        portions: recipe?.portions || 1,
        recipe,
        date: state.addingDate,
        category: state.addingCategory,
        index: state.mealIndex
      };

      props.addMeal(meal);
      planMeals = [...props.plan, meal];
    }
    planMeals.length
      ? saveUpdatedMealPlans(planMeals)
      : setState({ needUpdate: true });
    closeAddRecipe();
  };

  const openSwapRecipe = id => {
    setState({
      addRecipeVisible: true,
      swappingId: id
    });
  };

  const closeAddRecipe = () => {
    setState({
      addRecipeVisible: false,
      addingDate: null,
      addingCategory: null,
      swappingId: null,
      mealIndex: undefined
    });
  };

  const openShoppingCart = () => {
    setState({
      shoppingCartVisible: true
    });
  };

  const closeShoppingCart = () => {
    setState({
      shoppingCartVisible: false
    });
  };

  const openDeliveryPage = () => {
    setState({
      deliveryPageVisible: true
    });
  };

  const closeDeliveryPage = () => {
    setState({
      deliveryPageVisible: false
    });
  };

  const openSavePlanModal = () => {
    const slugParams = props.match.params.id.split("-");
    if (
      ["this-week", "create", "next-week"].includes(props.match.params.id) ||
      (monthsObject.hasOwnProperty(slugParams[0].toLowerCase()) &&
        slugParams[slugParams.length - 1].length < 6)
    ) {
      setState({
        savePlanModalVisible: true
      });
    } else {
      savePlan();
    }
  };

  const closeSavePlanModal = () => {
    setState({
      savePlanModalVisible: false
    });
  };

  const savePlan = (name, planMeals?) => {
    let callback = () => null;
    const slugParams = props.match.params.id.split("-");
    if (
      ["this-week", "create", "next-week"].includes(props.match.params.id) ||
      (monthsObject.hasOwnProperty(slugParams[0].toLowerCase()) &&
        slugParams[slugParams.length - 1].length < 6)
    ) {
      // setState({
      //   start: moment()
      //     .utc()
      //     .startOf("isoWeek"),
      //   end: moment()
      //     .utc()
      //     .endOf("isoWeek"),
      // });
      props.savePlan(
        {
          name: name,
          start: state.start.format("MM/DD/YYYY"),
          end: state.end.format("MM/DD/YYYY"),
          mealPlans: [...props.plan].map(plan => ({
            ...plan,
            _id: undefined,
            recipe: plan.recipe._id
          }))
        },
        onSuccessSave,
        callback,
        error => setErrorModal(error)
      );
    } else {
      const updatedPlan = planMeals || props.plan;
      props.updateCustomPlan(
        props.match.params.id,
        [...updatedPlan].map(plan => ({
          ...plan,
          _id: undefined,
          recipe: plan.recipe._id
        })),
        name || state.name,
        onSuccessUpdate,
        callback,
        error => setErrorModal(error)
      );
      setState({ name: name || state.name });
      replacePlanId(name || state.name);
    }
  };

  const replacePlanId = name => {
    const nameSlug = props.match.params.id;
    const slug = nameSlug.slice(-7);
    const newNameSlug = paramCase(name) + slug;
    replaceUrlParams(newNameSlug);
  };

  const onSuccessSave = plan => {
    replaceUrlParams(plan.nameSlug);

    setState({
      id: plan.nameSlug,
      nameSlug: plan.nameSlug,
      name: plan.name,
      needGetMeals: true
    });
    updatePlans();
    openSuccessModal();
  };

  const onSuccessUpdate = hideToast => {
    updatePlans();
    setState({ needGetMeals: true });
    openSuccessModal();
  };

  const handleCustomPlanFetch = name => {
    setName(name);
    getGrocery();
  };

  const setName = name => {
    setState({
      name
    });
  };

  const replaceUrlParams = id => {
    props.history.replace(`/plans/${id}`);
  };

  const goRecipePage = (id: string, e) => {
    e.preventDefault();
    e.stopPropagation();

    const win = window.open(`/recipes/${id}`, "_blank");
    win.focus();
  };

  const turnDraggingMode = id => {
    setState({
      draggingId: id
    });
  };

  const disableDraggingMode = id => {
    setState({
      draggingId: null
    });
  };

  const showAcceptModal = (title, onSuccess, targetId) => {
    if (targetId === state.draggingId) {
      return;
    }

    setState({
      acceptModalVisible: true,
      acceptModalTitle: title,
      acceptModalOnSuccess: onSuccess
    });
  };

  const closeAcceptModal = () => {
    setState({
      acceptModalVisible: false,
      acceptModalTitle: null,
      acceptModalOnSuccess: null
    });

    disableDraggingMode();
  };

  const onAcceptModalSuccess = () => {
    state.acceptModalOnSuccess();
    closeAcceptModal();
  };

  const turnMovingMode = id => {
    setState({
      movingId: id
    });
  };

  const acceptMove = (date, category, currentMeal, newIndex) => {
    props.moveMeal(
      state.draggingId || state.movingId,
      moment(date).format("MM/DD/YYYY"),
      category,
      currentMeal,
      newIndex
    );

    setState({
      movingId: null,
      draggingId: null
    });
  };

  const openSuccessModal = () => {
    setState({
      successModalVisible: true,
    });
  };

  const closeSuccessModal = () => {
    setState({
      successModalVisible: false,
      savePlanModalVisible: false
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (state.successModalVisible) {
      timer = setTimeout(closeSuccessModal, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [state.successModalVisible]);

  const openDeleteModal = () => {
    setState({
      deleteModalVisible: true
    });
  };

  const closeDeleteModal = () => {
    setState({
      deleteModalVisible: false
    });
  };

  const openEditModal = () => {
    setState({
      savePlanModalVisible: true,
      isEditMode: state.name ? true : false
    });
  };

  const closeEditModal = () => {
    setState({
      savePlanModalVisible: false,
      isEditMode: false
    });
  };

  const deletePlan = () => {
    props.deleteCustomPlan(props.match.params.id, () =>
      props.history.push("/plans")
    );
  };

  const saveNewName = e => {
    e.target.innerHTML = state.name;
  };

  const handleKeyPress = e => {
    if (e.key === "Enter" && e.target.innerHTML) {
      e.preventDefault();
      callUpdatePlanPart(props.match.params.id, { name: e.target.innerHTML });

      setState({
        name: e.target.innerHTML
      });
      e.target.blur();
    }
  };

  const handlePortionsUpdate = async (id, count) => {
    props.updatePortions(id, count);
    setExcludedIngredients([]);
    await callUpdateMealInPlan(
      state.nameSlug || state.id || props.match.params.id,
      id,
      { portions: count }
    );

    getGrocery();
  };

  const handleRemoveMeal = id => {
    props.removeMeal(id);
    const plans = store.getState().plans.current;
    const mealPlan = plans.filter(meal => meal._id !== id);
    saveUpdatedMealPlans(mealPlan);
  };

  const updateCurrentWeek = async weekPlan => {
    const mealPLan = weekPlan.reduce((plan, meal) => {
      meal.date &&
        plan.push({
          ...meal,
          _id: undefined,
          recipe: meal.recipe._id
        });
      return plan;
    }, []);
    await callUpdateCurrentWeekPlan(
      mealPLan,
      state.start.format("MM/DD/YYYY"),
      state.end.format("MM/DD/YYYY")
    );
    getMeals(state.start, state.end);

    onSuccessUpdate(state.needManage);
  };

  const updateDraft = async () => {
    const plans = store.getState().plans.current;

    const response = await callUpdateDraftPlan(
      plans.map(plan => ({
        ...plan,
        _id: undefined,
        recipe: plan.recipe._id
      })),
      state.start.format("MM/DD/YYYY"),
      state.end.format("MM/DD/YYYY"),
      state.id
    );

    if (response.data._id) {
      setState({ id: response.data._id });
      props.setCreatedPlanId(response.data._id);
    }

    props.setPlan(response.data.mealPlans);

    onSuccessUpdate(true);
  };

  const saveUpdatedMealPlans = plan => {
    const slugParams = props.match.params.id.split("-");

    if (!!state.name) {
      plan ? savePlan(null, plan) : savePlan();
    } else if (
      props.match.params.id === "this-week" ||
      (monthsObject.hasOwnProperty(slugParams[0].toLowerCase()) &&
        slugParams[slugParams.length - 1].length < 6)
    ) {
      plan && updateCurrentWeek(plan);
    } else if (props.match.params.id === "create") {
      updateDraft();
    }
  };

  const handleRecipeModalClose = (initialPortions, newPortions) => {
    if (initialPortions !== newPortions) {
      handlePortionsUpdate(state.recipe._id, newPortions);
    }

    setState({ recipe: null }, props.headerToTop);
  };

  const goToRecipePage = async (recipe, portions) => {
    const plans = store.getState().plans.current;
    let recipeList: any[] = plans;
    let planId: string | undefined;

    if (!state.id && !state.name) {
      const mealPLan = plans.reduce((plan, meal) => {
        meal.date &&
          plan.push({
            ...meal,
            _id: undefined,
            recipe: meal.recipe._id
          });
        return plan;
      }, []);
      const response = await callUpdateCurrentWeekPlan(
        mealPLan,
        state.start.format("MM/DD/YYYY"),
        state.end.format("MM/DD/YYYY")
      );

      recipeList = response.data.mealPlans;
      planId = response.data._id;

      setState({ id: response.data._id });
      props.setCreatedPlanId(response.data._id);

      props.setPlan(response.data.mealPlans);
      props.selectPlan(planId);

      const editedRecipe = recipeList.find(
        mealPlan => mealPlan.recipe._id === recipe.recipe._id
      );
      props.selectMeal(editedRecipe);
      props.setMealPortions(editedRecipe.portions);

      props.setPlanBody({
        plan: response.data.mealPlans.map(plan => ({
          ...plan,
          _id: undefined,
          recipe: plan.recipe._id
        })),
        start: state.start.format("MM/DD/YYYY"),
        end: state.end.format("MM/DD/YYYY")
      });
    } else {
      props.selectMeal(recipe);
      props.selectPlan(
        state.id || props.createdPlanId || props.match.params.id
      );
      props.setMealPortions(recipe.portions);

      const slugParams = props.match.params.id.split("-");

      if (
        props.match.params.id === "this-week" ||
        (monthsObject.hasOwnProperty(slugParams[0].toLowerCase()) &&
          slugParams[slugParams.length - 1].length < 6)
      ) {
        props.setPlanBody({
          plan: props.plan.map(plan => ({
            ...plan,
            _id: undefined,
            recipe: plan.recipe._id
          })),
          start: state.start.format("MM/DD/YYYY"),
          end: state.end.format("MM/DD/YYYY")
        });
      }
    }

    const titleSlug = paramCase(recipe.recipe.title);
    props.history.push(`/recipes/${titleSlug}`);
  };

  const getEnableNavigation = () => {
    const slugParams = props.match.params.id.split("-");
    return (
      props.match.params.id === "this-week" ||
      (monthsObject.hasOwnProperty(slugParams[0].toLowerCase()) &&
        slugParams[slugParams.length - 1].length < 6)
    );
  };

  const setExcludedIngredients = ingredients => {
    const ing = { ...props.excludedIngredients };
    ing[props.match.params.id] = ingredients;
    props.setExcludedIngredients(ing);
    setState({ excludedIngredients: ingredients });
  };

  const week = () => {
    const [dayOfPlan] = Object.keys(data);
    const start = (dayOfPlan
      ? moment.utc(dayOfPlan, "MM/DD/YYYY")
      : moment.utc()
    )
      .startOf("isoWeek")
      .format("DD-MMM-YYYY");
    return new Array(7).fill().map((_, i) =>
      moment(start, "DD-MMM-YYYY")
        .add(i, "d")
        .startOf("d")
        .format("MM/DD/YYYY")
    );
  };

  const setPlansMenuOpen = isOpen => {
    setState({
      plansMenuOpen: isOpen
    });
  };

  const getColumnDates = useCallback(
    index => {
      return moment
        .utc(state.start)
        .weekday(index + 1)
        .format("MM/DD/YYYY");
    },
    [state.start]
  );

  const onPageChange = (page: number) => {
    setState({ currentPage: page });
  };

  return (
    <>
      <Layout
        noScroll={!!state.recipe}
        wide={!state.shoppingCartVisible}
        fluid={state.shoppingCartVisible}
        lessPadding
        withoutSpacing={state.shoppingCartVisible && isMobile}
        fluidFull={state.shoppingCartVisible && isMobile}
        full={state.shoppingCartVisible && isMobile}
      >
        <ToastContainer
          position="bottom-right"
          autoClose={500}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          className="toast-container-img"
          toastClassName="toast"
          limit={1}
        />

        {state.shoppingCartVisible ? (
          <GroceryList
            title={state.name}
            close={closeShoppingCart}
            openDeliveryPage={openDeliveryPage}
            date={getTransformedDate()}
            list={props.grocery}
            planId={props.match.params.id || state.id}
            start={state.start}
            end={state.end}
            excludedIngredients={state.excludedIngredients}
            setExcludedIngredients={setExcludedIngredients}
            desktopView={state.desktopView}
          />
        ) : (
          <>
            {state.recipe && (
              <RecipeViewModal
                recipe={state.recipe}
                onClose={handleRecipeModalClose}
              />
            )}
            <Header
              isCustomPlan={!!state.name}
              date={
                props.loadingPlan
                  ? "Loading..."
                  : state.name || getTransformedDate()
              }
              openSavePlanModal={openSavePlanModal}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              openShoppingCart={openShoppingCart}
              openDeliveryPage={openDeliveryPage}
              gettingGrocery={props.gettingGrocery}
              savingPlan={props.savingPlan}
              deletingPlan={props.deletingPlan}
              saveNewName={saveNewName}
              handleKeyPress={handleKeyPress}
              enableNavigation={getEnableNavigation()}
              hasNext={
                moment(state.start).diff(moment().startOf("isoWeek"), "days") <
                7
              }
              navigatePastWeek={() => changeWeek("subtract")}
              navigateNextWeek={() => changeWeek("add")}
              savedPlans={state.menuSavedPlans}
              weeklyPlans={state.menuWeeklyPlans}
              goToPlan={goToPlan}
              setPlansMenuOpen={setPlansMenuOpen}
              week={week()}
              onPageChange={onPageChange}
            />
            <Pagination
              className="plan-grid g-gap10"
              plansMenuOpen={state.plansMenuOpen}
              outerCurrentPage={state.currentPage}
            >
              {week().map((date, index) => {
                return (
                  <Column
                    onShowRecipe={(recipe, portions) =>
                      goToRecipePage(recipe, portions)
                    }
                    key={date}
                    data={
                      (props.createdFromAACP
                        ? indexedData[index]
                        : data[getColumnDates(index)]) || []
                    }
                    date={getColumnDates(index)}
                    index={index}
                    onAddRecipe={openAddRecipe(index)}
                    onSwapClick={openSwapRecipe}
                    omMoveClick={turnMovingMode}
                    onRemoveClick={handleRemoveMeal}
                    updatePortions={handlePortionsUpdate}
                    movingId={state.movingId}
                    acceptMove={acceptMove}
                    draggingId={state.draggingId}
                    onDragStart={turnDraggingMode}
                    showAcceptModal={showAcceptModal}
                  />
                );
              })}
            </Pagination>
          </>
        )}
      </Layout>

      <SavePlanModal
        isCustomPlan={!!state.name}
        classNames={{ title: "create-plan-title" }}
        isCreatePlan
        visible={state.savePlanModalVisible}
        onSave={name => savePlan(name)}
        close={closeSavePlanModal}
        currentName={state.name}
        confirmRequired={true}
        error={errorModal}
        setError={setErrorModal}
        closeSuccess={closeSuccessModal}
        visibleSuccess={state.successModalVisible}
        header={state.isEditMode || !state.name ? 'EDIT PLAN NAME' : 'SAVE PLAN'}
      />
      <DeliveryPageModal
        title={state.name}
        close={closeDeliveryPage}
        date={getTransformedDate()}
        list={props.grocery}
        planId={
          hasSlug
            ? state.nameSlug || state.id || props.match.params.id
            : undefined
        }
        start={state.start}
        end={state.end}
        excludedIngredients={state.excludedIngredients}
        desktopView={state.desktopView}
        isAACP={props.createdFromAACP}
        visible={state.deliveryPageVisible}
      />

      <AddRecipe
        visible={state.addRecipeVisible}
        close={closeAddRecipe}
        onInfoClick={goRecipePage}
        onRecipeClick={addRecipe}
      />

      <AcceptModal
        close={closeAcceptModal}
        onSuccess={onAcceptModalSuccess}
        visible={state.acceptModalVisible}
        title={state.acceptModalTitle}
      />
      <DeletePlanModal
        visible={state.deleteModalVisible}
        onDelete={deletePlan}
        close={closeDeleteModal}
      />
    </>
  );
};

const mapStateToProps = state => ({
  loadingPlan: state.loading["GET_PLAN"] || state.loading["GET_CUSTOM_PLAN"],
  deletingPlan: state.loading["DELETE_PLAN"],
  savingPlan: state.loading["SAVE_PLAN"] || state.loading["UPDATE_PLAN"],
  gettingGrocery: state.loading["GET_GROCERY"],
  plan: state.plans.current,
  allPlans: state.plans.all,
  weeklyPlans: state.plans.weeklyPlans,
  grocery: state.plans.grocery,
  createdPlanId: state.plans.createdPlanId,
  excludedIngredients: state.ingredients.excludedIngredients,
  createdFromAACP: state.plans.createdFromAACP
});

const mapDispatchToProps = dispatch => ({
  getPlan: (start, end, onCustomPlan) =>
    dispatch({ type: "GET_PLAN", start, end, onCustomPlan }),
  getPlans: (start: number, end: number, callback) =>
    dispatch({ type: "GET_PLANS", start, end, onGetPlans: callback }),
  getWeeklyPlans: callback =>
    dispatch({ type: "GET_WEEKLY_PLANS", onGetPlans: callback }),
  getCustomPlan: (id, onSuccess) =>
    dispatch({ type: "GET_CUSTOM_PLAN", id, onSuccess }),
  savePlan: (body, onSuccess, getMeals, onError) =>
    dispatch({ type: "SAVE_PLAN", body, onSuccess, getMeals, onError }),
  removeMeal: id => dispatch(PlansActions.removeMeal({ id })),
  addMeal: meal => dispatch(PlansActions.addMeal({ meal })),
  updatePortions: (id, count) =>
    dispatch(PlansActions.updatePortions({ id, count })),
  swapRecipe: (id, recipe) => dispatch(PlansActions.swapRecipe({ id, recipe })),
  moveMeal: (id, date, category, currentMeal, index) =>
    dispatch(PlansActions.moveMeal({ id, date, category, currentMeal, index })),
  updateCustomPlan: (id, meals, name, onSuccess, onError) =>
    dispatch({
      type: "UPDATE_CUSTOM_PLAN",
      id,
      meals,
      name,
      onSuccess,
      onError
    }),
  getGrocery: params => dispatch({ type: "GET_GROCERY_LIST", params }),
  deleteCustomPlan: (id, onSuccess) =>
    dispatch({ type: "DELETE_PLAN", id, onSuccess }),
  resetPlan: () => {
    dispatch(PlansActions.setGrocery({ grocery: [] }));
    dispatch(PlansActions.setPlan({ data: [] }));
  },
  setExcludedIngredients: excludedIngredients =>
    dispatch(
      IngredientsActions.setExcludedIngredients({ excludedIngredients })
    ),
  setPlan: data => dispatch(PlansActions.setPlan({ data })),
  headerToBottom: () => dispatch(AppActions.headerToBottom()),
  headerToTop: () => dispatch(AppActions.headerToTop()),
  selectMeal: meal => dispatch(PlansActions.setSelectedMeal({ meal })),
  selectPlan: id => dispatch(PlansActions.setSelectedPlan({ id })),
  setMealPortions: count => dispatch(PlansActions.setMealPortions({ count })),
  setCreatedPlanId: id => dispatch(PlansActions.setCreatedPlanId({ id })),
  setPlanBody: body => dispatch(PlansActions.setPlanBody({ body }))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MealPlan)
);
