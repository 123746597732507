import React from 'react';
import cx from "classnames";

import close from "../../../../assets/images/common/close-black.svg";
import closeWhite from '../../../../assets/images/common/close-white.svg';

const SuccessModal = props => (
  <div className={cx('modal success-message-modal', { visible: props.visible })}>
    <div className="scrollable-layer">
      <div className="body">
        {/* <div className="button-container">
          <button
            className="close-button"
            onClick={props.close}
          >
            <img src={closeWhite} alt="Close"/>
          </button>
        </div> */}
        {props.children}
      </div>
    </div>
  </div>
);

export default SuccessModal;
