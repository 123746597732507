import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../../components/Layout';

import trialAlert from '../../assets/images/trial-alert.svg';
import first from '../../assets/images/wellness-web-1.jpg';
import second from '../../assets/images/wellness-web-2.jpg';
import third from '../../assets/images/wellness-web-3.jpg';
import reviewFirst from '../../assets/images/review-1.png';
import reviewSecond from '../../assets/images/review-2.png';
import reviewThird from '../../assets/images/review-3.png';
import Button from '../../components/Button';

const Advantage = ({ title, text, image }) => (
    <div className="advantage">
        <div className="advantage-image" style={{ backgroundImage: `url(${image})`}} />
        <div className="advantage-content">
            <p className="advantage-title">{title}</p>
            <p className="advantage-text" dangerouslySetInnerHTML={{__html: text}} />
        </div>
    </div>
);

const Review = ({ title, text, name, image }) => (
    <div className="review">
        <div className="review-image" style={{ backgroundImage: `url(${image})`}} />
        <div className="review-content">
            <p className="review-title">{title}</p>
            <p className="review-text">{text}</p>
            <div className="spacer" />
            <p className="review-name">{name}</p>
        </div>
    </div>
)

const Trial = () => (
    <Layout medium>
        <div className="trial-rawkstars">
            <div className="trial-alert-container">
                <img className="trial-alert" src={trialAlert} alt="Trial" />
                <div className="text">
                    <p className="title">You’re currently on</p>
                    <p className="message">free 14-day trial</p>
                </div>
            </div>
            <h3 className="unlock-title">UPGRADE TODAY AND UNLOCK…</h3>
            <div className="advantages-container">
                <Advantage
                    title="Seasonal Cleanse Kits"
                    text="Rawk the Year includes access to our 4 seasonal, best-selling cleanses to help you get over a weight loss hump, heal your body and gain more energy. Each season, we host live community cleanses and support you along the way with  group coaching calls and 24/7 online support.<br/><br/>Our plant-based diet cleanse programs cost $175 alone, yet we've included them inside Rawk the Year!"
                    image={first}
                />
                <Advantage
                    title="Mini Wellness Challenges"
                    text="Experience what a plant-powered lifestyle can truly do with exclusive, self-paced wellness challenges to deepen your plant-based whole food nutrition knowledge.Take these challenges on your own, or join our live community during our seasonal wellness challenges.<br/><br/>Explore topics like gut health, fitness, healthy habits, natural remedies and so much more!"
                    image={second}
                />
                <Advantage
                    title="Articles, Resources + Coaching Calls"
                    text="Gain access to our plant-based mix and match guides, DIY recipes, digital seasonal magazine and meal prep guidebooks to help you in the transition to a healthier lifestyle. Jump on a live coaching call to dive deeper into the rawkstar lifestyle and make new friends along the way.<br/><br/>Plus, you’ll get first dibs on tickets to our Adventure Retreats that sell our every year.!"
                    image={third}
                />
            </div>
            <div className="callout">
                <p className="callout-title">Upgrade today and get access to all this!</p>
                <Link to="account/subscription" className="button">UPGRADE</Link>
            </div>
            <div className="reviews-container">
                <Review
                    title="I lost 10 pounds over the 21 days!"
                    text="I lost 10 pounds over the 21 days and I was never hungry! The food was delicious and many of these recipes will be incorporated into the family meal rotation. Hurray! Love this new whole food lifestyle!"
                    name="- Carrie Eichens"
                    image={reviewFirst}
                />
                <Review
                    title="So happy for my decision."
                    text="So happy for my decision of being part of this program. In just 2 week I feel happier, stronger and most important, healthier! The inflammation of my hands are gone! The extra benefit of all of it: I have lost 8 pounds!"
                    name="- Indhira M."
                    image={reviewSecond}
                />
                <Review
                    title="I had energy to climb a mountain!"
                    text="I've tried cleanses before and always felt hungry and the recipes were tasteless. This was very satisfying and yummy! I had energy to climb to the top of a mountain with only my smoothie and water on my back. "
                    name="- Eva L."
                    image={reviewThird}
                />
            </div>
        </div>
    </Layout>
);

export default Trial;
