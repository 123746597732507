import React, { FC, HTMLProps, ReactNode, useState } from "react";

interface IProps extends HTMLProps<HTMLInputElement> {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

const NewInput: FC<IProps> = ({
  rightIcon,
  leftIcon,
  value,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  return (
    <div
      className={`new-input-wrapper ${isFocused && "new-input-wrapper_active"}`}
    >
      {leftIcon}
      <input
        value={value}
        onChange={onChange}
        className="new-input-input"
        onFocus={onFocus}
        onBlur={onBlur}
        {...rest}
      />
      {rightIcon}
    </div>
  );
};

export default NewInput;
