import React, { useState, useEffect } from "react";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";

import download from "../../assets/images/common/download.svg";
import { callGetArticle } from "../../api/articles";
import { connect } from "react-redux";
import {
  googleAnalitics,
  CATEGORY_TYPES,
  ACTION_TYPES,
} from "../../utils/GoogleAnalitics";

type Props = {
  viewArticle: (link: string) => void,
  match: {
    params: {
      id: string,
    },
  },
};

const Article = (props: Props) => {
  const [title, setTitle] = useState("");
  const [photo, setPhoto] = useState("");
  const [content, setContent] = useState("");
  const [pdf, setPdf] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await callGetArticle(props.match.params.id);

      setTitle(response.data.title)
      setPhoto(response.data.photo)
      setContent(response.data.content)
      setPdf(response.data.pdf)
    };
    fetchData();
  });

  const viewArticle = (link) => {
    googleAnalitics.logEvent({
      category: CATEGORY_TYPES.wellness,
      action: ACTION_TYPES.download,
      label: props.match.params.id,
    });

    window.open(link);
  };

  return (
    <Layout>
      <div
        className="main-image"
        style={{ backgroundImage: `url(${photo})` }}
      />
      <div className="rounded-content article">
        <div className="article-title section-header">
          <PageHeader title={title} spaceless />
          {!!pdf && (
            <Button
              transparent
              onClick={() => viewArticle(pdf)}
            >
              <img src={download} alt="Download" />
            </Button>
          )}
        </div>
        <div
          className="article-body"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  viewArticle: (link) => dispatch({ type: "VIEW_ARTICLE", link }),
});

export default connect(null, mapDispatchToProps)(Article);
