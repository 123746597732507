import React, { ReactNode } from "react";

type Option = { id: string; title: ReactNode | string };
type Props = {
  options: Option[];
  activeItemId: string;
  onSelectItem: (option: Option) => void;
  className?: string;
};

const Switch = (props: Props) => {
  return (
    <div className={`switch-container ${props.className || ""}`}>
      {props.options?.map((option, index) => (
        <button
          type="button"
          key={option.id}
          className={`switch-container_button ${
            option.id === props.activeItemId ? "active" : ""
          }`}
          onClick={() => {
            props.onSelectItem(option);
          }}
        >
          {option.title}
        </button>
      ))}
    </div>
  );
};

export default Switch;
