import { SignRadio } from "forms/SignUp/components/SignRadio";
import React, { Fragment } from "react";
import { useAppSelector } from "store/hooks";
import { Subscription } from "store/subscriptions/types";

const SUBSCRIPTION_PERIOD = {
  annual: "year",
  quarterly: "qtr",
  monthly: "mo",
};

export const getPeriod = (period: string) => {
  if (period?.toLowerCase() === "month") return "1 month";
  if (period?.toLowerCase() === "year") return "12 months";

  return period;
};

const SubscriptionPlanSelect = () => {
  const subscriptions: Subscription[] = useAppSelector(
    (state) => state.subscriptions.all
  );
  if (!subscriptions?.length) {
    return null;
  }

  return (
    <div className="subscription-plan-select">
      <div className="subscription-plan-select__prices">
        {subscriptions.map((subscription, index) => {
          const isLast = index === subscriptions.length - 1;
          const className = isLast ? "end" : index === 0 ? "start" : "center";

          return (
            <div
              key={index}
              className={`subscription-plan-select__item subscription-plan-select__item-${className}`}
            >
              {subscription.sale && (
                <div className="subscription-plan-select__discount">
                  <span>{subscription.sale}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="subscription-plan-select__radioButtons">
        {subscriptions.map((subscription, index) => {
          const isLast = index === subscriptions.length - 1;
          return (
            <Fragment key={index}>
              <SignRadio {...subscription} index={index} />
              {!isLast && <div className="subscription-plan-select__line" />}
            </Fragment>
          );
        })}
      </div>
      <div className="subscription-plan-select__prices">
        {subscriptions.map((subscription, index) => {
          const isLast = index === subscriptions.length - 1;
          const className = isLast ? "end" : index === 0 ? "start" : "center";

          const periodTitle = getPeriod(subscription.period);
          return (
            <div
              key={index}
              className={`subscription-plan-select__item subscription-plan-select__item-${className}`}
            >
              <span className="subscription-plan-select__period">
                {periodTitle}
              </span>
              <p className="subscription-plan-select__price">
                {`${subscription.currency}${subscription.price}`}
                <span className="subscription-plan-select__period">
                  {`/${(
                    SUBSCRIPTION_PERIOD[subscription.name.toLowerCase()] ||
                    subscription.name
                  ).toUpperCase()}`}
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionPlanSelect;
