import React from "react";
import { connect } from "react-redux";

import PageHeader from "../../../components/PageHeader";
import SubscriptionCard from "../SubscriptionCard";
import { Subscription, UserSubscription } from "store/subscriptions/types";

type Props = {
  subscriptions: Subscription[];
  navigateCheckoutPage: (string) => void;
  userSubscription: UserSubscription;
};

const SubscriptionTab = (props: Props) => {

  return (
    <div className="subscriptions-list">
      {props.subscriptions &&
        [...props.subscriptions]
          // .sort((a, b) => a.price - b.price)
          .map((subscription, index) => (
            <SubscriptionCard
              {...subscription}
              trial={index === 0}
              key={subscription._id}
              type="PRO"
              price={subscription.price}
              period={subscription.period}
              currency={subscription.currency}
              select={() => props.navigateCheckoutPage(subscription)}
              purchaseable
              abort={!props?.userSubscription}
            />
          ))}
    </div>
  );
};

// TODO: Fix afrer state management
// @ts-ignore
const connectFunction = connect(({ user }) => ({
  userSubscription: user.subscriptionStatus,
}));

export default connectFunction(SubscriptionTab);
