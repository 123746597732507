export class StorageItem {
  /**
   * @param {string} key 
   */
  constructor(key) {
    this.key = key;
  }

  parse(item) {
    try {
      const parsed = JSON.parse(item);
      return parsed;
    } catch (error) {
      return item || null;
    }
  }

  get() {
    const storedData = localStorage.getItem(this.key);
    return this.parse(storedData);
  }

  set(newValue) {
    if (newValue === undefined) {
      return localStorage.removeItem(this.key);
    }
    localStorage.setItem(this.key, JSON.stringify(newValue));
  }

  remove() {
    localStorage.removeItem(this.key);
  }
}
