import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import tinycolor from "tinycolor2";

import countries from "../../map.json";

type Props = {
  data: {
    [key: string]: {
      count: unknown;
      country: React.ReactNode;
      percent: any;
    };
  };
};

const Map = (props: Props) => {
  const maximum = Math.max.apply(
    null,
    Object.values(props.data).map((v) => v.count)
  );
  const topCountries = Object.values(props.data)
    .sort((a, b) => b.percent - a.percent)
    .slice(0, 4);

  const color = tinycolor("#48BD18");

  let maxPercent = 0;

  topCountries.forEach((c) => {
    if (c.percent / 100 > maxPercent) {
      maxPercent = c.percent / 100;
    }
  });

  let otherPercent = 1;

  topCountries.forEach((country) => {
    otherPercent -= country.percent / 100;
  });

  if (otherPercent > maxPercent) {
    maxPercent = otherPercent;
  }

  return (
    <div className="map-container">
      <div className="map">
        <ComposableMap>
          <Geographies geography="https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json">
            {({ geographies }) =>
              geographies
                .filter((g) => g.properties.NAME_LONG !== "Antarctica")
                .map((geo) => {
                  const newColor =
                    props.data[geo.properties.NAME_LONG.toLowerCase()] &&
                    props.data[geo.properties.NAME_LONG.toLowerCase()].count > 0
                      ? tinycolor("#48BD18")
                      : color.setAlpha(0.1);

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={newColor}
                      stroke="white"
                    />
                  );
                })
            }
          </Geographies>
        </ComposableMap>
      </div>
      <div className="statistics-container">
        <div className="statistics">
          {topCountries.map((c) => (
            <div className="element">
              <p>{c.country}</p>
              <div className="bar-container">
                <div
                  className="bar"
                  style={{ width: `${c.percent / maxPercent}%` }}
                />
              </div>
              <p className="text-muted">{c.percent.toFixed(1)}%</p>
            </div>
          ))}
          <div className="element">
            <p>Other</p>
            <div className="bar-container">
              <div
                className="bar"
                style={{ width: `${(otherPercent / maxPercent) * 100}%` }}
              />
            </div>
            <p className="text-muted">{(otherPercent * 100).toFixed(1)}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
