import React from "react";
import cx from "classnames";
import Button from "components/Button";
import { Subscription } from "store/subscriptions/types";
import {
  SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_PERIOD_MONTHS,
  SUBSCRIPTION_PERIOD_SHORT,
  getMonthlyPrice
} from "components/SubscriptionPlanInfo";

interface Props extends Subscription {
  type: string;
  daysLeft?: number;
  price: number;
  purchaseable?: boolean;
  selected?: boolean;
  select: (string) => void;
  stripeId: string;
  period: string;
  unselectable?: boolean;
  hidePrice?: boolean;
  currency: string;
  trial?: boolean;
  abort?: boolean;
}

const SubscriptionCard = (props: Props) => {
  return (
    <div
      className={cx("subscription-card", {
        hoverable: props.purchaseable,
        yearly: props.period === "year"
      })}
    >
      <h4 className="title">{props.sale || ""}</h4>
      {!props.hidePrice && (
        <div className="info">
          <p className="price">
            {props.daysLeft
              ? `${props.daysLeft}`
              : `$${
                  props.period === "year"
                    ? getMonthlyPrice(
                        props.price,
                        SUBSCRIPTION_PERIOD_MONTHS[props.period]
                      )
                    : props.price
                }`}
            <span className="period">
              /{SUBSCRIPTION_PERIOD_SHORT["month"]}
            </span>
          </p>
          <p className="duration">billed {SUBSCRIPTION_PERIOD[props.period]}</p>
        </div>
      )}
      <button
        onClick={() => !props.unselectable && props.select(props._id)}
        className="subscribe-button"
      >
        {props.abort
          ? props.trialTitle
            ? "TRY FOR FREE"
            : "LET'S RAWK!"
          : "LET'S RAWK!"}
      </button>
    </div>
  );
};

export default SubscriptionCard;
