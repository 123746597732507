import React from 'react';
import cx from "classnames";

import Button from "../../../../components/Button";

const AcceptModal = props => (
  <div className={cx('modal save-plan-modal', { visible: props.visible })}>
    <div className="scrollable-layer">
      <div className="body">
        <p className="message">Replace {props.title}?</p>
        <div className="footer-buttons">
          <Button outlined small onClick={props.close}>No</Button>
          <Button primary small onClick={props.onSuccess}>Replace</Button>
        </div>
      </div>
    </div>
  </div>
);

export default AcceptModal;
