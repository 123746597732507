import React from "react";

import Card from "../Card";
import Day from "../Day";

import type { CardType } from "../Card";

type Props = {
  date: string,
  index: number,
  onAddRecipe: (date: string, category: string) => void,
  data: CardType & {id: string, category: string }[],
  onTooltipClick?: (cb: () => void) => void;
  [key: string]: any,
}

const Column = (props: Props) => {
  const data = {
    Breakfast: props.data.filter(m => m.category === "Breakfast"),
    Lunch: props.data.filter(m => m.category === "Lunch"),
    Snack: props.data.filter(m => m.category === "Snack"),
    Dinner: props.data.filter(m => m.category === "Dinner"),
    Dessert: props.data.filter(m => m.category === "Dessert")
  };
  const sectionName = {
    Breakfast: 'Breakfast',
    Lunch: 'Lunch',
    Snack: 'Snack',
    Dinner: 'Dinner',
    Dessert: 'Extras'
  }

  return (
    <div className="plan-column">
      {Object.keys(data).map(key => (
        <React.Fragment key={key}>
          <h4 className="row-title">{props.index === 0 && sectionName[key]}</h4>
          {data[key].length ? (
            data[key].map(meal =>
              (
                <Card
                  onClick={(portions) => props.onShowRecipe(meal, portions)}
                  {...meal}
                  _id={meal._id}
                  key={meal._id}
                  onRemoveClick={() => props.onRemoveClick(meal._id)}
                  onSwapClick={() => props.onSwapClick(meal._id)}
                  onMoveClick={() => props.omMoveClick(meal._id)}
                  updatePortions={(value) => props.updatePortions(meal._id, value)}

                  moving={props.movingId}
                  empty={props.movingId && props.movingId !== meal._id}
                  emptyText={props.movingId && 'Move here'}
                  acceptMove={() => props.acceptMove(props.date, key, meal._id, props.index)}

                  onDragStart={props.onDragStart}
                  showAcceptModal={props.showAcceptModal}
                  draggingId={props.draggingId}
                  onTooltipClick={props.onTooltipClick}
                />
              ))
          ) : (
            // @ts-ignore
            <Card
              empty
              moving={props.movingId}
              addRecipe={() => props.onAddRecipe(props.date, key)}
              emptyText={props.movingId && 'Move here'}
              acceptMove={() => props.acceptMove(props.date, key, undefined, props.index)}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Column;
