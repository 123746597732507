import React, { FC } from "react";
import Button from "../Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const LoginAsBanner: FC = () => {
  const isLoginAs = useAppSelector(state => !!state.user?.isLoginAs);
  const email = useAppSelector(state => state.user?.email);

  const dispatch = useAppDispatch();

  return isLoginAs ? (
    <div className="login-as-banner">
      <span>
        You are logged in as <b>{email}</b>. Please be careful with what you do.
      </span>
      <Button small limited onClick={() => dispatch({ type: "LOGOUT" })}>
        Log out
      </Button>
    </div>
  ) : null;
};

export default LoginAsBanner;
