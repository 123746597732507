import React, { SyntheticEvent, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Layout from "../../components/LoginLayout";
import Input from "../Input";
import PasswordInput from "../PasswordInput";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import { isValidEmail, gotoStripeCheckout } from "../../utils";

type Props = {
  loading?: boolean;
  error?: string;
  login: (email: string, password: string) => void;
};

type State = {
  email: string;
  password: string;
  errors: {
    email?: string | null;
    password?: string | null;
  };
};

const Login = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    email: "",
    password: "",
    errors: {},
  });

  const setState = (newState: Partial<State>) => setCurrentState((prevState) => ({ ...prevState, ...newState }));

  const updateField = (e: SyntheticEvent<HTMLInputElement>, field: string) => {
    setState({
      [field]: e.currentTarget.value,
      errors: {},
    });
  };

  const validateFields = () => {
    const isEmailValid = isValidEmail(state.email);
    const isPasswordExists = !!state.password;

    if (isEmailValid && isPasswordExists) {
      return true;
    }

    setState({
      errors: {
        email: !isEmailValid ? "Email is invalid" : null,
        password: !isPasswordExists ? "Password cannot be blank" : null,
      },
    });
  };

  const loginRequest = () => {
    if (validateFields()) {
      props.login(state.email, state.password);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginRequest();
    }
  };

  return (
    <Layout>
      <div className="login-box">
        <PageHeader title="Log In" />
        <Input
          disabled={props.loading}
          error={state.errors.email}
          label="Email"
          placeholder="Your Email"
          type="email"
          name="email"
          value={state.email}
          onChange={(e) => updateField(e, "email")}
        />
        <PasswordInput
          disabled={props.loading}
          error={state.errors.password}
          label="Password"
          name="password"
          placeholder="Min 6 characters"
          value={state.password}
          onChange={(e) => updateField(e, "password")}
          onKeyPress={handleKeyPress}
        />
        <Button
          className="action-button"
          disabled={props.loading}
          primary
          onClick={loginRequest}
          children={props.loading ? "Authentication..." : "Log In"}
        />
        <p className="error">{props.error}</p>
        <div className="form-buttons">
          <Link to="/forgot">Forgot your password?</Link>
          <p>
            Don't have an account?{" "}
            <a href="#" onClick={gotoStripeCheckout}>
              Sign Up
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  error: state.error["LOGIN"],
  loading: state.loading["LOGIN"],
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch({ type: "LOGIN", email, password }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
