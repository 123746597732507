import { call, put, takeLatest } from "redux-saga/effects";
import { actions as RawkstarsActions } from '../../store/rawkstars/reducer';
import { callGetUsers, callGetReviews, callGetUsersStatistics } from "../../api/rawkstars";

export function* getUsers(action) {
  try {
    yield put({ type: "GET_USERS_REQUEST" });
    const response = yield call(callGetUsers, action.page, action.search);

    yield put(RawkstarsActions.setUsers(response.data.results));

    action.onPagesFetch(response.data.pages);

    yield put({ type: "GET_USERS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_USERS_FAILURE",
      error: error.message
    });
  }
}

export function* getReviews(action) {
  try {
    yield put({ type: "GET_REVIEWS_REQUEST" });
    const response = yield call(callGetReviews, action.page);

    yield put(RawkstarsActions.setReviews(response.data.results));

    action.onPagesFetch(response.data.pages);

    yield put({ type: "GET_REVIEWS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_REVIEWS_FAILURE",
      error: error.message
    });
  }
}

export function* getStatistics(action) {
  try {
    yield put({ type: "GET_STATISTICS_REQUEST" });
    const response = yield call(callGetUsersStatistics);

    yield put(RawkstarsActions.setStatistics(response.data.results));

    yield put({ type: "GET_STATISTICS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_STATISTICS_FAILURE",
      error: error.message
    });
  }
}

const authSaga = [
  takeLatest("GET_USERS", getUsers),
  takeLatest("GET_REVIEWS", getReviews),
  takeLatest("GET_STATISTICS", getStatistics),
];

export default authSaga;
