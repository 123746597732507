import React, { FC, useEffect, useState } from "react";
import { Sticky } from "react-sticky";
import cx from "classnames";

import printPlan from "../../../assets/images/common/print_new_black.svg";
import printPlanWhite from "../../../assets/images/common/print_new.svg";
import deletePLan from "../../../assets/images/common/trash_new_black.svg";
import deletePLanWhite from "../../../assets/images/common/trash_new.svg";
import cartWhite from "../../../assets/images/common/cart_white.svg";
import editPlan from "../../../assets/images/common/edit.svg";
import editPlanWhite from "../../../assets/images/common/edit_white.svg";
import { useAppSelector } from "store/hooks";
import cart from "../../../assets/images/common/cart.svg";
import Day from "containers/MealPlan/components/Day";
import Pagination from "containers/MealPlan/components/Pagination";

interface IProps {
  isCustomPlan?: boolean;
  openShoppingCart?: () => void;
  openDeliveryPage?: () => void;
  openEditModal?: () => void;
  openSavePlanModal?: () => void;
  openDeleteModal?: () => void;
  name: string;
  onPageChange?: (page: number) => void;
}

const Header: FC<IProps> = props => {
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    setMobileView(window.innerWidth <= 475);
  }, []);
  const isGroceryLoading = useAppSelector(
    state => state.loading["GET_GROCERY"]
  );
  const isSavingPlan = useAppSelector(
    state => state.loading["SAVE_PLAN"] || state.loading["UPDATE_PLAN"]
  );
  const isDeletingPlan = useAppSelector(state => state.loading["DELETE_PLAN"]);

  return (
    <div className="meal-header_wrapper">
      <div className={cx("meal-header")}>
        <div className="header-container" onClick={props.openEditModal}>
          <p className="aacp-header-title">{props.name}</p>
          {!mobileView && (
            <img
              src={mobileView ? editPlanWhite : editPlan}
              alt="edit plan icon"
            />
          )}
        </div>
        <div className="buttons-container">
          <div className="buttons">
            <div className="action-button-container">
              <button
                className={cx("action-button")}
                onClick={props.openShoppingCart}
                disabled={isGroceryLoading}
              >
                <img src={mobileView ? cartWhite : cart} alt="Grocery list" />
              </button>
              <button
                className="action-button"
                onClick={props.openDeliveryPage}
              >
                <img
                  src={mobileView ? printPlanWhite : printPlan}
                  alt="Grocery list"
                />
              </button>
              {mobileView && (
                <button
                  className={cx("action-button")}
                  onClick={props.openEditModal}
                >
                  <img
                    src={mobileView ? editPlanWhite : editPlan}
                    alt="Grocery list"
                  />
                </button>
              )}
              <button
                className={cx("action-button")}
                onClick={
                  !isDeletingPlan && props.isCustomPlan && props.openDeleteModal
                }
              >
                <img
                  src={mobileView ? deletePLanWhite : deletePLan}
                  alt="Grocery list"
                />
              </button>
            </div>
          </div>
        </div>
        <Pagination
          plansMenuOpen={false}
          customRender={true}
          onPageChange={props.onPageChange}
        />
        <div className={`plan-grid meal-header_days`}>
          {[1, 2, 3, 4, 5, 6, 7].map((date, index) => (
            <Day date={date} first={index === 0} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
