import { callMakeRecipeFavorite, callRemoveRecipeFavorite } from "../api/recipes";

export default async (currentValue, recipeId) => {
  try {
    let nextValue = !currentValue;
    let response;

    if (currentValue) {
      response = await callRemoveRecipeFavorite(recipeId);
    } else {
      response = await callMakeRecipeFavorite(recipeId);
    }

    
    if (response.success) {
      return nextValue;
    }

    return currentValue;
  } catch (e) {
    console.log(e);
  }
};
