import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    all: [],
  };

const articlesSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {
        setArticles: (state, action) => {
            state.all = action.payload.articles;
        }
    }
});

export const { actions } = articlesSlice;

export default articlesSlice.reducer;
  