import { call, put, takeLatest } from "redux-saga/effects";
import { actions as ArticlesActions } from '../../store/articles/reducer';
import { callGetArticles } from '../../api/articles';

export function* getArticles(_action) {
  try {
    yield put({ type: "GET_ARTICLES_REQUEST" });
    const response = yield call(callGetArticles);

    yield put(ArticlesActions.setArticles(response.data));

    yield put({ type: "GET_ARTICLES_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_ARTICLES_FAILURE",
      error: error.message
    });
  }
}

const authSaga = [
  takeLatest("GET_ARTICLES", getArticles),
];

export default authSaga;
