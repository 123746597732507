// @ts-nocheck
import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import cx from "classnames";
import { connect } from 'react-redux';
import {paramCase} from "change-case";

import RecipesFilter from "../RecipesFilter";
import SearchInput from "../../../../forms/SearchInput";
import RecipeCard from "../RecipeCard";
import Modal from "../Modal";
import Button from "../../../../components/Button";

import settings from "../../../../assets/images/common/sliders-vert.svg";
import type { Props as RecipeProps } from '../RecipeCard';
import withWindowSize from "../../../../utils/withWindowWidth";
import { callGetRecipes } from "../../../../api/recipes";
import NotFoundMessage from "../NotFoundMessage";
import { googleAnalitics, CATEGORY_TYPES, ACTION_TYPES } from '../../../../utils/GoogleAnalitics';
import { StorageItem } from '../../../../utils/storage';
import CustomSelect from '../Select';

const storage = {
  title: new StorageItem('recipe-filter__title'),
  seasons: new StorageItem('recipe-filter__seasons'),
  types: new StorageItem('recipe-filter__types'),
  time: new StorageItem('recipe-filter__time'),
  reviews: new StorageItem('recipe-filter__reviews'),
  tab: new StorageItem('recipe-filter__tab'),
  favorites: new StorageItem('recipe-filter__favorites'),
  haveMade: new StorageItem('recipe-filter__haveMade'),
  sort: new StorageItem('recipe-filter__sort')
}

type Props = {
  recipes: RecipeProps[],
  windowWidth: number,
  // getEnums: (string[]) => void,
  getEnums: (any) => void,
  seasons: { _id: string, name: string }[],
  types: { _id: string, name: string }[],
  onInfoClick: (string) => void,
};

export const SORT_OPTIONS = [
  {
    value: 'new',
    label: 'Newest'
  },
  {
    value: 'old',
    label: 'oldest'
  },
  {
    value: 'quick',
    label: 'quickest'
  },
  {
    value: 'rate',
    label: 'highest rated'
  },
  {
    value: 'alphabet',
    label: 'Alphabetical'
  }
]

type State = {
  title: string,
  isFilterOpen: boolean,
  tab: string,

  seasons: string[],
  type: string,
  time: number[],
  reviews: any,
  favorites: boolean,
  haveMade: boolean,

  recipes: RecipeProps[],
  page: number,
  pages: number | null,
  loading: boolean,
  shouldFetchMore: boolean,

  loadingCount: boolean,
  filteredRecipesCount: number,

  sort: 'new' | 'old' | 'quick' | 'rate' | 'alphabet'
}

const initialFilters = {
  title: '',
  seasons: [],
  types: [],
  time: [],
  reviews: undefined,
  favorites: undefined,
  haveMade: undefined,
  search: '',
  sort: 'new'
};

const  RecipesLayout = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    // ...initialFilters,
    title: storage.title.get() || initialFilters.title,
    seasons: storage.seasons.get() || initialFilters.seasons,
    types: storage.types.get() || initialFilters.types,
    time: storage.time.get() || initialFilters.time,
    reviews: storage.reviews.get() || initialFilters.reviews,
    search: storage.title.get() || initialFilters.title,
    favorites: storage.favorites.get() || initialFilters.favorites,
    haveMade: storage.haveMade.get() || initialFilters.haveMade,

    isFilterOpen: false,
    tab: storage.tab.get() || 'all',

    recipes: [],
    page: 0,
    pages: null,
    loading: false,
    shouldFetchMore: true,

    loadingCount: false,
    filteredRecipesCount: 0,
    sort: storage.sort.get() || initialFilters.sort
  })

  const setState = (newState: Partial<State>) => {
    setCurrentState((prevState: State) => ({ ...prevState,  ...newState }));
  }

  useEffect(() => {
    props.getEnums(['seasons', 'types']);
  }, [])

  useEffect(() => {
    getFreshRecipes();
  },[state.title])

  const handleLoad = async () => {
    setState({ loading: true, shouldFetchMore: false });

    const filterParams = countFilters() > 0 ? getQuery() : {};

    const response = await callGetRecipes(state.page + 1, {
      ...filterParams,
      ...getPermanentParams()
    });
    setState({
      loading: false,
      page: parseInt(response.data.stats.page),
      pages: Math.ceil(response.data.stats.pages),
      recipes: [...state.recipes, ...response.data.stats.results],
    });

  };

  const getPermanentParams = () => {
    return {
      haveMade: state.haveMade,
      favorite: state.favorites,
      title: state.title || undefined,
      sort: state.sort
    };
  };

  const changeFilter = (field: string, value: string) => {
    let newValue = value;
    if ((typeof state[field] !== 'object' || !value) && state[field] === value) {
      newValue = initialFilters[field];
    }

    setState({
      [field]: newValue
    });

    storage[field].set(newValue);
    
    // countRecipesWithFilters(getQuery({ [field]: newValue }));
    getFreshRecipes({ [field]: newValue });
  };

  const toggleFavorites = () => {
    setState({
      favorites: !state.favorites ? true : undefined
    });
      storage.favorites.set(!state.favorites ? true : undefined);
      countRecipesWithFilters(getQuery());
      getFreshRecipes();
  }

  const toggleHaveMade = () => {

    setState({
      haveMade: !state.haveMade ? true : undefined
    });
      storage.haveMade.set(!state.haveMade ? true : undefined);
      countRecipesWithFilters(getQuery());
      getFreshRecipes();
  }

  const getQuery = (newValue: {[field]: string}) => {
    return {
      seasons: state.seasons,
      types: state.types,
      minTime: state.time[0],
      maxTime: state.time[1],
      minRating: state.reviews,
      sort: state.sort,
      ...newValue
    };
  };

  const countRecipesWithFilters = async params => {
    if (state.loadingCount) {
      return;
    }

    const permanentParams = getPermanentParams();
    const combinedParams = {
      ...params,
      ...permanentParams
    };
    setState({ loadingCount: true });
    const response = await callGetRecipes(1, combinedParams);

    logMetricEvents(combinedParams);

    setState({
      loadingCount: false,
      filteredRecipesCount: response.data.stats.count,
    });
  };

  const logMetricEvents = (params) => {
    if (params.title) {
      googleAnalitics.logEvent({ category: CATEGORY_TYPES.recipes, action: ACTION_TYPES.search, label: params.title });
    }

    if (params.seasons.length) {
      const season = props.seasons.find((i) => params.seasons.includes(i._id));
      googleAnalitics.logEvent({ category: CATEGORY_TYPES.recipes, action: ACTION_TYPES.filterSeason, label: season ?  season.name : ''});
    }

    if (params.types.length) {
      const type = props.types.find((i) => params.types.includes(i._id));
      googleAnalitics.logEvent({ category: CATEGORY_TYPES.recipes, action: ACTION_TYPES.filterRecipeType, label: type ? type.name : '' });
    }

    if (params.maxTime) {
      const ingredients = `${params.minTime}m - ${params.maxTime}m`;
      googleAnalitics.logEvent({ category: CATEGORY_TYPES.recipes, action: ACTION_TYPES.filterCookingTime, label: ingredients });
    }

    const rating = params.minRating ? `${params.minRating}+` : 'Any';
    googleAnalitics.logEvent({ category: CATEGORY_TYPES.recipes, action: ACTION_TYPES.filterReviews, label: rating });
  }

  const clearFilters = () => {
    setState(initialFilters);
    storage.title.remove();
    storage.seasons.remove();
    storage.types.remove();
    storage.time.remove();
    storage.reviews.remove();
    storage.favorites.remove();
    storage.haveMade.remove();
    setTimeout(() => {
      getFreshRecipes();
    });
  };

  const countFilters = () => {
    let count = 0;

    count += !state.title ? 0 : 1;
    count += state.seasons.length;
    count += state.types.length;
    count += state.time[0] !== undefined || state.time[1] !== undefined ? 1 : 0;
    count += state.reviews !== undefined ? 1 : 0;
    count += state.favorites ? 1 : 0;
    count += state.haveMade ? 1 : 0;

    return count;
  };

  const openFilters = () => {
    setState({
      isFilterOpen: true,
    })
  };

  const closeFilters = () => {
    setState({
      isFilterOpen: false,
    })
  };

  const getFreshRecipes = (params) => {
    setState({
      recipes: [],
      page: 0,
      shouldFetchMore: true,
    });

    countRecipesWithFilters(getQuery(params));
  };

  const applySearchFilter = (e) => {
    if (e.key === 'Enter') {
      const newValue = e.target.value;
      setState({
        title: newValue,
      });
      storage.title.set(newValue);
    }
  };

  const handleSearchStringChange = (ev) => {
    const newValue = ev.target.value;
    setState({ search: newValue });
  }

  const onRangeChange = (value: number[]) => {
    setState({
      time: [0, value[0]]
    })
  }

  const getFiltersWithProps = () => {
    return <RecipesFilter
      season={state.seasons}
      type={state.types}
      time={state.time}
      reviews={state.reviews}
      onChange={changeFilter}
      onClearClick={clearFilters}
      search={state.search}
      onSearchChange={handleSearchStringChange}
      onKeyPress={applySearchFilter}
      countFilters={countFilters}
      onFinalChange={(value) => changeFilter('time', [0, value[0]])}
      onRangeChange={onRangeChange}
      disableHeader={true}

      filtersCount={countFilters()}
      filteredRecipesCount={state.filteredRecipesCount}
      loadingCount={state.loadingCount}

      seasonsOptions={props.seasons}
      typesOptions={props.types}

      windowWidth={props.windowWidth}
      favorites={state.favorites}
      toggleFavorites={toggleFavorites}
      haveMade={state.haveMade}
      toggleHaveMade={toggleHaveMade}
    />;
  };

  const hasMorePages = state.page < state.pages;

  return (
    <>
    <div className='add-recipe'>
      <div
        className={cx('sidebar', {
          visible: state.isFilterOpen && props.windowWidth > 1200
        })}
      >
        {getFiltersWithProps()}
      </div>
      <div className='content-container'>
        <div className='search-container'>
          <SearchInput
            value={state.search}
            onChange={handleSearchStringChange}
            placeholder='Search all recipes...'
            onKeyPress={applySearchFilter}
            classNames={{ input: "mobile-search" }}
          />
          <div className="buttons">
            <button className='filter-button' onClick={openFilters}>
              <img src={settings} alt='Filters' />
              <p className='small-uppercase'>show filters</p>
            </button>
            <div className='divider' />
            <button className='filter-button' onClick={clearFilters}>
              <p className='small-uppercase'>Clear filters</p>
            </button>
          </div>
        </div>
          <div className='filter-statistics'>
            <div className='title'>
              Filters Selected:
              <span className='count'>{countFilters()}</span>
            </div>
            <Button className='clear-all' link onClick={clearFilters}>
              Clear filters to see all recipes
            </Button>
          </div>
        <div
          className={cx('recipes-container', {
            visible: !state.isFilterOpen || props.windowWidth > 767
          })}
        >
          <CustomSelect
            options={SORT_OPTIONS}
            value={state.sort}
            onChange={(opt: string) => {
              changeFilter('sort', opt);
            }}
            leftLabel='sort by:'
            wrapperClassName='self-end'
          />
          {state.loading || !!state.recipes.length || state.shouldFetchMore ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoad}
              hasMore={!state.loading && (state.shouldFetchMore || hasMorePages)}
              loader={null}
              threshold={0}
              className='recipes-grid-container xs-full'
            >
              <div className={cx('recipes-grid', { visible: state.isFilterOpen })}>
                {state.recipes.map((recipe, index) => (
                  <RecipeCard
                    key={`${recipe._id}-${index}`}
                    {...recipe}
                    onClick={() => props.onInfoClick(paramCase(recipe.title))}
                    withTooltip
                  />
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <NotFoundMessage />
          )}
        </div>
      </div>
      <Modal
        visible={state.isFilterOpen && props.windowWidth < 1200}
        actionButton={closeFilters}
        // mobileButton="Clear all"
        // mobileButtonAction={clearFilters}
        mobileButtonVisible={state.isFilterOpen}
        windowWidth={props.windowWidth}
        layer="layer"
      >
        {getFiltersWithProps()}
      </Modal>
    </div>
  </>
  );
}

const mapStateToProps = state => ({
  seasons: state.enum.seasons,
  types: state.enum.types,
});

const mapDispatchToProps = dispatch => ({
  getEnums: (enums: string[]) => dispatch({ type: 'GET_ENUMS', enums }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWindowSize(RecipesLayout));
