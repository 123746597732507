import React, { FC, useEffect } from "react";
import cx from "classnames";
import Button from "components/Button";
import cross from "../../assets/images/common/close_btn.svg";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PK } from "../../config";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ToastContainer, toast } from "react-toastify";

interface IProps {
  visible: boolean;
  onClose: () => void;
  title?: string;
  titleClassName?: string;
}

const AACPModal: FC<IProps> = ({ visible, onClose, title, titleClassName }) => {
  const { isLoading, all, isBuyLoading } = useAppSelector(
    state => state.crossSells
  );
  const isTrial = useAppSelector(state => state.subscriptions.user.trial);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (visible) {
      dispatch({ type: "GET_CROSS_SELLS" });
    }
  }, [visible]);

  useEffect(() => {
    if (!isBuyLoading) {
      dispatch({ type: "GET_USER_SUBSCRIPTIONS", onSuccess: () => {} });
    }
  }, [isBuyLoading]);

  const onPurschaseError = () => {
    toast.error("Cant purchase AACP", {
      position: "bottom-center"
    });
  };

  const onRedirect = (url: string) => {
    window.open(url, "_self");
  };

  const handlePurchase = () => {
    dispatch({
      type: "BUY_CROSS_SELL",
      id: all[0].id,
      onSuccess: () => {
        onClose();
        dispatch({ type: "GET_PROFILE" });
      },
      onError: onPurschaseError,
      onRedirect
    });
  };

  return (
    <div className={cx("modal aacp-modal", { visible })}>
      <div className="scrollable-layer">
        {isTrial ? (
          <div className="body">
            <p className="aacp-title">
              All Access Cleanse Pass will be available after trial period
            </p>
            <div className="footer-buttons">
              <button className="close-icon-button" onClick={onClose}>
                <img src={cross} />
              </button>
            </div>
          </div>
        ) : (
          <div className="body">
            <p className={`aacp-title ${titleClassName ?? ""}`}>
              {title ?? "ALL ACCESS CLEANSE PASS REQUIRED"}
            </p>
            <p className="message">
              Upgrade now to unlock access to cleanse plans + live cleanse
              events.
            </p>
            <div className="footer-buttons">
              <button className="close-icon-button" onClick={onClose}>
                <img src={cross} />
              </button>
              <Button
                isLoading={isLoading || isBuyLoading}
                className=""
                onClick={handlePurchase}
                disabled={isLoading || isBuyLoading}
              >
                UPGRADE: ${all[0]?.price}
              </Button>
            </div>
            <span>one-time charge</span>
          </div>
        )}
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        toastClassName="toast error"
        limit={1}
      />
    </div>
  );
};

export default AACPModal;
