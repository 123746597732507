import * as React from "react";
import Layout from "../../components/Layout";
import RecipesLayout from "./components/RecipesLayout";
import { useScript } from "utils/useScript";

const Recipes = (props) => {
  const goRecipeInfoPage = (titleSlug: string) => {
    props.history.push(`/recipes/${titleSlug}`);
  };

  useScript(
    `var _learnq = _learnq || [];var page = window.location.href;_learnq.push(['track', 'Viewed Recipes',{url: 'https://rawktheyear.com/recipes'}]);`
  );

  return (
    <Layout wide withoutSpacing>
      <RecipesLayout onInfoClick={goRecipeInfoPage} recipes={[]} />
    </Layout>
  );
};

export default Recipes;
