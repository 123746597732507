import * as React from "react";
import { useFormContext } from "react-hook-form";
import { Subscription } from "store/subscriptions/types";
import { useHistory, useLocation } from "react-router";
export interface ISignRadioProps extends Subscription {
  index: number;
  className?: string;
}

// const PLAN_TITLE = {
//   starter_monthly: "14-day free trial (then $19.99/mo)",
//   maintenance_quarterly: "3 months: $44.99/qtr",
//   lifestyle_yearly: "12 months: $99 (with code: RAWK24)",
// };

export function SignRadio(props: ISignRadioProps) {
  const { index, lookupKey, className } = props;
  const history = useHistory();
  const location = useLocation();

  const { register } = useFormContext();

  return (
    <div className={`sign-radio sign-radio-${className}`}>
      <label htmlFor={index.toString()}>
        <input
          {...register("subscriptionIndex")}
          type="radio"
          id={index.toString()}
          name="subscriptionIndex"
          value={index}
          onChange={(e) => {
            const params = new URLSearchParams({ subscriptionType: lookupKey });
            history.replace({
              pathname: location.pathname,
              search: params.toString(),
            });
          }}
          className="sign-radio__dot"
        />
      </label>
    </div>
  );
}
