// @ts-nocheck
import * as React from "react";
import cx from "classnames";

import Checkbox from "../../../../forms/Checkbox";
import closeWhite from "../../../../assets/images/common/close_btn.svg";
import Button from "../../../../components/Button";
import { getIngredientsListAmount } from "../../../../utils/shoppingListFunctions";

type Props = {
  visible: boolean;
  close: () => void;
  date: string;
};

type State = {
  products: boolean[][];
};

type CartElementProps = {
  product: {
    amount: number;
    unit: string;
    name: string;
  };
};

type CartElementState = {
  active: boolean;
  deleted: boolean;
};

class CartElement extends React.Component<CartElementProps, CartElementState> {
  constructor(props) {
    super(props);
    this.state = {
      active: props.selected,
      deleted: false
    };
  }

  toggleCheckbox = () => {
    this.setState(
      {
        active: !this.state.active
      },
      () => this.props.onSelectionToggled(this.state.active)
    );
  };

  render() {
    if (this.state.deleted) {
      return null;
    }
    let ingredientName = this.props.product.unicode;
    // if (this.props.product.amount.length) {
    //   ingredientName += ': ' + getIngredientsListAmount(this.props.product.amount.replace('⁄','/'),1,this.props.product.measurementUnit)
    // }

    return (
      <div className="shopping-cart-element">
        <Checkbox
          name={ingredientName}
          active={this.state.active}
          onChange={this.toggleCheckbox}
          lowercase
          circle
          spanClassName="grocery-list-checkbox"
        />
      </div>
    );
  }
}

type GroceryListProps = {
  visible: boolean;
  close: () => void;
  setExcludedIngredients: (ings: any[]) => void;
  excludedIngredients: any[];
  openDeliveryPage?: () => void;
  date: string;
  list: {
    category: string;
    ingredients: {
      amount: number;
      unit: string;
      name: string;
    }[];
  }[];
  planId?: string;
};

class GroceryList extends React.Component<GroceryListProps> {
  state = {};

  handleClose = () => {
    this.props.close();
  };
  setProductSelection = (product, selected) => {
    if (selected) {
      this.props.setExcludedIngredients([
        ...this.props.excludedIngredients,
        product.ingredient
      ]);
    } else {
      this.props.setExcludedIngredients(
        this.props.excludedIngredients.filter(
          ingredient => ingredient !== product.ingredient
        )
      );
    }
  };
  openDeliveryPage = () => {
    this.props.close();
    this.props.openDeliveryPage();
  };
  render() {
    return (
      <div>
        <div className="grocery-header">
          <h2 className="title">Grocery List</h2>
          <p className="plain">
            Check off the items you already have. This will remove them from
            your printable grocery list.
          </p>
          <p className="complete">When done, hit close button.</p>
          <button className="close-button" onClick={this.handleClose}>
            <img src={closeWhite} alt="Close" />
          </button>
        </div>
        <div
          className={cx("shopping-cart-modal", {
            visible: this.props.visible,
            withPDF: !!this.state.url
          })}
        >
          <div className="embded-pdf-container">
            {this.state.url && (
              <iframe
                className="embded-pdf-preview"
                title="PDF in an i-Frame"
                src={this.state.url}
                frameBorder="1"
                scrolling="auto"
              ></iframe>
            )}
            <div>
              <div className="shopping-cart-grid">
                {this.props.list.map(list => (
                  <div className="shopping-cart-block">
                    <p className="small-uppercase">{list.category}</p>
                    {list.ingredients.map(product => (
                      <CartElement
                        selected={this.props.excludedIngredients.includes(
                          product.ingredient
                        )}
                        onSelectionToggled={selected =>
                          this.setProductSelection(product, selected)
                        }
                        product={product}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GroceryList;
