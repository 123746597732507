import React from "react";

import tick from "../../assets/images/common/check-b.svg";

const Checkbox = props => (
  <div
    className={`checkbox ${props.active ? "active" : ""} ${
      props.lowercase ? "lowercase" : ""
    }`}
    onClick={props.onChange}
  >
    <div className={`${props.circle ? "circle" : "box"}`}>
      <img className="checkbox-img" src={tick} alt="check" />
    </div>
    <span
      style={{ textTransform: "none" }}
      className={props.spanClassName}
      dangerouslySetInnerHTML={{ __html: props.name }}
    />
  </div>
);

export default Checkbox;
