import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    all: [],
};

const eventsSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        setEvents: (state, action) => {
            state.all = action.payload.events;
        }
    }
});

export const { actions } = eventsSlice;

export default eventsSlice.reducer;
