import { call, put, takeLatest } from "redux-saga/effects";
import { callUpdateUser } from "../../api/user";
import { actions as UserActions } from '../../store/user/reducer';

export function* updateUser(action) {
  try {
    yield put({ type: "UPDATE_USER_REQUEST" });
    const response = yield call(callUpdateUser, action.data);

    action.onSuccess();

    yield put(UserActions.setUserImage(response.data.profileImage || ""));

    yield put(UserActions.setUserData(action.data));
    // TODO: unused action?
    yield put({ type: "UPDATE_USER_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_USER_FAILURE",
      error: error.message
    });
  }
}


const authSaga = [
  takeLatest("UPDATE_USER", updateUser),
];

export default authSaga;
