import React from "react";

import Button from "components/Button";
import { CrossSell } from "store/crossSells/types";

interface IUpsellProduct {
  product: CrossSell;
  isAdded: boolean;
  onAdd: () => void;
}

const UpsellProduct = (props: IUpsellProduct) => {
  const { product, isAdded, onAdd } = props;
  const { name, description, image, price } = product;

  const buttonNode = isAdded ? (
    <div className="upsell-product__button upsell-product__added-label">
      Added
    </div>
  ) : (
    <Button className="button button-md upsell-product__button" onClick={onAdd}>
      ADD TO ORDER
    </Button>
  );

  const nameNode = name && <p className="upsell-product__name">{name}</p>;
  const descriptionNode = description && (
    <p className="upsell-product__description upsell-product__description-desktop">
      {description}
    </p>
  );
  const priceNode = (
    <span className="upsell-product__price">{`$${price}`}</span>
  );

  return (
    <div className="upsell-product">
      {image && (
        <img src={image} alt={name} className="upsell-product__image" />
      )}
      <div className="upsell-product__content">
        <div className="upsell-product__info">
          {nameNode}
          {descriptionNode}
        </div>
        <div className="upsell-product__right">
          {priceNode}
          {buttonNode}
        </div>
      </div>
      <div className="upsell-product__content-mobile">
        <div className="upsell-product__content__header">
          {nameNode}
          {priceNode}
        </div>
        {descriptionNode}
        {buttonNode}
      </div>
    </div>
  );
};

export default UpsellProduct;
