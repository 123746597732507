import { createSlice } from "@reduxjs/toolkit";
import {
  ISetPlans,
  ISetPlan,
  ISetCreatedPlanId,
  ISetGrecery,
  ISwapRecipe,
  IRemoveMeal
} from "./types";

const initialState = {
  all: [],
  weeklyPlans: [],
  current: [],
  grocery: [],
  selectedMeal: null,
  selectedPlanId: null,
  mealPortions: null,
  createdPlanId: "",
  planBody: null,
  allAACP: [],
  currentAACP: null,
  updateAACP: false,
  createdFromAACP: false,
};

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    setPlans: (state, action: ISetPlans) => {
      state.all = action.payload.plans;
    },
    setWeeklyPlans: (state, action) => {
      state.weeklyPlans = action.payload.plans;
    },
    setPlan: (state, action: ISetPlan) => {
      state.current = [...action.payload.data];
    },

    setPlanBody: (state, action) => {
      state.planBody = action.payload.body;
    },

    removeMeal: (state, action: IRemoveMeal) => {
      state.current = state.current.filter(
        meal => meal._id !== action.payload.id
      );
    },

    addMeal: (state, action) => {
      state.current = [...state.current, action.payload.meal];
    },

    setSelectedMeal: (state, action) => {
      state.selectedMeal = action.payload.meal;
    },

    setSelectedPlan: (state, action) => {
      state.selectedPlanId = action.payload.id;
    },

    setCreatedPlanId: (state, action: ISetCreatedPlanId) => {
      state.createdPlanId = action.payload.id;
    },

    setMealPortions: (state, action) => {
      state.mealPortions = action.payload.count;
    },

    updatePortions: (state, action) => {
      state.current = state.current.map(recipe => {
        if (recipe._id !== action.payload.id) return recipe;
        return {
          ...recipe,
          portions: action.payload.count
        };
      });
    },

    swapRecipe: (state, action: ISwapRecipe) => {
      state.current = state.current.map(meal => {
        if (meal._id !== action.payload.id) {
          return meal;
        }
        return {
          ...meal,
          recipe: action.payload.recipe
        };
      });
    },

    moveMeal: (state, action) => {
      const moveCopy = [...state.current];

      const movingMealIndex = moveCopy.findIndex(
        meal => meal._id === action.payload.id
      );
      moveCopy[movingMealIndex].date = action.payload.date;
      moveCopy[movingMealIndex].category = action.payload.category;
      moveCopy[movingMealIndex].index = action.payload.index;

      if (action.payload.currentMeal) {
        const currentMealIndex = moveCopy.findIndex(
          meal => meal._id === action.payload.currentMeal
        );
        moveCopy.splice(currentMealIndex, 1);
      }

      state.current = moveCopy;
    },

    setGrocery: (state, action: ISetGrecery) => {
      state.grocery = action.payload.grocery;
    },

    setAACPPlans: (state, action) => {
      state.allAACP = action.payload;
    },
    setCurrentAACPPlan: (state, action) => {
      state.currentAACP = action.payload;
    },
    moveAACPMeal: (state, action) => {
      const moveCopy = { ...state.currentAACP };

      const movingMealIndex = moveCopy.mealPlans.findIndex(
        meal => meal._id === action.payload.id
      );
      moveCopy.mealPlans[movingMealIndex].date = action.payload.date;
      moveCopy.mealPlans[movingMealIndex].category = action.payload.category;
      moveCopy.mealPlans[movingMealIndex].index = action.payload.newIndex;

      if (action.payload.currentMeal) {
        const currentMealIndex = moveCopy.mealPlans.findIndex(
          meal => meal._id === action.payload.currentMeal
        );
        moveCopy.mealPlans.splice(currentMealIndex, 1);
      }

      state.currentAACP = moveCopy;
      state.updateAACP = true;
    },
    resetAACPUpdate: (state) => {
      state.updateAACP = false;
    },
    setCreatedFromAACP: (state, action) => {
      state.createdFromAACP = action.payload;
    },
    updateAACPPortions: (state, action) => {
      state.currentAACP.mealPlans = state.currentAACP.mealPlans.map(recipe => {
        if (recipe._id !== action.payload.id) return recipe;
        return {
          ...recipe,
          portions: action.payload.count
        };
      });
    },
  }
});

export const { actions } = plansSlice;

export default plansSlice.reducer;
