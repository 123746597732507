import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import cx from "classnames";

import location from "../../assets/images/common/location-m.svg";
import star from "../../assets/images/common/star-b.svg";
import starEmpty from "../../assets/images/common/star-empty.svg";
import avatar from "../../assets/images/user.svg";
import Input from "../../forms/Input";
import { callUpdateReview, callRemoveReview } from "../../api/reviews";
import Button from "../Button";

export type Props = {
  _id: string;
  user: {
    _id?: string;
    id?: string;
    profileImage: null | string;
    firstName: string;
    lastName: string;
    country?: string;
  };
  date: string;
  rating: number;
  review: string;
  disableEditing?: boolean;
  userId: string;
};

type State = {
  editing: boolean;
  newRating: number;
  newContent: null | string;
  saving: boolean;
  modalVisible: boolean;
  deleted: boolean;
};

const Review = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    editing: false,
    newRating: 0,
    newContent: null,
    saving: false,
    deleted: false,
    modalVisible: false,
  });

  if (state.deleted) return null;

  const setState = (newState: Partial<State>) => setCurrentState((prevState) => ({ ...prevState, ...newState }));

  const editable =
    !props.disableEditing &&
    props.user &&
    (props.user.id || props.user._id) === props.userId;

  let editButtonText = state.editing ? "Save" : "Edit";

  if (state.saving) editButtonText = "Saving...";

  const toggleEditing = () => {
    setState({ editing: !state.editing,});
    if (state.newRating || state.newContent) saveUpdatedReview();
  };

  const saveUpdatedReview = async () => {
    const requestBody = {
      rating: state.newRating || props.rating,
      review: state.newContent || props.review,
    };

    try {
      setState({ saving: true });
      await callUpdateReview(props._id, requestBody);
      setState({ saving: false });
    } catch (err) {
      console.log(err);
    }
  };

  const changeRating = (newRating) => setState({ newRating });
  const changeContent = (e) => setState({ newContent: e.target.value });

  const openModal = () => setState({ modalVisible: true });
  const closeModal = () => setState({ modalVisible: false });

  const deleteReview = async () => {
    try {
      callRemoveReview(props._id);

      setState({ modalVisible: false, deleted: true });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={cx("review")}>
      <div className="info">
        <div className="user">
          <div
            className="photo"
            style={{
              backgroundImage: `url(${
                props.user && props.user.profileImage
                  ? props.user.profileImage
                  : avatar
              })`,
            }}
          />
          <div className="details">
            <p className="name">
              {props.user &&
                `${props.user.firstName} ${props.user.lastName[0] ? `${props.user.lastName[0]}.` : "" }`}
            </p>
            {props.user && props.user.country && (
              <p className="text-muted location">
                <img src={location} alt="Location" />
                {props.user.country}
              </p>
            )}
          </div>
        </div>
        <div className="meta-container">
          {editable && (
            <button className="edit-button" onClick={toggleEditing}>
              {editButtonText}
            </button>
          )}
          {editable && (
            <button className="edit-button" onClick={openModal}>
              Delete
            </button>
          )}
          <div className="review-rating">
            {!state.editing
              ? new Array(state.newRating || props.rating)
                  .fill('')
                  .map((_, i) => <img key={i} src={star} alt="Rating" />)
              : new Array(5)
                  .fill('')
                  .map((_, index) => (
                    <img
                      onClick={() => changeRating(index + 1)}
                      src={
                        index < (state.newRating || props.rating) ? star : starEmpty
                      }
                      alt="Rating"
                    />
                  ))}
          </div>
        </div>
      </div>
      {state.editing ? (
        <Input
          value={state.newContent !== null ? state.newContent : props.review}
          onChange={changeContent}
          textarea
        />
      ) : (
        <p className="message">{state.newContent || props.review}</p>
      )}
      {editable && (
        <div
          className={cx("modal save-plan-modal", {
            visible: state.modalVisible,
          })}
        >
          <div className="scrollable-layer">
            <div className="body">
              <p className="message">
                Are you sure you want to delete the review?
              </p>
              <div className="footer-buttons">
                <Button small limited outlined onClick={closeModal}>
                  Close
                </Button>
                <Button small limited primary onClick={deleteReview}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.id,
});

export default connect(mapStateToProps)(Review);
