import React from "react";

import Login from "../../forms/Login";
import SignUp from "../../forms/SignUp";
import Forgot from "../../forms/Forgot";
import NewPassword from "../../forms/NewPassword";
import MealPlans from "../../containers/MealPlans";
import MealPlan from "../../containers/MealPlan";
import Recipes from "../../containers/Recipes";
import Recipe from "../../containers/Recipe";
import Rawkstars from "../../containers/Rawkstars";
import ContactUs from "../../forms/ContactUs";
import AccountForm from "../../forms/AccountForm";
import Wellness from "../../containers/Wellness";
import Article from "../../containers/Article";
import Event from "../../containers/Event";
import PaymentForm from "../../forms/PaymentForm";
// import Affiliate from "../../containers/Affiliate";
import SalesPage from "../../containers/Sales";
import UpgradePlan from "../../containers/UpgradePlan";
import CheckSubscriprion from "../CheckSubscriprion";
import { Plans } from "containers/Plans";
import { NewSignUp } from "forms/SignUp/index.new";
import AACPMealPlan from "containers/AACPMealPlan";

export const defaultRoutesList = {
  unauthRoutes: [
    { path: "/", component: SalesPage, isShowHelper: false },
    { path: "/login", component: Login, isShowHelper: false },
    { path: "/sign-up", component: NewSignUp, isShowHelper: false },
    // { path: "/sign-up/:subscriptionId", component: NewSignUp, isShowHelper: false },

    // {
    //   path: "/maintenance-plan",
    //   component: () => <SignUp isMaintenancePlan={true} />,
    //   isShowHelper: false,
    // },
    // {
    //   path: "/lifestyle-plan",
    //   component: () => <SignUp isLifestylePlan={true} />,
    //   isShowHelper: false,
    // },
    { path: "/forgot", component: Forgot, isShowHelper: false },
    {
      path: "/reset-password/:token",
      component: NewPassword,
      isShowHelper: false,
    },
  ],
  authRoutes: [
    { path: "/contact-us", component: ContactUs, isShowHelper: false },
  ],
};

export const memberRoutesList = {
  authRoutes: [
    //   { path: "/account/refer-a-friend", component: Affiliate },
    { path: "/account/:section", component: AccountForm, isShowHelper: true },
    { path: "/checkout/:planId", component: PaymentForm, isShowHelper: false },
    { path: "/home", component: MealPlans, isShowHelper: true },
    { path: "/plans", component: Plans, isShowHelper: true },
    { path: "/plans/:id", component: MealPlan, isShowHelper: false },
    { path: "/plans/aacp/:id", component: AACPMealPlan, isShowHelper: false },
    { path: "/recipes", component: Recipes, isShowHelper: true },
    { path: "/recipes/:titleSlug", component: Recipe, isShowHelper: false },
    { path: "/rawkstars", component: Rawkstars, isShowHelper: false },
    { path: "/trial", component: Wellness, isShowHelper: false },
    { path: "/wellness/article/:id", component: Article, isShowHelper: false },
    { path: "/wellness/event/:id", component: Event, isShowHelper: false },
    { path: "/upgrade-plan", component: UpgradePlan, isShowHelper: false },
  ],
};

export const guestRoutesList = {
  authRoutes: [
    { path: "/account/:section", component: AccountForm, isShowHelper: false },
    { path: "/checkout/:planId", component: PaymentForm },
    { path: "/applying-subscription", component: CheckSubscriprion },
  ],
};
