import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    users: [],
    reviews: [],
    statistics: [],
};

const rawkstarsSlice = createSlice({
    name: "rawkstars",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload.users;
        },
        setReviews: (state, action) => {
            state.reviews = action.payload.reviews;
        },
        setStatistics: (state, action) => {
            state.statistics = action.payload.statistics;
        }
    }
})

export const { actions } = rawkstarsSlice;

export default rawkstarsSlice.reducer;
