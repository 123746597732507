import Button from "components/Button";
import SavePlanModal from "components/SavePlanModal";
import moment from "moment";
import * as React from "react";
import { useAppDispatch } from "store/hooks";
import { useHistory } from "react-router-dom";
import { useGetCurrentWeek } from "utils/customHooks/use-get-current-week";
import addCircle from "../../../../assets/images/common/add-circle.svg";
export interface ICreatePlanProps {}

export function CreatePlan(props: ICreatePlanProps) {
  const [opened, setOpened] = React.useState(false);
  const { start, end } = useGetCurrentWeek();
  const [error, setError] = React.useState("");
  const [successModalVisible, setSuccessModalVisible] = React.useState(false);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const savePlanHandler = (name: string) => {
    dispatch({
      type: "SAVE_PLAN",
      body: {
        name: name,
        start: start,
        end: end,
        mealPlans: [],
      },
      onSuccess: (plan) =>
        history.push({
          pathname: `/plans/${plan.nameSlug}`,
        }),
      onError: (error) => {
        setError(error);
      },
    });
  };

  return (
    <>
      <button className="create-plan-button" onClick={() => setOpened(true)}>
        <span>Create a plan</span>
        <img src={addCircle} alt="add" />
      </button>

      <SavePlanModal
        classNames={{ title: "create-plan-title" }}
        isCreatePlan
        visible={opened}
        onSave={savePlanHandler}
        close={() => setOpened(false)}
        currentName={""}
        confirmRequired={true}
        error={error}
        setError={setError}
        closeSuccess={setSuccessModalVisible}
        visibleSuccess={successModalVisible}
      />
    </>
  );
}
