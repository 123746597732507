import { isDev } from '../config';

const colors = {
  info: '\x1b[36m%s\x1b[0m',
  warn: '\x1b[33m%s\x1b[0m',
  error: '\x1b[31m%s\x1b[0m',
};

export default {
  info: (...args) => {
    if (!isDev) { return; }
  },
  warn: (...args) => {
    if (!isDev) { return; }

    console.warn(colors.warn, 'WARNING: ', ...args);
  },
  error: (...args) => {
    console.error(colors.error, `ERROR:   (${new Date().toLocaleString()})`, ...args);
  },
};
