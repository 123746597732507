// @ts-nocheck
import * as React from "react";
import { isMobile } from "mobile-device-detect";
import cx from "classnames";

import Checkbox from "../../../../forms/Checkbox";

import email from "../../../../assets/images/common/email.svg";
import closeWhite from "../../../../assets/images/common/close_btn.svg";
import Button from "../../../../components/Button";
import { callGetShoppingList } from "../../../../api/plans";
import { monthsObject } from "../../../../utils/index";
import moment from "moment";

type Props = {
  visible: boolean;
  close: () => void;
  date: string;
};

type State = {
  products: boolean[][];
};

type DeliveryPageProps = {
  visible: boolean;
  close: () => void;
  date: string;
  list: {
    category: string;
    ingredients: {
      amount: number;
      unit: string;
      name: string;
    }[];
  }[];
  start: string;
  end: string;
  planId?: string;
  excludedIngredients?: any[];
  isAACP?: boolean;
};

export const base64toBlob = (data, type) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type });
};

// INFO: This component not used. Can be deleted later.
class DeliveryPageModal extends React.Component<DeliveryPageProps> {
  state = {
    configuringPrint: false,
    includes: {
      mealPlan: true,
      list: true,
      prep: true,
      images: true
    },
    delivery:
      process.env.REACT_APP_ENVIRONMENT === "production" ? "email" : "pdf",
    excludedIngredients: [],
    processingRequest: false,
    showSuccess: false,
  };

  closeConfiguration = () => {
    if (this.state.url) {
      this.setState({
        url: ""
      });
      return;
    }

    this.setState({
      configuringPrint: false
    });
  };

  changeIncludes = name => {
    this.setState({
      includes: {
        ...this.state.includes,
        [name]: !this.state.includes[name]
      }
    });
  };

  savePDF = href => {
    const downloadLink = document.createElement("a");
    const fileName = "Meal Plan.pdf";
    downloadLink.href = href;
    downloadLink.download = fileName;
    downloadLink.setAttribute("target", "_blank");
    downloadLink.click();
  };

  requestList = async () => {
    this.setState({ processingRequest: true, showSuccess: true });

    setTimeout(() => {
      this.setState({ showSuccess: false });
      this.props.close();
    }, 5000);

    const params = {
      method: this.state.delivery,
      sections: Object.keys(this.state.includes).filter(
        key => this.state.includes[key]
      ),
      excludedIngredients: this.props.excludedIngredients,
      isAACP: !!this.props.isAACP
    };
    if (!this.state.includes.images) {
      params.excludeImages = 1;
    }
    if (this.props.planId) {
      params.planId = this.props.planId;
    } else if (this.props.start && this.props.end) {
      params.start = moment(this.props.start).format("MM/DD/YYYY");
      params.end = moment(this.props.end).format("MM/DD/YYYY");
    }
    try {
      const response = await callGetShoppingList(
        params,
        !this.state.includes.images ? "?excludeImages=1" : ""
      );

      if (this.state.delivery === "pdf") {
        const blob = base64toBlob(response.data.pdf, "application/pdf");
        const fileURL = URL.createObjectURL(blob);

        if (isMobile) {
          this.savePDF(fileURL);
        } else {
          window.open(fileURL);
        }
      } else {
        this.setState({ sent: true });
        this.closeConfiguration();
      }
    } catch (err) {
      console.error("PDF creation error: ", err);
    }
    this.setState({ processingRequest: false });
  };

  handleClose = () => {
    this.props.close();
    this.setState({
      configuringPrint: false,
      includes: {},
      delivery: "email",
      sent: false
    });
  };

  render() {
    let printButtonText = "CREATE PRINTABLE PDF";
    if (this.state.processingRequest) {
      printButtonText = "PROCESSING...";
    } else if (this.state.sent) {
      printButtonText = "SENT TO EMAIL";
    }
    return (
      <div
        className={cx("modal print-modal", {
          visible: this.props.visible,
          withPDF: !!this.state.url
        })}
      >
        <div className="scrollable-layer">
          <div className="body">
            {this.state.showSuccess ? (
              <div className="success-modal-mock success-modal-mock-gap">
                <img className="success-modal-mock_icon" src={email} />
                <p className="success-modal-mock_message">Plan sent to email</p>
                <p className="success-modal-mock_text success-modal-mock_text_80">
                  Depending on how detailed your meal plan is, it can take some time
                  to create this gorgeous PDF. Yet trust us, it's worth it!
                </p>
                <div className="success-modal-mock_divider" />
                <p className="success-modal-mock_close">
                  ( window will close automatically )
                </p>
              </div>
            ) : (
              <>
                {" "}
                <button
                  className="close-button"
                  onClick={
                    this.state.configuringPrint
                      ? this.closeConfiguration
                      : this.handleClose
                  }
                >
                  <img src={closeWhite} alt="Close" />
                </button>
                {this.state.url && (
                  <iframe
                    className="embded-pdf-preview"
                    title="PDF in an i-Frame"
                    src={this.state.url}
                    frameBorder="1"
                    scrolling="auto"
                  ></iframe>
                )}
                <h2 className="print-title">select items to print</h2>
                <div className="variants-container">
                  <Checkbox
                    active={this.state.includes.mealPlan}
                    name="Meal Plan"
                    circle={true}
                    onChange={() => this.changeIncludes("mealPlan")}
                    spanClassName="checkbox-span"
                  />
                  <Checkbox
                    active={this.state.includes.list}
                    name="Grocery List"
                    circle={true}
                    onChange={() => this.changeIncludes("list")}
                    spanClassName="checkbox-span"
                  />
                  <Checkbox
                    active={this.state.includes.prep}
                    name="Recipes"
                    circle={true}
                    onChange={() => this.changeIncludes("prep")}
                    spanClassName="checkbox-span"
                  />
                  <Checkbox
                    active={this.state.includes.images}
                    name="Include recipe photos"
                    circle={true}
                    onChange={() => this.changeIncludes("images")}
                    spanClassName="checkbox-span"
                  />
                </div>
                <Button
                  primary
                  onClick={this.requestList}
                  disabled={
                    !Object.keys(this.state.includes).filter(
                      key => this.state.includes[key]
                    ).length ||
                    this.state.processingRequest ||
                    this.state.sent
                  }
                  className="print-button"
                >
                  {printButtonText}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryPageModal;
