import { useEffect } from "react";

export const useShowHelper = (isShow = false) => {
  useEffect(() => {
    const helper = document.body;

    const observer = new MutationObserver((mutations) => {
      const expectedBeaconId = "beacon-container";
      const beaconEl = mutations[0].addedNodes[0];

      if ((beaconEl as HTMLElement)?.id === expectedBeaconId && isShow) {
        (beaconEl as HTMLElement).classList.add("active");
      }
    });

    observer.observe(helper, {
      characterData: true,
      childList: true,
      attributes: true,
      attributeFilter: ["class", "style"],
    });
  }, [isShow]);

  useEffect(() => {
    const helper = document.body;
    const scout = helper.querySelector("#beacon-container");
    if (!scout) return;

    if (isShow) {
      scout.classList.add("active");
    } else {
      scout.classList.remove("active");
    }
  }, [isShow]);
};
