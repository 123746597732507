import Button from "components/Button";
import * as React from "react";
import ReactDOM from "react-dom";
import close from "../../../../assets/images/common/close-white.svg";
import Modal from "components/Modal/Modal";
import SuccessModal from "containers/MealPlan/components/SuccessModal";

export interface IUpgradeDropdownProps {
  isVisible: boolean;
  onClose: () => void;
  onClick: () => void;
  isLoading: boolean;
}

export function UpgradeDropdown(props: IUpgradeDropdownProps) {
  const { isVisible, onClose, onClick, isLoading } = props;

  const [isShowConfirm, setIsShowConfirm] = React.useState(false);

  if (!isVisible) return <></>;

  return ReactDOM.createPortal(
    <div className="upgrade-dropdown">
      <div className="upgrade-dropdown--content">
        <h4 className="upgrade-dropdown--title">MEMBERS ONLY</h4>
        <p className="upgrade-dropdown--description">
          Upgrade to a paid plan to unlock access to community support & live
          events.
        </p>
        <Button isLoading={isLoading} onClick={() => setIsShowConfirm(true)}>
          UPGRADE NOW
        </Button>
      </div>
      <button className="upgrade-dropdown--close" onClick={onClose}>
        <img src={close} alt="close" />
      </button>
      <Modal
        close={() => setIsShowConfirm(false)}
        content="I'm ready to end my free trial + activate my membership. I agree to be charged $19.99/month until I cancel or change plans."
        onClick={onClick}
        visible={isShowConfirm}
      />
    </div>,
    document.body
  );
}
