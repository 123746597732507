// @ts-nocheck
import React, { useEffect } from "react";
// import Features from './Features';
// import Header from './Header';
// import Jumbotron from './Jumbotron';
// import Logos from './Logos';
// import Advantages from './Advantages';
// import AlsoIncluded from './AlsoIncluded';
// import Oprah from './Oprah';
// import Personalized from './Personalized';
// import WellnessPreview from './WellnessPreview';
// import Reviews from './Reviews';
// import ExpandedReviews from './ExpandedReviews';

// import beverly from '../../assets/images/beverly.png';
// import kavli from '../../assets/images/kavli.png';
// import shannon from '../../assets/images/shannon.png';
// import jennifer from '../../assets/images/jennifer.png';
// import Charles from './Charles';
// import Pricing from './Pricing';
// import Forecast from './Forecast';
// import ReviewsSlider from './ReviewsSlider';
// import Florence from './Florence';
// import Why from './Why';
// import FAQ from './FAQ';
// import Footer from './Footer';
// import { googleAnalitics, CATEGORY_TYPES, ACTION_TYPES } from '../../utils/GoogleAnalitics';

const SalesPage = () => {
  useEffect(() => {
    window.open("https://simplegreensmoothies.com/meal-planner", "_self");
  }, []);

  return <></>;
  // const [isMobile, setIsMobile] = useState(false);
  // const benefitsRef = useRef(null);
  // const aboutUsRef = useRef(null);
  // const pricingRef = useRef(null);
  // const testimonialsRef = useRef(null);
  // const faqsRef = useRef(null);
  // const [activeLinkNum,setActiveLinkNum] = useState(0);
  // const refs = [benefitsRef,pricingRef,testimonialsRef,aboutUsRef,faqsRef];

  // const handler = () => {
  //   setIsMobile(window.innerWidth <= 475);
  // };
  // useEffect(() => {
  //     googleAnalitics.logEvent({
  //         category: CATEGORY_TYPES.ecommerce,
  //         action: ACTION_TYPES.LandOnSalesPage
  //       });

  //       handler();
  //       window.addEventListener('resize', handler);
  //       window.addEventListener('scroll',scrollHandler)

  //       return () => {
  //           window.removeEventListener('resize', handler);
  //           window.removeEventListener('scroll', scrollHandler);
  //       }
  //   }, []);

  //   const goToRef = (ref) => {
  //     const offset = document.getElementsByClassName('sales-header')[0].scrollHeight
  //     window.scrollTo({
  //       top: ref.current.offsetTop - offset,
  //       left: 0,
  //       behavior: 'smooth',
  //     })
  //   }
  //   const scrollHandler = (event) => {
  //     const offset = document.getElementsByClassName('sales-header')[0].scrollHeight + 10;
  //       const currentPos =event.currentTarget.scrollY + offset;
  //       refs.map((ref,index) => {
  //           if (currentPos <= refs[0].current.offsetTop) {
  //                   setActiveLinkNum(0);
  //           }
  //           if (currentPos > ref.current.offsetTop
  //             // && currentPos <= ref.current.offsetTop + ref.current.offsetHeight
  //             ) {
  //               setActiveLinkNum(index + 1);
  //           }
  //       })
  //   }

  // return (
  //   <div  className='sales-page'>
  //     <style>{'body { max-width: 100vw; overflow-x: hidden }'}</style>
  //     {!isMobile ? (
  //       <>
  //         <Header refs={refs} activeLink={activeLinkNum} goToRef={goToRef}/>
  //         <Jumbotron />
  //         <Features refLink={benefitsRef}/>
  //         <Logos />
  //         <Advantages />
  //         <AlsoIncluded/>
  //         <Oprah />
  //         <Personalized />
  //         <WellnessPreview />
  //         <Reviews />
  //         <ExpandedReviews
  //           withMobileTitle
  //           withAngles
  //           image={beverly}
  //           title='I learned that I love cooking and a plant-based diet.'
  //           body='Menopause hit me hard and I gained 50 lbs over 3 years. I used to say that my favorite things were "Rest & Baked Goods". But, over the past two years I have learned that I love cooking, eating a plant based diet and starting my day with Simple Green Smoothies. I love how colorful the vegetables and fruits are. Preparing our meals has just become so enjoyable with everything laid out for me weekly. I’ve also learned that spin classes, weight lifting and yoga have become essentials in my daily life.'
  //           author='— Beverly Doucette'
  //         />
  //         <ExpandedReviews
  //           extraClass='kavli-review'
  //           dark
  //           reversed
  //           withShapeTop
  //           image={kavli}
  //           title='I feel like I’m growing younger every year.'
  //           body='Since joining Rawk The Year, my energy levels have soared. I’ve found foods to fuel my passions. I’ve ran across the Grand Canyon and taken hiking trips I never imagined I could do. I’m more active now than I was a decade ago! I feel like I am growing younger every year. I’ve learned so much about plant-based foods and tried new amazing flavor combinations. I’ve tried and learned to love veggies I swore I  would never eat again (hello Brussels sprouts!!). '
  //           author='— Kavli Krukeja'
  //         />
  //         <Charles />
  //         <ExpandedReviews
  //           extraClass='shannon-review'
  //           dark
  //           image={shannon}
  //           title="It's now been 8 months, I've lost 45lbs …"
  //           body={
  //             <>
  //               Like so many others, covid was impacting my lifestyle and I found myself at home,
  //               eating junk constantly. I was upset with my weight. My relationship with food was a
  //               coping mechanism for me to deal with my emotions, boredom, stress, problems and
  //               overall unhappiness with how I looked and felt. <br />
  //               <br /> With the support of the cleanses, Rawk The Year meal plan and community, I
  //               have the accountability and long term support I need to be successful, see results
  //               and maintain them. It's now been 8 months, I've lost 45lbs and continue to make
  //               healthy choices for myself!
  //             </>
  //           }
  //           author='— Shannon Bryck'
  //         />
  //         <ExpandedReviews
  //           extraClass='jennifer-review'
  //           withAngles
  //           withShape
  //           reversed
  //           image={jennifer}
  //           title="It's provided the accountability and support I needed."
  //           body="It's provided the accountability and support I needed to curb cravings and keep making healthy choices. Everything I have experienced from SGS has been of the highest quality. Their books are beautiful, their recipes are absolutely amazing, and they have a true passion for helping others live a better life. I have not been disappointed."
  //           author='— Jennifer Alexander'
  //         />
  //         <Pricing refLink={pricingRef}/>
  //         <Forecast />
  //         <ReviewsSlider refLink={testimonialsRef}/>
  //         <Florence />
  //         <Why  refLink={aboutUsRef} />
  //         <FAQ refLink={faqsRef}/>
  //         <Footer />
  //       </>
  //     ) : (
  //       <>
  //         <Header refs={refs} activeLink={activeLinkNum} goToRef={goToRef}/>
  //         <Jumbotron />
  //         <Features refLink={benefitsRef}/>
  //         <Personalized />
  //         <Oprah />
  //         <Logos />
  //         <ExpandedReviews
  //           withMobileTitle
  //           withAngles
  //           image={beverly}
  //           title='I learned that I love cooking and a plant-based diet.'
  //           body='Menopause hit me hard and I gained 50 lbs over 3 years. I used to say that my favorite things were "Rest & Baked Goods". But, over the past two years I have learned that I love cooking, eating a plant based diet and starting my day with Simple Green Smoothies. I love how colorful the vegetables and fruits are. Preparing our meals has just become so enjoyable with everything laid out for me weekly. I’ve also learned that spin classes, weight lifting and yoga have become essentials in my daily life.'
  //           author='— Beverly Doucette'
  //         />
  //         <ExpandedReviews
  //           extraClass='kavli-review'
  //           dark
  //           reversed
  //           image={kavli}
  //           title='I feel like I’m growing younger every year.'
  //           body='Since joining Rawk The Year, my energy levels have soared. I’ve found foods to fuel my passions. I’ve ran across the Grand Canyon and taken hiking trips I never imagined I could do. I’m more active now than I was a decade ago! I feel like I am growing younger every year. I’ve learned so much about plant-based foods and tried new amazing flavor combinations. I’ve tried and learned to love veggies I swore I  would never eat again (hello Brussels sprouts!!). '
  //           author='— Kavli Krukeja'
  //         />
  //         <ExpandedReviews
  //           extraClass='shannon-review'
  //           dark
  //           image={shannon}
  //           title="It's now been 8 months, I've lost 45lbs …"
  //           body={
  //             <>
  //               Like so many others, covid was impacting my lifestyle and I found myself at home,
  //               eating junk constantly. I was upset with my weight. My relationship with food was a
  //               coping mechanism for me to deal with my emotions, boredom, stress, problems and
  //               overall unhappiness with how I looked and felt. <br />
  //               <br /> With the support of the cleanses, Rawk The Year meal plan and community, I
  //               have the accountability and long term support I need to be successful, see results
  //               and maintain them. It's now been 8 months, I've lost 45lbs and continue to make
  //               healthy choices for myself!
  //             </>
  //           }
  //           author='— Shannon Bryck'
  //         />
  //         <ExpandedReviews
  //           extraClass='jennifer-review'
  //           withAngles
  //           withShape
  //           reversed
  //           image={jennifer}
  //           title="It's provided the accountability and support I needed."
  //           body="It's provided the accountability and support I needed to curb cravings and keep making healthy choices. Everything I have experienced from SGS has been of the highest quality. Their books are beautiful, their recipes are absolutely amazing, and they have a true passion for helping others live a better life. I have not been disappointed."
  //           author='— Jennifer Alexander'
  //         />
  //         <Advantages />
  //         <AlsoIncluded title='Way more than just recipes…' />
  //         <WellnessPreview />
  //         <Reviews />
  //         <Florence />
  //         <Why refLink={aboutUsRef} />
  //         <Pricing refLink={pricingRef}/>
  //         <ReviewsSlider title='Real Reviews' refLink={testimonialsRef}/>
  //         <FAQ refLink={faqsRef}/>
  //         <Footer />
  //       </>
  //     )}
  //   </div>
  // );
};

export default SalesPage;
