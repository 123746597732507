import { call } from "../core";

export const callSignIn = (email, password) => {
  return call("/auth/signin", "POST", { body: { email, password } });
};

export const callSignUp = (body) => {
  return call("/auth/signup", "POST", { body });
};

export const callRequestReset = (email) => {
  return call("/auth/password/reset", "POST", { body: { email } });
};

export const callResetPassword = (token, password) => {
  return call("/auth/password/reset", "PUT", { body: { token, password } });
};

export const callAbTest = () => {
  return call("/ab", "GET", {});
};

export const callSendAbandonedUser = (body: {
  email: string;
  firstName: string;
  lastName: string;
}) => {
  return call("/auth/abandoned", "POST", { body });
};
