import { IRecipeFilterRequest } from './type';
import { call } from "../core";

export const callGetRecipes = (page: number, params?: Object) => {
  return call(`/recipe/list/${page}`, "GET", { params });
};

export const callGetRecipesFilter = (params: IRecipeFilterRequest) => {
  return call(`/recipes/`, "GET", { params });
};

export const callGetTopRecipesFilter = (params: IRecipeFilterRequest) => {
  return call(`/recipes/top`, "GET", { params });
};

export const callGetRecipe = (id: string) => {
  return call(`/recipe/${id}`, "GET", {});
};

export const callGetRecipeByTitle = (titleSlug: string) => {
  return call(`/recipe/title/${titleSlug}`, "GET", {});
};

export const callMakeRecipeFavorite = (id: string) => {
  return call(`/favorite/${id}`, "POST", {});
};

export const callRemoveRecipeFavorite = (id: string) => {
  return call(`/favorite/${id}`, "DELETE", {});
};
