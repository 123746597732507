import React from "react";
import cx from 'classnames';

type Props = {
  title: string,
  spaceless?: boolean,
  className?: string,
  subtitle?: string,
  contentEditable?: any,
  onKeyPress?: any,
  onBlur?: any,
};

const PageHeader = (props: Props) => (
  <h2 {...props} className={cx("section-header", props.className, { spaceless: props.spaceless })}>
    {props.title}
    {props.subtitle && <div className="subtitle">{props.subtitle}</div>}
  </h2>
);

export default PageHeader;
