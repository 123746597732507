import React, { FC, useState } from "react";
import cx from "classnames";
import ClickOutside from "react-click-outside";

interface IProps {
  options: { value: string | number; label: string }[];
  value: string | number;
  leftLabel?: string;
  labesClassName?: string;
  wrapperClassName?: string;
  onChange: (value: string | number) => void;
}

const CustomSelect: FC<IProps> = ({
  options,
  value,
  leftLabel,
  labesClassName,
  wrapperClassName,
  onChange
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (val: string | number) => () => {
    setIsOpen(false);
    onChange(val);
  };

  return (
    <div className={cx("custom-select", wrapperClassName)}>
      {leftLabel && (
        <label className={cx("label", labesClassName)}>{leftLabel}</label>
      )}
      <ClickOutside onClickOutside={() => setIsOpen(false)}>
        <div className="select">
          <button className="toggle" onClick={() => setIsOpen(prev => !prev)}>
            {value}
          </button>
          {isOpen && (
            <div className="options-wrapper">
              {options.map(opt => (
                <div
                  key={opt.value}
                  onClick={handleChange(opt.value)}
                  className="option"
                >
                  {opt.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  );
};

export default CustomSelect;
