import React, {useEffect, useState} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { STRIPE_PK } from "../../config";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import { callCreateSubscriptionWithCard } from "../../api/subscriptions";
// import planerScreenshot from '../../assets/images/planer-screen.png';
// import circleLogo from '../../assets/images/circle-logo.png';
import checkout from '../../assets/images/checkout.png';
import CountrySelect from "../CountrySelect";
import { actions as SubscriptionActions } from "../../store/subscriptions/reducer";
import { googleAnalitics, CATEGORY_TYPES, ACTION_TYPES } from '../../utils/GoogleAnalitics';

import visa from '../../assets/images/visa.png';
import mastercard from '../../assets/images/mastercard.png';
import amex from '../../assets/images/amex.png';
import discover from '../../assets/images/discover.png';
import lock from '../../assets/images/lock.png';

import { useAppSelector, useAppDispatch } from '../../store/hooks'

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#0D0C0C',
      fontFamily: 'MontserratRegular, Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#B9B8B8'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm = (props) => {
  const profile = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [purchased, setPurchased] = useState(false);
  const [name, setName] = useState(profile.name);
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState(profile.country);
  const [countryCode, setCountryCode] = useState(profile.countryCode);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');

  const handleZipChange = e => {
    if (!isNaN(Number(e.target.value))) {
     setZip(e.target.value);
    }
  };

  const handleCountryChange = (country, countryCode) => {
    setCountry(country);
    setCountryCode(countryCode);
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    if(loading) {
      return;
    }
    setLoading(true);
    event.preventDefault();
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card, {
      name,
      address_zip: zip,
      address_country: countryCode,
    });

    if (!result.error) {
      try {
        const response = await callCreateSubscriptionWithCard(props.planId, result.token.id);
        // @ts-ignore
        window.rewardful('convert', { email: profile.email });
        dispatch(SubscriptionActions.setUserSubscriptions(response.data))
        setPurchased(true);

        googleAnalitics.logEvent({ category: CATEGORY_TYPES.subscription, action: ACTION_TYPES.subscribe, label: 'trial' });

        props.history.push('/home');
      } catch (err) {
        setError(err.response && err.response.data && err.response.data.data && err.response.data.data.error);
      }
    } else {
      setError(result.error.message);
    }

    setLoading(false);
  };

  let buttonText = loading ? 'Starting...' : 'Start Now';

  if (purchased) {
    buttonText = 'Success'
  }

  return (
    <div className="card-wrapper">
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <h3>Credit Card</h3>
        <div className="subtitle-container">
          <p className="subtitle">All transactions are secure and encrypted.</p>
          <div className="card-provides">
            <div style={{ backgroundImage: `url(${visa})`}} />
            <div style={{ backgroundImage: `url(${mastercard})`}} />
            <div style={{ backgroundImage: `url(${amex})`}} />
            <div style={{ backgroundImage: `url(${discover})`}} />
          </div>
        </div>
        <div className="card-container">
          <div className="card-number">
            <CardNumberElement options={{ ...CARD_ELEMENT_OPTIONS, placeholder: 'Card number' }}/>
            <img src={lock} alt="Lock" className="lock" />
          </div>
          <div className="card-extra">
            <div className="extra-cell">
              <CardExpiryElement options={{ ...CARD_ELEMENT_OPTIONS, placeholder: 'Expiration date (MM/YY)' }} />
            </div>
            <div className="extra-cell">
              <CardCvcElement options={{ ...CARD_ELEMENT_OPTIONS, placeholder: 'Security code' }} />
            </div>
            <div className="extra-cell">
              <input className="zip-input" placeholder="Billing zip code" value={zip} onChange={handleZipChange}/>
            </div>
          </div>
        </div>
        <input className="name-input" placeholder="Name on Card" value={name} onChange={e => setName(e.target.value)}/>
        <CountrySelect placeholder="Country" value={country} onChange={handleCountryChange} />
      </div>
     <div className="button-container">
       <Button disabled={loading} primary>{buttonText}</Button>
       {purchased && (<span className="success">Your account has been activated successfully</span>)}
       {!purchased && (<span className="error">{error}</span>)}
     </div>
      <p className="card-description">You won't be charged during your free trial. If you fall in love with Rawk the Year, you'll automatically be upgraded to a $25 monthly membership on day 15.</p>
    </form>
    </div>
  );
}

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(STRIPE_PK);

export default (props) => {
  const dispatch = useAppDispatch();
  const currentSubscription = useAppSelector(state => state.subscriptions.user);
  const neededSubscription = useAppSelector(state => state.subscriptions.all.find(s => s._id == props.match.params.planId));

  useEffect(() => {
    dispatch({ type: 'GET_SUBSCRIPTIONS' })
  }, [dispatch])

  useEffect(()=>{
    if(!document.getElementById("userProofScript")) {
      const userProofScript=document.createElement("script");
      userProofScript.setAttribute("id","userProofScript");
      userProofScript.setAttribute("src","https://cdn.useproof.com/proof.js?acc=UROIN82qDxZJuJ70hu5MX9wztPk1");
      document.body.appendChild(userProofScript);
    }

    return ()=>{
      document.getElementById("userProofScript").remove();
    }
  })

  const hasSubscription = currentSubscription && currentSubscription._id;

  return (
    <Elements stripe={stripePromise}>
      <Layout className="container-payment" fluid>
        <div className="payment-form">
          <div className="payment-info" style={{ backgroundImage: `url(${checkout})`}}>
            <div className="label">
              <h2>14-DAY <br/> FREE TRIAL</h2>
              <p>No contract. <br /> Cancel any time</p>
            </div>

          {/*  {hasSubscription*/}
          {/*    ? <h2>UPGRADE TO {neededSubscription && neededSubscription.name.toUpperCase()} PLAN</h2>*/}
          {/*    : <h2>ACTIVATE YOUR FREE 14-DAY TRIAL</h2>*/}
          {/*  }*/}
          {/*  <p className="subtitle lead">(No contract, Cancel anytime.)</p>*/}
          {/*  <img className="planer-screenshot" src={planerScreenshot} alt="Planer"/>*/}
          {/*  {!hasSubscription && <p className="subscription-details">You won't be charged during your free trial. If you fall in love with Rawk the Year, you'll automatically be upgraded to $25 monthly membership on day 15.</p>}*/}
          </div>
          <div className="checkout-form">
            <CheckoutForm history={props.history} planId={props.match.params.planId}/>
          </div>
        </div>
      </Layout>
    </Elements>
  );
}
