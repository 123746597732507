import { all, call, put, takeLatest } from "redux-saga/effects";
import { actions as EnumActions } from '../../store/enum/reducer';
import {
  callGetAllergy,
  callGetUnits,
  callGetTypes,
  callGetSeasons,
  callGetIngredients,
  callGetUnitSystems,
  callGetDiets
} from "../../api/enums";

const map = {
  allergy: callGetAllergy,
  units: callGetUnits,
  types: callGetTypes,
  seasons: callGetSeasons,
  ingredients: callGetIngredients,
  unitSystems: callGetUnitSystems,
  diets: callGetDiets,
};

export function* getEnums(action) {
  try {
    yield put({ type: "GET_ENUMS_REQUEST" });
    const response = yield all(action.enums.map(e => call(map[e])));

    const enums = {};
    action.enums.forEach((e, index) => enums[e] = response[index].data);

    yield put(EnumActions.setEnums(enums));
    yield put({ type: "GET_ENUMS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_ENUMS_FAILURE",
      error: error.message
    });
  }
}


const authSaga = [
  takeLatest("GET_ENUMS", getEnums),
];

export default authSaga;
