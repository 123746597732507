import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { callGetRecipe } from "../../../../api/recipes";
import { paramCase } from "change-case";

import Rating from "../../../Recipe/components/Rating";
import Counter from "../../../../components/Counter";
import time from "../../../../assets/images/common/time-black.svg";
import close from "../../../../assets/images/common/close.svg";
import { getIngredientsListAmount } from "../../../../utils/shoppingListFunctions";
import { Link } from "react-router-dom";
import { actions as AppActions } from "../../../../store/app/reducer";

const RecipeViewModal = (props) => {
  const [state, setCurrentState] = useState({
    servingsCounter: props.recipe.portions || 1,
    servings: 1,
    ingredients: [],
    carbs: {},
    protein: {},
    fat: {},
    avgRating: 0,
    calories: 0,
    time: 0,
    instructions: [],
  });

  const setState = (newState) => setCurrentState((prevState) => ({ ...prevState, ...newState }));

  useEffect(() => {
    const fetchData = async () => {
      props.toggleLinks(true);
      try {
        const response = await callGetRecipe(props.recipe.recipe._id);
        setState({
          ...response.data,
          ingredients: response.data.ingredients.map((ingredient) => {
            ingredient.quantity =
              ingredient.displayAmount || ingredient.quantity;
            ingredient.measure = ingredient.displayUnit || ingredient.measure;
            return ingredient;
          }),
          servingsCounter: props.recipe.portions || response.data.servings,
        });
      } catch (error) {
        alert(
          "There has been an error loading the ingredients list. Please try again."
        );
        props.onClose();
      }
    };
    fetchData();

    return props.toggleLinks(false);
  }, [props]);

  const updateServings = (servings) => {
    setState({
      servingsCounter: servings,
    });
  };

  const handleClose = () => {
    props.onClose(props.recipe.portions, state.servingsCounter);
  };

  const coeficent = state.servingsCounter / state.servings;

  return (
    <div className="recipe-modal-container">
      <div className="recipe-modal-scroll">
        <div className="recipe-info">
          <header
            style={{
              background: `url('${props.recipe.recipe.image}')`,
              backgroundPosition: "top center",
              backgroundSize: "cover",
            }}
          >
            <div className="close-button-container">
              <Link to={`/recipes/${paramCase(props.recipe.recipe.title)}`}>
                FULL RECIPE
              </Link>
              <button onClick={handleClose} className="close-button">
                <img src={close} alt="Close" />
              </button>
            </div>
            <div className="mobile-statistics">
              <div className="element rating-element">
                <Rating black value={state.avgRating} />
              </div>
              <div className="element time-element">
                <img src={time} alt="Time" />
                <p className="text-muted">{state.time} min</p>
              </div>
            </div>
          </header>
          {state.ingredients.length === 0 && (
            <div className="loading-message">Loading ingredients list...</div>
          )}
          {state.ingredients.length > 0 && (
            <div className="recipe-page">
              <div className="recipe-grid">
                <div className="statistics-container">
                  <h1>{props.recipe.recipe.title}</h1>
                  <div className="statistics">
                    <div className="element rating-element">
                      <Rating black value={state.avgRating} />
                    </div>
                    <div className="element time-element">
                      <img src={time} alt="Time" />
                      <p className="text-muted">{state.time} min</p>
                    </div>
                    <div className="element servings-evement">
                      <p className="text-muted">Servings</p>
                      <Counter
                        value={state.servingsCounter}
                        onChange={updateServings}
                      />
                    </div>
                  </div>
                </div>
                <div className="content-container">
                  <div className="ingredients">
                    <p className="small-uppercase small-title">Ingredients</p>
                    <div className="list">
                      {state.ingredients.map((ingredient) => {
                        if (!ingredient.heading) {
                          return (
                            <p>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: getIngredientsListAmount(
                                    ingredient.quantity,
                                    coeficent,
                                    ingredient.measure
                                  ),
                                }}
                              />{" "}
                              {ingredient.name.name}
                              {ingredient.form && `, ${ingredient.form}`}
                            </p>
                          );
                        } else {
                          return (
                            <h4 className="ingredient-heading">
                              {ingredient.name.name}
                            </h4>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div className="instructions">
                    <p className="small-uppercase small-title">Instructions</p>
                    <div className="list">
                      {state.instructions.map((instruction, index) => (
                        <div className="list-row">
                          <div className="index">{index + 1}</div>
                          <p>{instruction}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleLinks: (state) => dispatch(AppActions.toggleHeaderLinks(state)),
});

export default connect(null, mapDispatchToProps)(RecipeViewModal);
