import React, { useState, useEffect, ReactNode } from "react";
import cx from "classnames";

import Button from "../Button";
import SuccessModal from "containers/MealPlan/components/SuccessModal";
import closeBtn from "../../assets/images/common/close_btn.svg";
import success from "../../assets/images/common/success.svg";
import edit from "../../assets/images/common/edit.svg";
import NewInput from "forms/Input/NewInput";

type Props = {
  onSave: (name: string) => void;
  close: () => void;
  currentName: string;
  visible: boolean;
  isCustomPlan?: boolean;
  confirmRequired?: boolean;
  isConfirmed?: boolean;
  isCreatePlan?: boolean;
  error?: string;
  classNames?: {
    title?: string;
  };
  setError?: React.Dispatch<React.SetStateAction<string>>;
  closeSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  visibleSuccess: boolean;
  title?: ReactNode;
  withHeight?: boolean;
  isAACP?: boolean;
  header?: string;
};

const SavePlanModal = (props: Props) => {
  const {
    close,
    currentName,
    isCreatePlan = false,
    isCustomPlan = false,
    onSave,
    visible,
    confirmRequired,
    error,
    setError,
    classNames,
    closeSuccess,
    visibleSuccess,
    isAACP,
    header
  } = props;

  const [name, setName] = useState("");
  const [onClickSave, setOnClickSave] = useState(false);

  const closeHandler = () => {
    close();
    setError("");
  };

  useEffect(() => {
    setName(currentName);
  }, [currentName]);
  useEffect(() => {
    if (visible) {
      setOnClickSave(false);
    }
  }, [visible]);
  const changeName = e => {
    setName(e.target.value);
  };
  const handleSave = () => {
    if (!confirmRequired) {
      closeHandler();
    } else {
      setOnClickSave(true);
    }
    onSave(name);
  };

  return (
    <div
      className={cx("modal save-plan-modal", {
        visible,
        withHeight: props.withHeight,
        visibleSuccess
      })}
    >
      <div className="scrollable-layer">
        <div className="body">
          {visibleSuccess ? (
            <div className="success-modal-mock">
              <img className="success-modal-mock_icon" src={success} />
              <p className="success-modal-mock_message">
                {isCustomPlan ? "Save " : "Update "} successful
              </p>
              <p className="success-modal-mock_text">
                You can now access this custom {isAACP ? "cleanse" : ""} plan in
                your Saved Plans.
              </p>
              <div className="success-modal-mock_divider" />
              <p className="success-modal-mock_close">
                ( window will close automatically )
              </p>
            </div>
          ) : (
            <>
              {isCreatePlan ? (
                <div className="aacp-save">
                  <p className="aacp-save_title">{header || "SAVE PLAN"}</p>
                  <p className={cx("message", classNames?.title ?? "")}>
                    {props.title ?? "What would you like to name this plan?"}
                  </p>
                </div>
              ) : (
                <></>
              )}
              <NewInput
                placeholder="Plan name"
                value={name}
                onChange={changeName}
                leftIcon={<img src={edit} alt="edit icon" />}
              />
              <Button
                disabled={!name || (confirmRequired && onClickSave)}
                small
                onClick={handleSave}
                className="modal-save-button"
              >
                {isCustomPlan ? "Update" : "SAVE PLAN"}
              </Button>
              <button className="close-icon-button" onClick={closeHandler}>
                <img src={closeBtn} />
              </button>
              {error && <span className="save-plan-modal_error">{error}</span>}
            </>
          )}
        </div>
      </div>
      {/* <SuccessModal close={closeSuccess} visible={visibleSuccess}>
        <p className="message create-plan-title">
          {isCustomPlan ? "Save " : "Update "} successful
        </p>
      </SuccessModal> */}
    </div>
  );
};

export default SavePlanModal;
