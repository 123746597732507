import { IImages } from "api/recipes/type";
import SavePlanModal from "components/SavePlanModal";
import Tooltip from "components/Tooltip";
import React from "react";
import { useOptimizerImage } from "utils/useOptimizerImage";
import pencil from "../../../../assets/images/common/pencil.svg";
import trash from "../../../../assets/images/common/trash.svg";
import Button from "../../../../components/Button";

type Props = {
  images: IImages;
  label: string;
  custom?: boolean;
  badge?: string;
  onClick: () => void;
  openRenameModal: () => void;
  openDeleteModal: () => void;
  className?: string;
  placeholder?: string;
  onTooltipToggle?: (isOpen: boolean) => void;
};

const PlanCard = (props: Props) => {
  const optimizedImageLink = useOptimizerImage({
    common: props?.images?.horizontal,
    retina: props?.images?.horizontalX2
  });

  return (
    <div className={`plan-card embla__slide_default ${props.className ?? ""}`}>
      <div
        className="plan-image"
        style={
          props?.images?.horizontal
            ? optimizedImageLink
            : { backgroundColor: "#eeeeee" }
        }
        onClick={props.onClick}
      >
        <div className="plan-name">
          <div className="plan-tooltip-button">
            <Button>{props.label ?? props.placeholder}</Button>
            {props.custom && (
              <Tooltip
                onToggle={props.onTooltipToggle}
                buttons={[
                  {
                    icon: pencil,
                    title: "Rename",
                    onClick: props.openRenameModal
                  },
                  {
                    icon: trash,
                    title: "Delete",
                    onClick: props.openDeleteModal
                  }
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
