// @ts-nocheck
import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";

import Button from "../../components/Button";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Rating from "./components/Rating";
import LeaveReview from "./components/LeaveReview";
import Review from "../../components/Review";

import { actions as PlansActions } from "../../store/plans/reducer";
import close from "../../assets/images/common/close_btn.svg";
import clock from "../../assets/images/common/clock.svg";
import heart from "../../assets/images/common/heart-b-filled.svg";
import heartEmpty from "../../assets/images/common/heart-empty.svg";
import time from "../../assets/images/common/time-black.svg";
import printer from "../../assets/images/common/printer.svg";
import Counter from "../../components/Counter";
import { callGetRecipe, callGetRecipeByTitle } from "../../api/recipes";
import { callCreateRecipe } from "../../api/reviews";
import {
  callUpdateMealInPlan,
  callUpdateCurrentWeekPlan
} from "../../api/plans";
import toggleFavorite from "../../utils/toggleFavorite";
import RecipeFunctions from "../../utils/recipeFunctions";
import { getIngredientsListAmount } from "../../utils/shoppingListFunctions";
import { IImages } from "api/recipes/type";
import { useOptimizerImage } from "utils/useOptimizerImage";
import { useScript } from "utils/useScript";
type Props = {
  match: {
    params: {
      titleSlug: string;
    };
  };
};

type State = {
  title: string;
  favorite: boolean;
  _id: string;
  servingsCounter: number;
  servings: number;
  ingredients: any[];
  instructions: string[];
  notes: string;
  carbs: {
    grams?: number;
    percent?: number;
  };
  protein: {
    grams?: number;
    percent?: number;
  };
  fat: {
    grams?: number;
    percent?: number;
  };
  reviews: any[];
  avgRating: number;
  images: IImages;
  calories: number;
  time: number;
  hideNavigation: boolean;
  scrollTop: Number;
  createdPlan: unknown;
};

const Recipe = (props: Props) => {
  const myRef = useRef();

  const [state, setCurrentState] = useState<State>({
    title: "",
    favorite: false,
    _id: "",
    servingsCounter: 1,
    servings: 1,
    ingredients: [],
    instructions: [],
    notes: "",
    carbs: {},
    protein: {},
    fat: {},
    reviews: [],
    avgRating: 0,
    calories: 0,
    time: 0,
    images: {},
    hideNavigation: true,
    scrollTop: 0,
    createdPlan: null
  });

  const setState = (newState: Partial<State>, cb?) => {
    setCurrentState(prevState => ({ ...prevState, ...newState }));
    if (cb) return cb();
  };

  useScript(
    `var _learnq = _learnq || [];var page = window.location.href;_learnq.push(['track', 'Viewed Recipes',{url: 'https://rawktheyear.com/recipes/${props.match.params.titleSlug}'}]);`
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await callGetRecipeByTitle(props.match.params.titleSlug);
      window.addEventListener("scroll", handlerScroll, true);
      setState(
        {
          ...response.data,
          ingredients: response.data.ingredients.map(ingredient => {
            ingredient.quantity =
              ingredient.displayAmount || ingredient.quantity;
            ingredient.measure = ingredient.displayUnit || ingredient.measure;
            return ingredient;
          }),
          servingsCounter:
            typeof props.mealPortions === "number"
              ? props.mealPortions
              : response.data.servings
        },
        () => window.scrollTo(0, 0)
      );
    };
    fetchData();
    return () => {
      props.selectMeal(null);
      props.selectPlan(null);
      props.setMealPortions(null);
      props.setPlanBody(null);
      window.removeEventListener("scroll", handlerScroll);
    };
  }, []);

  const toggleFavourite = async () => {
    const result = await toggleFavorite(state.favorite, state._id);

    setState({ favorite: result });
  };

  const updateServings = async (servings: number) => {
    setState({
      servingsCounter: servings
    });

    if (props.selectedPlan && props.selectedMeal) {
      let selectedPlanId = state.createdPlan?._id || props.selectedPlan;
      let selectedMealId = props.selectedMeal?._id;
      if (
        ["this-week", "next-week"].includes(selectedPlanId) &&
        !state.createdPlan
      ) {
        const { plan, start, end } = props.planBody;
        const { data } = await callUpdateCurrentWeekPlan(plan, start, end);

        setState({ createdPlan: data });
        selectedPlanId = data?._id;
        selectedMealId = data.mealPlans.find(
          mealPlan => mealPlan.recipe._id === props.selectedMeal.recipe._id
        )?._id;
      }

      if (state.createdPlan) {
        selectedMealId = state.createdPlan.mealPlans.find(
          mealPlan => mealPlan.recipe._id === props.selectedMeal.recipe._id
        )?._id;
      }
      await callUpdateMealInPlan(selectedPlanId, selectedMealId, {
        portions: servings
      });
      if (props.createdPlanId) {
        const indexOfUpdatedItem = props.currentMealPlan.findIndex(
          mealPlan => mealPlan._id === props.selectedMeal._id
        );

        const newPlanList = [...props.currentMealPlan];
        newPlanList[indexOfUpdatedItem] = {
          ...newPlanList[indexOfUpdatedItem],
          portions: servings
        };

        props.setPlan(newPlanList);
      }
    }
  };

  const handlerScroll = event => {
    const scrollTop = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    setState({
      scrollTop: scrollTop
    });
    if (scrollTop > 300) {
      setState({ hideNavigation: false });
    } else {
      setState({ hideNavigation: true });
    }
  };

  const createReview = async (rating: number, review: string) => {
    const response = await callCreateRecipe(rating, review, state._id);

    setState({
      reviews: [response.data, ...state.reviews]
    });
  };

  const print = () => {
    window.print();
  };

  const coeficent = state.servingsCounter / state.servings;

  const nutritionCeficient = 1 / state.servings;

  const transformedPercents = useMemo(() => {
    if (
      !state?.carbs?.percent ||
      !state?.protein?.percent ||
      !state?.fat?.percent
    )
      return [0, 0, 0];
    return RecipeFunctions.roundPercent([
      state.carbs.percent,
      state.protein.percent,
      state.fat.percent
    ]);
  }, [state.carbs, state.protein, state.fat]);

  const nutrition = (
    <div className="nutrition">
      <p className="small-uppercase">
        <span>RAWKIN’ NUTRITION</span>
        <br />
        <span className="little-notice">(per serving)</span>
      </p>
      <div className="elements">
        <div className="element">
          <p className="name">Calories</p>
          <p className="amount">
            {RecipeFunctions.getPrettyNumber(
              state.calories,
              nutritionCeficient
            )}
          </p>
        </div>
        <div className="element">
          <p className="name">Carbs</p>
          <p className="amount">
            {RecipeFunctions.getPrettyNumber(
              state.carbs.grams,
              nutritionCeficient
            )}{" "}
            <span className="measure">g</span>
          </p>
          {/* <p className="percents">{transformedPercents[0]}%</p> */}
        </div>
        <div className="element">
          <p className="name">Protein</p>
          <p className="amount">
            {RecipeFunctions.getPrettyNumber(
              state.protein.grams,
              nutritionCeficient
            )}{" "}
            <span className="measure">g</span>
          </p>
          {/* <p className="percents">{transformedPercents[1]}%</p> */}
        </div>
        <div className="element">
          <p className="name">Fat</p>
          <p className="amount">
            {RecipeFunctions.getPrettyNumber(
              state.fat.grams,
              nutritionCeficient
            )}{" "}
            <span className="measure">g</span>
          </p>
          {/* <p className="percents">{transformedPercents[2]}%</p> */}
        </div>
      </div>
    </div>
  );

  const onCloseButton = () => {
    if (props.history.length <= 2) {
      return props.history.push("/home");
    }
    props.history.goBack();
  };

  const optimizedImageLink = useOptimizerImage({
    common: state?.images?.big,
    retina: state?.images?.bigX2
  });

  const isNotesExist = useMemo(() => {
    const div = document.createElement("div");
    div.innerHTML = state?.notes;
    return div.innerText.trim().length > 0;
  }, [state]);

  return (
    <Layout
      hideNavigation={state.hideNavigation}
      // lessPadding
      className="recipeContainer"
      isHeaderAnimated
      headerAbsolute
    >
      <div className="recipe-wrapper" ref={myRef}>
        <div className="main-image" style={optimizedImageLink}>
          <button className="close-button big" onClick={onCloseButton}>
            <img src={close} alt="Close" />
          </button>
          {/* <div className="header-statistics">
            <div className="element">
              <Rating black value={state.avgRating} />
            </div>
            <div className="element">
              <img src={time} alt="Time" />
              <p className="text-muted">{state.time} min</p>
            </div>
          </div> */}
          <div className="header-statistics">
            <div className="recipe-info">
              <span className="recipe-time">
                <img src={clock} alt={"time"} />
                {state.time} MIN
              </span>{" "}
              <span className="divider"/> <span>{(state.calories / state.servings).toFixed()} CAL</span>
              <span className="rating">{state.avgRating}</span>
            </div>
          </div>
        </div>
        <div className="recipe-page">
          <div className="title-row">
            <PageHeader title={state.title} className="recipe-header-new" spaceless />
            <div className="title-buttons">
              <div className="servings">
                <p>Servings</p>
                <Counter
                  value={state.servingsCounter}
                  onChange={updateServings}
                />
              </div>
              <Button transparent onClick={print}>
                <img src={printer} alt="Print" />
              </Button>
              <Button transparent className="heart" onClick={toggleFavourite}>
                <img
                  src={state.favorite ? heart : heartEmpty}
                  alt="Favourite"
                />
              </Button>
            </div>
          </div>
          <p className="print-info">
            Servings: <span>{state.servingsCounter}</span>
            Rating: <span>{state.avgRating}</span>
            Time: <span>{state.time} min</span>
          </p>
          <div className="content-container">
            <div className="ingredients">
              <div className="ingredients-header">
                <p className="recipe-title recipe-page-title">Ingredients</p>
              </div>
              <div className="list">
                {state.ingredients.map(ingredient => {
                  if (!ingredient.heading) {
                    return (
                      <p key={ingredient.name.name}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getIngredientsListAmount(
                              ingredient.quantity,
                              coeficent,
                              ingredient.displayUnit
                            )
                          }}
                        />{" "}
                        {ingredient.name.name}
                        {ingredient.form && `, ${ingredient.form}`}
                      </p>
                    );
                  } else {
                    return (
                      <h4
                        key={ingredient.name.name}
                        className="ingredient-heading"
                      >
                        {ingredient.name.name}
                      </h4>
                    );
                  }
                })}
              </div>
            </div>
            <div className="instructions">
              {state.instructions.length > 0 && (
                <p className="recipe-title recipe-page-title">Instructions</p>
              )}
              <div className="list">
                {state.instructions.map((instruction, index) => (
                  <div key={index} className="list-row">
                    <div className="index">{index + 1}.</div>
                    <p>{instruction}</p>
                  </div>
                ))}
              </div>

              {state?.notes && isNotesExist && (
                <div className="notes">
                  <p className="recipe-title recipe-page-title">Notes</p>
                  <div
                    className="recipe-description"
                    dangerouslySetInnerHTML={{ __html: state.notes }}
                  />
                </div>
              )}

              {nutrition}
            </div>
          </div>
          <PageHeader className="reviews-header" title="I Made It!" spaceless />
          <div className="review-container">
            <LeaveReview createReview={createReview} />
            {nutrition}
          </div>
          <div className="reviews">
            {state.reviews.map((r, i) => (
              <Review key={i} {...r} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    selectedPlan: state.plans.selectedPlanId,
    selectedMeal: state.plans.selectedMeal,
    mealPortions: state.plans.mealPortions,
    currentMealPlan: state.plans.current,
    createdPlanId: state.plans.createdPlanId,
    planBody: state.plans.planBody
  };
};

const mapDispatchToProps = dispatch => ({
  selectMeal: meal => dispatch(PlansActions.setSelectedMeal({ meal })),
  selectPlan: id => dispatch(PlansActions.setSelectedPlan({ id })),
  setMealPortions: count => dispatch(PlansActions.setMealPortions({ count })),
  setPlan: data => dispatch(PlansActions.setPlan({ data })),
  setPlanBody: body => dispatch(PlansActions.setPlanBody({ body }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Recipe);
