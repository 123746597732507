// @flow
import React, { useEffect, useState } from "react";
import { Sticky } from "react-sticky";
import cx from "classnames";

import printPlan from "../../../../assets/images/common/print_new_black.svg";
import printPlanWhite from "../../../../assets/images/common/print_new.svg";
import deletePLan from "../../../../assets/images/common/trash_new_black.svg";
import deletePLanWhite from "../../../../assets/images/common/trash_new.svg";
import savePlan from "../../../../assets/images/common/action-save-plan.svg";
import renamePlan from "../../../../assets/images/common/action-rename-plan.svg";
import editPlan from "../../../../assets/images/common/edit.svg";
import editPlanWhite from "../../../../assets/images/common/edit_white.svg";
import cart from "../../../../assets/images/common/cart.svg";
import cartWhite from "../../../../assets/images/common/cart_white.svg";
import Day from "../Day";
import Pagination from "../Pagination";

type Props = {
  date: string;
  enableNavigation: boolean;
  navigatePastWeek: React.MouseEventHandler<HTMLButtonElement>;
  navigateNextWeek: React.MouseEventHandler<HTMLButtonElement>;
  hasNext: unknown;
  isCustomPlan: boolean;
  handleKeyPress: unknown;
  saveNewName: unknown;
  gettingGrocery: any;
  savingPlan: any;
  openSavePlanModal: any;
  deletingPlan: any;
  openDeleteModal: any;
  openEditModal: any;
  disableGetPlan: boolean;
  openShoppingCart: () => void;
  openDeliveryPage: () => void;
  savedPlans: any;
  weeklyPlans: any;
  goToPlan: () => void;
  setPlansMenuOpen: () => void;
  week: string[];
  onPageChange?: (page: number) => void;
};

const Header = (props: Props) => {
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    setMobileView(window.innerWidth <= 475);
  });

  const getButtonTitle = () => {
    if (mobileView) {
      return (
        <img
          src={props.isCustomPlan ? renamePlan : savePlan}
          alt="Grocery list"
        />
      );
    }
    let title = "SAVE PLAN";
    if (props.isCustomPlan) {
      title = "RENAME PLAN";
    }
    return <span>{title}</span>;
  };

  return (
    <div className="meal-header_wrapper">
      <div className={cx("meal-header")}>
        <div className={`header-container`} onClick={props.openEditModal}>
          {/* <PlansDropDown
              savedPlans={props.savedPlans}
              weeklyPlans={props.weeklyPlans}
              title={props.date}
              goToPlan={props.goToPlan}
              setPlansMenuOpen={props.setPlansMenuOpen}
            /> */}
          <p className="plan-detail-title">{props.date}</p>
          <img
            src={editPlan}
            alt="edit plan icon"
            className="plan-detail_icon"
          />
        </div>
        <div className="buttons-container">
          <div className="buttons">
            <div className="action-button-container">
              <button
                className={cx("action-button")}
                onClick={props.openShoppingCart}
              >
                <img src={mobileView ? cartWhite : cart} alt="Grocery list" />
              </button>
              <button
                className="action-button"
                onClick={props.openDeliveryPage}
              >
                <img
                  src={mobileView ? printPlanWhite : printPlan}
                  alt="Grocery list"
                />
              </button>
              {mobileView && (
                <button className="action-button" onClick={props.openEditModal}>
                  <img src={editPlanWhite} alt="Edit plan" />
                </button>
              )}
              <button
                className={cx("action-button")}
                onClick={
                  !props.deletingPlan &&
                  props.isCustomPlan &&
                  props.openDeleteModal
                }
              >
                <img
                  src={mobileView ? deletePLanWhite : deletePLan}
                  alt="Grocery list"
                />
              </button>
            </div>
          </div>
        </div>
        <Pagination
          plansMenuOpen={false}
          customRender={true}
          onPageChange={props.onPageChange}
        />
        <div className={`plan-grid g-gap10 meal-header_days`}>
          {props.week.map((date, index) => (
            <Day date={date} first={index === 0} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
