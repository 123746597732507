import React, { useEffect, useRef } from "react";

import Button from "components/Button";
import {
  SubscriptionCrossSell,
  SubscriptionDetails
} from "store/subscriptions/types";
import { useMediaQuery } from "utils/customHooks/use-media-query";
import CartSummaryLine from "./CartSummaryLine";
import { useAppSelector } from "store/hooks";

import cross from "../../assets/images/cross.svg";
import tag from "../../assets/images/tag.svg";

interface ICartSummaryProps {
  className?: string;
  promocode?: string;
  isMobileFullVersion?: boolean;
  isPromocodeError: boolean;
  isAddPromocodeMode?: boolean;
  setIsAddPromocodeMode?: (isAddMode: boolean) => void;
  setIsPromocodeError: (hasError: boolean) => void;
  onAddPromocode?: (promocode: string) => void;
  onRemovePromocode?: () => void;
  onRemoveProduct?: (productId: string) => void;
}

const getDiscount = (subscriptionDetails: SubscriptionDetails) => {
  const { membership, crossSells } = subscriptionDetails ?? {};
  const subtotalAmount = (crossSells || []).reduce((acc, item) => {
    acc = acc + item.subtotal;
    return acc;
  }, membership.subtotal);

  const totalAmount = (crossSells || []).reduce((acc, item) => {
    acc = acc + item.total;
    return acc;
  }, membership.totalAfterTrial);

  const discountPercentage = +(
    100 -
    (totalAmount * 100) / subtotalAmount
  ).toFixed(0);

  return {
    discountAmount: subtotalAmount - totalAmount,
    discountPercentage,
    subtotalAmount
  };
};

const getAmount = (amount: number, crossSells: SubscriptionCrossSell[]) =>
  +(crossSells || [])
    .reduce((acc, item) => (acc = acc + +item.total), amount)
    .toFixed(2);

const CartSummary = (props: ICartSummaryProps) => {
  const {
    isMobileFullVersion = true,
    className = "",
    promocode,
    isPromocodeError,
    isAddPromocodeMode,
    setIsAddPromocodeMode,
    setIsPromocodeError,
    onRemovePromocode,
    onAddPromocode,
    onRemoveProduct
  } = props;
  const inputRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 993px)");

  const subscriptionDetails: SubscriptionDetails = useAppSelector(
    state => state.subscriptions.selectedSubscription
  );

  const { membership, crossSells, hasPromocodeError } =
    subscriptionDetails ?? {};
  const { totalAfterTrial, totalDueToday, subtotal, name } = membership ?? {};

  const { discountAmount = 0, subtotalAmount = 0 } = subscriptionDetails
    ? getDiscount(subscriptionDetails)
    : {};

  const isMobilereducedVersion = isMobile && !isMobileFullVersion;
  const addPromocodeHandler = () => {
    const value = inputRef.current.value;
    if (!value.trim()) return;

    onAddPromocode?.(value);
  };

  useEffect(() => {
    if (!isAddPromocodeMode) {
      setIsPromocodeError(false);
    }
  }, [isAddPromocodeMode, setIsPromocodeError]);

  return (
    <div className={`cart-summary ${className}`}>
      <div className="cart-summary__products">
        {!!crossSells?.length &&
          crossSells.map(product => (
            <CartSummaryLine
              key={product.id}
              name={product.name}
              total={product.total}
              subtotal={product.subtotal}
              onRemoveProduct={() => onRemoveProduct?.(product.id)}
            />
          ))}
        {subtotal !== undefined && (
          <CartSummaryLine
            name={`${name} Plan`}
            total={totalAfterTrial}
            subtotal={subtotal}
          />
        )}
        <div className="divider" />
        {subtotalAmount !== undefined && (
          <CartSummaryLine name="Subtotal" total={subtotalAmount} />
        )}
      </div>
      {!isAddPromocodeMode && !promocode && (
        <Button
          className={`cart-summary__button ${
            isMobilereducedVersion
              ? "button-md cart-summary__button-mobile"
              : ""
          }`}
          onClick={() => setIsAddPromocodeMode(true)}
        >
          ADD PROMO CODE
        </Button>
      )}
      {isAddPromocodeMode && (!promocode || !discountAmount) && (
        <div className="input-container cart-summary__promocode">
          <input
            placeholder="SPRING2024"
            ref={inputRef}
            className="input cart-summary__input"
            onChange={() => setIsPromocodeError(false)}
          />
          <Button
            className="button button-md cart-summary__promocode__button"
            onClick={addPromocodeHandler}
            type="button"
          >
            APPLY
          </Button>
          {isPromocodeError && (
            <div className="cart-summary__input-error">Code is not applied</div>
          )}
        </div>
      )}
      {promocode && !hasPromocodeError && !!discountAmount && (
        <div className="cart-summary__applied-promocode">
          <div className="cart-summary__applied-promocode-top">
            {promocode && (
              <div className="cart-summary__applied-promocode-tag">
                <img src={tag} alt="Tag" />
                <span>{promocode}</span>
                <button
                  onClick={onRemovePromocode}
                  className="cart-summary__applied-promocode-close"
                >
                  <img src={cross} alt="Close" />
                </button>
              </div>
            )}
            <span className="cart-summary__applied-promocode-amount">{`-$${discountAmount.toFixed(
              2
            )}`}</span>
          </div>
        </div>
      )}
      <div className="divider" />
      <div className="cart-summary__totals">
        {totalAfterTrial !== undefined && (
          <CartSummaryLine
            name="Total After Trial"
            total={getAmount(totalAfterTrial, crossSells)}
          />
        )}
        {totalDueToday !== undefined && (
          <CartSummaryLine
            name="Total Due Today"
            total={
              totalDueToday
                ? getAmount(totalDueToday, crossSells)
                : totalDueToday
            }
          />
        )}
      </div>
    </div>
  );
};

export default CartSummary;
