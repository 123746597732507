import React, { useState } from "react";
import Collapse from "react-collapse";
import cx from "classnames";

import arrow from "../../../../assets/images/common/arrow-down.svg";

type Props = {
  title: string;
  children: React.ReactNode;
  collapseDisabled?: boolean;
  wrapperClassName?: string;
};

const CollapseComponent = (props: Props) => {
  const [collapsed, setCollapsed] = useState(!props.collapseDisabled);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={cx("filter-element", props.wrapperClassName)}>
      <div
        className="filter-header"
        onClick={!props.collapseDisabled ? toggleCollapse : undefined}
      >
        <p className="small-uppercase">{props.title}</p>
        {!props.collapseDisabled && (
          <img className={cx({ rotate: !collapsed })} src={arrow} alt="Arrow" />
        )}
      </div>
      {props.collapseDisabled ? (
        props.children
      ) : (
        <Collapse isOpened={!collapsed}>{props.children}</Collapse>
      )}
    </div>
  );
};

export default CollapseComponent;
