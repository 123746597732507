const initialState = {};

export default function loading(state = initialState, action) {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|CLEAR)/.exec(type);
  if (!matches) {
    return state;
  }
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === "REQUEST"
  };
}
