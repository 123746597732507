import { all } from "redux-saga/effects";

import authenticationSaga from "./authentication";
import userSaga from "./user";
import enumsSaga from "./enums";
import subscriptions from "./subscriptions";
import miscSaga from "./misc";
import recipesSaga from "./recipes";
import eventsSaga from "./events";
import articlesSaga from "./articles";
import plansSaga from "./plans";
import rawkstarsSaga from "./rawkstars";
import crossSellsSaga from "./crossSells";

export default function* root() {
  yield all([
    ...authenticationSaga,
    ...userSaga,
    ...enumsSaga,
    ...subscriptions,
    ...miscSaga,
    ...recipesSaga,
    ...eventsSaga,
    ...articlesSaga,
    ...plansSaga,
    ...rawkstarsSaga,
    ...crossSellsSaga,
  ]);
}
