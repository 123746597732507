import { createSlice } from "@reduxjs/toolkit";
import { ISetCrossSells } from "./types";

const initialState = {
  all: [],
  isLoading: false,
  isBuyLoading: false,
};

const crossSellsSlice = createSlice({
  name: "crossSells",
  initialState,
  reducers: {
    setCrossSells: (state, action: ISetCrossSells) => {
      state.all = action.payload;
    },
    setIsLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setIsBuyLoading: (state, action: { payload: boolean }) => {
      state.isBuyLoading = action.payload;
    },
  },
});

export const { actions } = crossSellsSlice;

export default crossSellsSlice.reducer;
