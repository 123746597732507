import * as React from "react";

interface ISignUpLayout {
  children: React.ReactNode;
}

export const SignUpLayout = (props: ISignUpLayout) => {
  return (
    <div className="page sign-up">
      {/* <div className="signup-header">
        <div className="header-content">
          <img className="logo" src={logo} alt="logo" />
          <Link to={"/login"}>
            <img className="account" src={emptyAccount} alt="logo" />
          </Link>
        </div>
      </div> */}
      <div className="signup-content">{props.children}</div>
      {/* <div className="signup-footer">
        <a href="https://stripe.com" type="_blank" className="footer-left">
          <span>Powered by</span>
          <img className="stripe" src={stripe} alt="logo" />
        </a>
        <div className="footer-right">
          <a href="https://stripe.com/legal/end-users" type="_blank">
            Terms
          </a>
          <a href="https://stripe.com/privacy" type="_blank">
            Privacy
          </a>
        </div>
      </div> */}
    </div>
  );
};
