import * as React from "react";
import { useHistory } from "react-router-dom";

import logo from "../../assets/images/logo-new-black.png";

type Props = {
  children: React.ReactNode,
};

const LoginLayout = (props: Props) => {
  const history = useHistory();
  const handleLogoOnClick = () => {
    history.push('/');
  }
  return (
  <div className="page auth">
    <div className="login-header" >
      <img className='logo' src={logo} alt="logo" onClick={handleLogoOnClick}/>
    </div>
    <div className="login-content" >{props.children}</div>
  </div>
  )
}

export default LoginLayout;
