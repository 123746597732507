import React, { useState, useEffect } from "react";

import star from "../../../../assets/images/common/star-b.svg";
import starEmpty from "../../../../assets/images/common/star-empty.svg";
import Button from "../../../../components/Button";

type Props = {
  createReview: (arg0: number, arg1: string) => void;
};

const LeaveReview = (props: Props) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [error, setError] = useState("");
  const [stars, setStars] = useState(new Array(5).fill(false)) 

  useEffect(() => {
    setStars([
      ...new Array(rating).fill(true), 
      ...new Array(5 - rating).fill(false)
    ])
  }, [rating])

  const handleReviewChange = (e) => {
    e.preventDefault();
    setReview(e.target.value);
  };

  const validate = () => {
    const ratingExists = !!rating;
    const reviewMessageNotEmpty = !!review;

    if (ratingExists && reviewMessageNotEmpty) return true;

    const ratingError = !ratingExists ? "Rate the recipe (top right)" : "";
    const reviewMessageError = !reviewMessageNotEmpty ? "Leave a review" : "";

    setError(reviewMessageError || ratingError);
  };

  const submit = () => {
    if (validate()) {
      props.createReview(rating, review);

      setRating(0);
      setReview("");
      setError("");
    }
  };

  return (
    <div className="review-area">
      <div className="title">
        <p className="small-uppercase">Leave a Review</p>
      </div>
      <div className="input-container">
        <div className="wrapper">
          <textarea
            onChange={handleReviewChange}
            value={review}
            className="input"
            rows={3}
            placeholder="Share tips, tweaks, and feedback to help you and your fellow rawkstars."
          />
        </div>
      </div>
      <div className="button-container">
        <Button limited onClick={submit}>
          SUBMIT
        </Button>
        <div className="review-rating">
          {stars.map((active, index) => (
            <Button transparent onClick={() => setRating(index + 1)}>
              <img src={active ? star : starEmpty} alt="Star" />
            </Button>
          ))}
        </div>
      </div>
      <p className="error">{error}</p>
    </div>
  );
};

export default LeaveReview;
