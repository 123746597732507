import { IBaseResponse } from "api/types";
import { call } from "../core";
import { IBackgrounds } from "./types";

export const callContactUs = (name, email, message) => {
  return call('/contact', "POST", { body: { name, email, message } });
};

export const callGetQuote = () => {
  return call('/quote', "GET", {});
};

export const callGetQuoteBackgrounds = (): Promise<IBaseResponse<IBackgrounds>> => {
  return call('/backgrounds/current', "GET", {});
};
