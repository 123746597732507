import { createSlice } from "@reduxjs/toolkit";
import { ISetEnums } from "./types";

const initialState = {
    allergy: [],
    units: [],
    seasons: [],
    types: [],
    ingredients: [],
    unitSystems: [],
    diets: [],
};

type setEnumsAction = {
    payload: ISetEnums;
    type: string;
}

const enumSlice = createSlice({
    name: "enum",
    initialState,
    reducers: {
        setEnums: (state, action: setEnumsAction) => {
            state = Object.assign(state, action.payload);
        }
    }
})

export const { actions } = enumSlice;

export default enumSlice.reducer;
