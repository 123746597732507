import { call, put, takeLatest } from "redux-saga/effects";
import { actions as CrossSellsActions } from "../../store/crossSells/reducer";
import { callBuyCrossSell, callGetCrossSells } from "api/crossSells";

export function* getCrossSells(action) {
  try {
    yield put({ type: "GET_CROSS_SELLS_REQUEST" });
    yield put(CrossSellsActions.setIsLoading(true));
    const response = yield call(callGetCrossSells);

    yield put(CrossSellsActions.setCrossSells(response.data));
    yield put(CrossSellsActions.setIsLoading(false));
    yield put({ type: "GET_CROSS_SELLS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_CROSS_SELLS_FAILURE",
      error: error.message
    });
  }
}

export function* buyCrossSell(action) {
  try {
    yield put({ type: "BUY_CROSS_SELL_REQUEST" });
    yield put(CrossSellsActions.setIsBuyLoading(true));
    const response = yield call(callBuyCrossSell, action.id);
    if (response.data.url) {
      action.onRedirect(response.data.url);
    }
    yield put(CrossSellsActions.setIsBuyLoading(false));
    yield put({ type: "BUY_CROSS_SELL_SUCCESS" });
    action.onSuccess();
  } catch (error) {
    action.onError && action.onError();
    yield put({
      type: "BUY_CROSS_SELL_FAILURE",
      error: error.message
    });
    yield put(CrossSellsActions.setIsBuyLoading(false));
  }
}

const authSaga = [
  takeLatest("GET_CROSS_SELLS", getCrossSells),
  takeLatest("BUY_CROSS_SELL", buyCrossSell)
];

export default authSaga;
