import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import loader from '../../assets/images/common/loader-new.svg';
import Layout from '../Layout';

type Props = {
  trialEnded: boolean,
  checkSubscriptions: (arg0: any) => void,
};

const CheckSubscription = (props: Props) => {
  const [count, setCount] = useState(0);
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      setCount((count) => count + 1);
      props.checkSubscriptions(() => {
        !props.trialEnded && history.push('/home')
      })
    }, 5000);
    (count === 5) && history.push('/home')
  });
  return (
    <Layout>
      <div className='sub-loader'>
        <p>Subscription loading... Please wait</p>
        <img src={loader} alt="loader" />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    trialEnded:
      !state.loading['GET_USER_SUBSCRIPTIONS'] &&
      (!state.subscriptions.user ||
        (!state.subscriptions.user._id && state.subscriptions.user.name !== 'Legacy Membership') ||
        state.subscriptions.user.status === 'past-due')
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkSubscriptions: (onSuccess) => dispatch({ type: 'GET_USER_SUBSCRIPTIONS', onSuccess })
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckSubscription);
