import { callGetRecipesFilter, callGetTopRecipesFilter } from "api/recipes";
import { IRecipeFilterRequest } from "api/recipes/type";
import cx from "classnames";
import React, { useEffect } from "react";
import { IRecipe, RecipeCard } from "../components/RecipeCard";
import useEmblaCarousel from "embla-carousel-react";
import { SliderNavigation } from "components/SliderNavigation";
export interface IRecipeListProps extends Omit<IRecipeFilterRequest, "page"> {
  title: string;
  page: number;
  bgGray?: boolean;
  disableSlider?: boolean;
  fullRecipes?: boolean;
  classNames?: {
    root?: string;
    card?: {
      wrapper?: string;
      content?: string;
      image?: string;
      rating?: string;
      title?: string;
    };
    grid?: string;
  };
  recipes?: IRecipe[];
  slidesToScroll: number;
  gridSlider?: boolean;
  imageAsBg?: boolean;
}

export function RecipeList(props: IRecipeListProps) {
  const {
    title,
    bgGray = false,
    page,
    classNames,
    fullRecipes,
    recipes: defaultRecipes,
    slidesToScroll,
    gridSlider,
    imageAsBg,
    ...filterParams
  } = props;

  const [recipes, setRecipes] = React.useState<IRecipe[]>(defaultRecipes?.length ? defaultRecipes : []);

  useEffect(() => {
    if (defaultRecipes && defaultRecipes.length) {
      setRecipes(defaultRecipes);
    }
  }, [defaultRecipes]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: true,
    dragFree: true,
    align: "start",
    slidesToScroll: slidesToScroll,
    loop: true,
    breakpoints: {
      "(min-width: 768px)": { active: true },
      "(max-width: 768px)": { active: true }
    }
  });

  useEffect(() => {
    emblaApi?.reInit();
  }, [emblaApi, recipes]);

  React.useEffect(() => {
    if (!defaultRecipes) {
      (async () => {
        const response = await callGetRecipesFilter({ page, ...filterParams });
        setRecipes(response.data.recipes);
      })();
    }
  }, []);

  return (
    <div className={cx("top-recipe", { gray: bgGray }, classNames?.root ?? "")}>
      <div className="container wide lessPadding top-recipe--container embla">
        {recipes && <h2 className="recipe-h2">{title}</h2>}
        <div className="embla__viewport" ref={emblaRef}>
          <div
            className={`recipes-grid plans-week embla__container ${classNames?.grid}`}
          >
            {gridSlider
              ? [
                  [0, 4],
                  [4, 8]
                ].map((point, i) => (
                  <div key={i} className="recipes-grid grid-2-2 embla__slide">
                    {recipes?.slice(point[0], point[1]).map(recipe => (
                      <RecipeCard
                        key={recipe._id}
                        {...recipe}
                        className={classNames?.card}
                        imageAsBg={imageAsBg}
                      />
                    ))}
                  </div>
                ))
              : recipes
                  ?.slice(0, 8)
                  .map(recipe => (
                    <RecipeCard
                      key={recipe._id}
                      {...recipe}
                      className={classNames?.card}
                      imageAsBg={imageAsBg}
                    />
                  ))}
          </div>
        </div>
        {!props.disableSlider && recipes.length > 4 && (
          <SliderNavigation
            classNames={{ root: "top-recipe-navigation" }}
            onLeftClick={() => emblaApi?.scrollPrev()}
            onRightClick={() => emblaApi?.scrollNext()}
          />
        )}
      </div>
    </div>
  );
}
