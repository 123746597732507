import { call } from "../core";

export const callGetAllergy = () => {
  return call('/enum/allergy', "GET", {});
};

export const callGetUnits = () => {
  return call('/enum/unit', "GET", {});
};

export const callGetTypes = () => {
  return call('/enum/recipeType', "GET", {});
};

export const callGetSeasons = () => {
  return call('/enum/season', "GET", {});
};

export const callGetIngredients = () => {
  return call('/enum/ingredient/15', "GET", {});
};

export const callGetUnitSystems = () => {
  return call('/enum/unitSystem', "GET", {});
};

export const callGetDiets = () => {
  return call('/enum/diet', "GET", {});
};
