import React from "react";

type Props = {
  value?: number;
  defaultValue?: number;
  onChange?: (number) => void;
  disabled?: boolean;
  className?: {
    button?: string;
    value?: string;
  };
};

const Counter = (props: Props) => {
  const increase = () => {
    const value =
      typeof props.value === "number" ? props.value : props.defaultValue;

    if (value === undefined) {
      return;
    }

    const nextValue = value + 1;

    if (props.onChange) {
      props.onChange(nextValue);
    }
  };

  const decrease = () => {
    const value =
      typeof props.value === "number" ? props.value : props.defaultValue;

    if (value === undefined) {
      return;
    }

    const nextValue = value - 1;

    if (nextValue >= 1 && props.onChange) {
      props.onChange(nextValue);
    }
  };

  const value =
    typeof props.value === "number" ? props.value : props.defaultValue;

  return (
    <div className={`counter ${props.disabled ? "disabled" : ""}`}>
      <button
        className={`counter-button ${props.className?.button}`}
        onClick={decrease}
      >
        <span>-</span>
      </button>
      <span className={`counter-value ${props.className?.value}`}>
        {value || 0}
      </span>
      <button
        className={`counter-button ${props.className?.button}`}
        onClick={increase}
      >
        <span>+</span>
      </button>
    </div>
  );
};

export default Counter;
