import React from 'react';

type Props = {
  title: string,
  photo: string,
  duration: string,
  onClick: () => void,
};

const Article = (props: Props) => (
  <div className="wellness-article" onClick={props.onClick}>
    <p className="title">{props.title}</p>
    <div className="photo" style={{ backgroundImage: `url(${props.photo})`}}>
      {props.duration && (<div className="badge">
        {props.duration}
      </div>)}
    </div>
  </div>
);

export default Article;
