import React, { useEffect, useState } from "react";
import cx from "classnames";

import Button from "../Button";

type Props = {
  onDelete: () => void,
  close: () => void,
  visible: boolean,
  confirmRequired?: boolean,
  isConfirmed?: boolean
};

const DeletePlanModal = (props: Props) => {
  const [onClickDelete, setOnClickDelete] = useState(false);
  useEffect(() => {
    if (props.visible) {
      setOnClickDelete(false)
    }
  },[props.visible]);
  useEffect(() => {
    if (props.isConfirmed) {
      props.close();
    }
  },[props.isConfirmed]);

  const handleSave = () => {
    if (!props.confirmRequired ) {
      props.close();
    } else {
      setOnClickDelete(true)
    }
    
    props.onDelete();
  };

  return (
    <div
      className={cx("modal save-plan-modal", { visible: props.visible })}
    >
      <div className="scrollable-layer">
        <div className="body">
          <p className="message">
            You are going to delete this Meal Plan permanently, do you want to
            proceed?
          </p>
          <div className="footer-buttons">
            <Button small onClick={props.close}>
              No
            </Button>
            <Button primary disabled={props.confirmRequired && onClickDelete} small onClick={handleSave}>
              Yes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePlanModal;
