import React, { useEffect, useState } from "react";
import cx from "classnames";

import arrow from "../../../../assets/images/common/arrow-right.svg";
import { Sticky } from "react-sticky";

type Props = {
  className?: string;
  plansMenuOpen: boolean;
  children?: any[];
  customRender?: boolean;
  onPageChange?: (page: number) => void;
  outerCurrentPage?: number;
};

type State = {
  deviceWidth: number;
  perPage: number;
  pages: number;
  currentPage: number;
};

const Pagination = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    deviceWidth: 0,
    perPage: 0,
    pages: 0,
    currentPage: 0
  });

  const setState = (newState: Partial<State>) =>
    setCurrentState(prevState => ({ ...prevState, ...newState }));

  const updateDeviceWidth = () => {
    //$$FlowFixMe
    const width = document.body.clientWidth;

    setState({
      deviceWidth: width,
      perPage: getDisplayCount(width)
    });

    const newPages = Math.ceil(7 / getDisplayCount(width));

    if (state.pages !== newPages) {
      setState({ pages: newPages, currentPage: 0 });
    }
  };

  useEffect(() => {
    setState({ currentPage: props.outerCurrentPage });
  }, [props.outerCurrentPage]);

  useEffect(() => {
    updateDeviceWidth();
    window.addEventListener("resize", updateDeviceWidth);

    return window.removeEventListener("resize", updateDeviceWidth);
  }, [props]);

  const getDisplayCount = (width: number) => {
    switch (true) {
      case width > 1150:
        return 7;
      case width > 890:
        return 5;
      case width > 660:
        return 4;
      case width > 505:
        return 3;
      case width > 450:
        return 2;
      default:
        return 1;
    }
  };

  const increasePage = () => {
    if (state.currentPage + 1 <= state.pages - 1) {
      setState({ currentPage: state.currentPage + 1 });
      props.onPageChange?.(state.currentPage + 1);
    }
  };

  const decreasePage = () => {
    if (state.currentPage - 1 >= 0) {
      setState({ currentPage: state.currentPage - 1 });
      props.onPageChange?.(state.currentPage - 1);
    }
  };

  return props.customRender ? (
    <>
      {state.pages > 1 && (
        <div className={cx("pagination-buttons header-pagination")}>
          <button
            className={cx({ disabled: state.currentPage === 0 })}
            onClick={decreasePage}
          >
            <img
              className={cx({ hidden: props.plansMenuOpen })}
              src={arrow}
              alt="Previous"
            />
          </button>
          <p className="text">Day {state.currentPage + 1}</p>
          <button
            className={cx({
              disabled: state.currentPage === state.pages - 1
            })}
            onClick={increasePage}
          >
            <img src={arrow} alt="Next" />
          </button>
        </div>
      )}
    </>
  ) : (
    <div className={cx("meal-pagination", props.className)}>
      {state.pages > 1 && (
        <div className={cx("pagination-buttons")}>
          <button
            className={cx({ disabled: state.currentPage === 0 })}
            onClick={decreasePage}
          >
            <img
              className={cx({ hidden: props.plansMenuOpen })}
              src={arrow}
              alt="Previous"
            />
          </button>
          <button
            className={cx({
              disabled: state.currentPage === state.pages - 1
            })}
            onClick={increasePage}
          >
            <img src={arrow} alt="Next" />
          </button>
        </div>
      )}
      {props.children &&
        props.children
          .slice(
            state.perPage * state.currentPage,
            state.perPage * state.currentPage + state.perPage
          )
          .map((element, index) =>
            React.cloneElement(element, {
              index,
              key: index
            })
          )}
    </div>
  );
};

export default Pagination;
