import * as React from "react";

export interface IInputProperties extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  disabled?: boolean;
  postfix?: React.ReactNode;
  textarea?: boolean;
  error?: string | null;
  innerRef?: React.LegacyRef<HTMLInputElement>;
  classNames?: {
    root?: string 
    label?: string;
    input?: string;
    error?: string 
  };
}

const Input = (props: IInputProperties) => {
  return (
    <div className={`input-container ${props?.classNames?.root ?? ""}`}>
      {props.label && (
        <label className={props?.classNames?.label ?? ""} htmlFor={props.label}>
          {props.label}
        </label>
      )}
      <div className={`wrapper ${props.disabled ? "disabled" : ""}`}>
        {props.textarea ? (
          // @ts-ignore
          <textarea
            {...props}
            id={props.label ? props.label : undefined}
            className={`input textarea ${props.postfix ? "with-postfix" : ""}`}
          />
        ) : (
          <input
            {...props}
            id={props.label ? props.label : undefined}
            className={`input  ${props?.classNames?.input ?? ""} ${
              props.postfix ? "with-postfix" : ""
            }`}
            ref={props.innerRef}
          />
        )}
        {props.postfix && <div className="postfix">{props.postfix}</div>}
      </div>
      <span className={`error ${props?.classNames?.error ?? ""}`}>{props.error}</span>
    </div>
  );
};

export default Input;
