import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";

import Layout from "../../components/Layout";
import { callCreatePortalSession } from "../../api/subscriptions";
import { STRIPE_PK } from "../../config";

import Tabs from "../../components/BigTab";
import SubscriptionTab from "./SubscriptionTab";
import ProfileTab from "./ProfileTab";
import { withRouter } from "react-router-dom";
import PageHeader from "components/PageHeader";
import cx from "classnames";
import { Subscription } from "store/subscriptions/types";
import { useScript } from "utils/useScript";

type Props = {
  updatingUser: boolean;
  updatingUserError?: string;
  updateUser: (data: any) => void;
  getEnums: (enums: string[]) => void;
  allergens: { _id: string; name: string }[];
  units: { _id: string; name: string }[];
  getSubscriptions: () => void;
  getProfileSubscriptions: () => void;
  isTrial: boolean;
  subscriptions: Subscription[];
  match: any;
  trialEnded: boolean;
  history: {
    replace: (string) => void;
    push: (string) => void;
    location: any;
  };
  currentSubscription: {
    status: string;
    cancelAt: any;
  };
};

const AccountForm = (props: Props) => {
  const [tab, setTab] = useState(0);
  useScript(
    `var _learnq = _learnq || [];var page = window.location.href;_learnq.push(['track', 'Viewed Profile', {url: 'https://rawktheyear.com/account/profile'}]);`
  );
  useEffect(() => {
    props.getEnums(["allergy", "unitSystems"]);
    props.getProfileSubscriptions();
    props.getSubscriptions();
    if (props.match.params.section === "subscription") {
      setTab(1);
      if (!props.trialEnded && props.isTrial) {
        props.history.push("/account/profile");
      }
    }
  }, []);

  useEffect(() => {
    if (props.match.params.section === "subscription" && tab !== 1) {
      setTab(1);
      if (!props.trialEnded && props.isTrial) {
        props.history.push("/");
        setTab(0);
      }
    }
    if (props.match.params.section !== "subscription" && tab !== 0) {
      setTab(0);
    }
  }, [props.match.params.section, props.isTrial]);

  const changeTab = (index: number) => {
    setTab(index);
    props.history.replace(
      `/account/${index === 0 ? "profile" : "subscription"}`
    );
  };

  const navigateCheckoutPage = async subscription => {
    const res = await callCreatePortalSession("", true, subscription.period);

    const stripe = await loadStripe(STRIPE_PK);
    stripe.redirectToCheckout({
      sessionId: res.data.id
    });
  };

  return (
    <div
      className={cx("account-settings-background", {
        green: props.history.location.pathname === "/account/subscription"
      })}
    >
      <Layout center className="account-settings-container">
        {props.currentSubscription &&
          props.currentSubscription.status === "active-cancels" && (
            <div className="trial-message error">
              <h3 style={{ marginBottom: "10px" }}>MEMBERSHIP CANCELED</h3>
              <p>
                We're sorry to see you go! Your access to Rawk the Year expires:{" "}
                <b style={{ fontFamily: "Arial", fontWeight: "700" }}>
                  {moment(props.currentSubscription.cancelAt, "X").format(
                    "MM/DD/YYYY"
                  )}
                </b>
              </p>
              <p style={{ marginTop: "10px", fontSize: "12px" }}>
                (We hope you'll come back for our next seasonal cleanse!)
              </p>
            </div>
          )}
        {tab === 1 && (
          <p className="trial-message title">
            CHOOSE A PLAN TO{" "}
            {props.currentSubscription &&
            props.currentSubscription.status !== "active"
              ? "RE-"
              : ""}
            ACTIVATE YOUR MEMBERSHIP
          </p>
        )}
        <Tabs
          full
          tabs={props.trialEnded ? ["", "Subscription"] : [""]}
          active={tab}
          onChange={changeTab}
        >
          <div className="account-settings">
            <ProfileTab
              loading={props.updatingUser}
              error={props.updatingUserError}
              updateUser={props.updateUser}
              allergens={props.allergens.map(a => ({
                value: a._id,
                label: a.name
              }))}
              units={props.units.map(a => ({
                value: a._id,
                label: a.name
              }))}
            />
          </div>
          <div className="account-settings subscription">
            {(props.trialEnded || tab === 1) && (
              <SubscriptionTab
                subscriptions={props.subscriptions}
                navigateCheckoutPage={navigateCheckoutPage}
              />
            )}
          </div>
        </Tabs>
        <p className="account-settings-description">
          Immediate access to recipes + meal plans. Cancel anytime.
        </p>
      </Layout>
    </div>
  );
};

const mapStateToProps = state => ({
  updatingUser: state.loading["UPDATE_USER"],
  updatingUserError: state.error["UPDATE_USER"],
  allergens: state.enum.allergy,
  units: state.enum.unitSystems,
  subscriptions: state.subscriptions.all,
  isTrial: state.subscriptions.user.trial,
  trialEnded:
    !state.loading["GET_USER_SUBSCRIPTIONS"] &&
    (!state.subscriptions.user ||
      (!state.subscriptions.user._id &&
        state.subscriptions.user.name !== "Legacy Membership") ||
      state.subscriptions.user.status === "past-due"),
  currentSubscription: state.subscriptions.user
});

const mapDispatchToProps = dispatch => ({
  updateUser: (data, onSuccess) =>
    dispatch({ type: "UPDATE_USER", data, onSuccess }),
  getEnums: (enums: string[]) => dispatch({ type: "GET_ENUMS", enums }),
  getSubscriptions: () => dispatch({ type: "GET_SUBSCRIPTIONS" }),
  getProfileSubscriptions: () => dispatch({ type: "GET_PROFILE" })
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountForm)
);
