import React, { useState } from "react";
import cx from "classnames";
import Collapse from "react-collapse";

import arrowDown from "../../assets/images/common/arrow-down-b.svg";
import check from "../../assets/images/common/check-b.svg";

type Props = {
  tabs: string[];
  active: number;
  onChange: (number) => void;
  children: React.ReactNode[];
  thin?: boolean;
  full?: boolean;
};

type State = {
  isOpened: boolean;
};

const Tabs = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggleCollapse = () => setIsOpened(!isOpened);

  const handleChange = (i: number) => {
    props.onChange(i);
    toggleCollapse();
  };

  return (
    <div className="big-tabs">
      <div className="tab-options">
        {props.tabs.map((tab, index) => (
          <button
            key={tab}
            className={cx("option small-uppercase", {
              active: props.active === index,
            })}
            onClick={() => props.onChange(index)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="tab-options mobile">
        <button
          className="option small-uppercase active"
          onClick={toggleCollapse}
        >
          {props.tabs[props.active]}
          <img src={isOpened ? check : arrowDown} alt="Action" />
        </button>
        <Collapse isOpened={isOpened}>
          {props.tabs.map((tab, index) => (
            <button
              key={tab}
              className={cx("option small-uppercase")}
              onClick={() => handleChange(index)}
            >
              {tab}
            </button>
          ))}
        </Collapse>
      </div>
      <div
        className={cx("body", {
          thin: props.thin,
          "rounded-left": props.active !== 0,
          "rounded-right": props.active !== props.tabs.length - 1,
          full: props.full,
        })}
      >
        {props.children[props.active]}
      </div>
    </div>
  );
};

export default Tabs;
