import React, { useState, useEffect, useRef, ReactNode } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {paramCase} from "change-case";

import withWindowSize from '../../../../utils/withWindowWidth';
import FullScreenModal from "../../../../components/FullScreenModal";
import SearchInput from "../../../../forms/SearchInput";
import PageHeader from "../../../../components/PageHeader";
import RecipesFilter from "../../../Recipes/components/RecipesFilter";
import RecipeCard from "../../../Recipes/components/RecipeCard";
import NotFoundMessage from "../../../Recipes/components/NotFoundMessage";
import Button from '../../../../components/Button';


import type {
  Props as RecipeProps,
  Props as RecipeCardType
} from "../../../Recipes/components/RecipeCard";
import settings from "../../../../assets/images/common/sliders-vert.svg";
import { callGetRecipes } from "../../../../api/recipes";
import { StorageItem } from "utils/storage";
import CustomSelect from "containers/Recipes/components/Select";
import { SORT_OPTIONS } from "containers/Recipes/components/RecipesLayout";

type Props = {
  visible: boolean,
  close: () => void,
  windowWidth: number,
  recipes?: RecipeCardType[],
  getEnums: ([]) => any,
  seasons: string[],
  types: { _id: string, name: string }[],
  onInfoClick: (arg0: string, arg1: unknown) => void,
  onRecipeClick: (any) => void,
  dietOptions?: any,
};

type State = {
  title: string,
  isFilterOpen: boolean,
  tab: string,

  seasons: string[],
  types: string[],
  time: number[],
  reviews: any,
  favorites: boolean,
  haveMade: boolean,
  windowWidth: number,

  recipes: RecipeProps[],
  page: number,
  pages: number | null,
  loading: boolean,
  shouldFetchMore: boolean,

  loadingCount: boolean,
  filteredRecipesCount: number
  search: string;

  sort: 'new' | 'old' | 'quick' | 'rate' | 'alphabet'
};

const initialFilters = {
  title: "",
  seasons: [],
  types: [],
  time: [],
  reviews: undefined,
  favorites: undefined,
  haveMade: undefined,
  windowWidth: 0,
  search: '',
  sort: 'new' as const
};

const AddRecipe = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    ...initialFilters,
    isFilterOpen: false,
    tab: "all",

    recipes: [],
    page: 0,
    pages: null,
    loading: false,
    shouldFetchMore: true,

    loadingCount: false,
    filteredRecipesCount: 0,
    windowWidth: 0,
    search: '',
    sort: initialFilters.sort
  })

  const scrollable = useRef<HTMLDivElement>();

  const setState = (newState: Partial<State>, cb?) => {
    setCurrentState((prevState) => ({ ...prevState, ...newState }));
    if (cb) return cb();
  }

  useEffect(() => {
    props.getEnums(["seasons", "types"]);
    const fetchData = async () => {
      await handleLoad();
    }
    fetchData();
  }, [])

  useEffect(() => {
    if (state.shouldFetchMore) {
      handleLoad();
      countRecipesWithFilters(getQuery());
    }
  },[state.shouldFetchMore])

  const handleLoad = async () => {
    
    setState({ loading: true, shouldFetchMore: false });

    const filterParams = countFilters() > 0 ? getQuery() : {};
    const response = await callGetRecipes(state.page + 1, {
      ...filterParams,
      ...getPermanentParams()
    });
    
    setState({
      loading: false,
      page: parseInt(response.data.stats.page),
      pages: Math.ceil(response.data.stats.pages),
      recipes: [...state.recipes, ...response.data.stats.results]
    });
  };

  const getPermanentParams = () => {
    return {
      haveMade: state.haveMade,
      favorite: state.favorites,
      title: state.title || undefined,
      sort: state.sort
    };
  };

  const changeFilter = (field: string, value: string | number[] | number) => {
    let newValue = value;
    if ((typeof state[field] !== 'object' || !value) && state[field] === value) {
      newValue = initialFilters[field];
    }
    setState({
      [field]: newValue
    });

    setTimeout(() => {
      countRecipesWithFilters(getQuery({ [field]: newValue }));
      getFreshRecipes();
    });
  };

 const toggleFavorites = () => {
    setState({
      favorites: !state.favorites ? true : undefined
    });
    setTimeout(() => {
      countRecipesWithFilters(getQuery());
      getFreshRecipes();
    });
  }

 const toggleHaveMade = () => {

    setState({
      haveMade: !state.haveMade ? true : undefined
    });
    setTimeout(() => {
      countRecipesWithFilters(getQuery());
      getFreshRecipes();
    });
  }

  const getQuery = (newValue?: any) => {
    return {
      seasons: state.seasons,
      types: state.types,
      minTime: state.time[0],
      maxTime: state.time[1],
      minRating: state.reviews,
      sort: state.sort,
      ...newValue
    };
  };

  const countRecipesWithFilters = async params => {
    if (state.loadingCount) return

   setState({ loadingCount: true });
    const response = await callGetRecipes(1, {
      ...params,
      ...getPermanentParams()
    });

   setState({
      loadingCount: false,
      filteredRecipesCount: response.data.stats.count
    });
  };

  const clearFilters = () => {
    setState(initialFilters);
    setState({tab: 'all'});
    setTimeout(() => { getFreshRecipes() });
  };

  const countFilters = () => {
    let count = 0;

    count += !state.title ? 0 : 1;
    count += !state.seasons.length ? 0 : 1;
    count += !state.types.length ? 0 : 1;
    count +=
     state.time[0] !== undefined ||state.time[1] !== undefined
        ? 1
        : 0;
    count += state.reviews !== undefined ? 1 : 0;
    count += state.favorites ? 1 : 0;
    count += state.haveMade ? 1 : 0;

    return count;
  };

  const openFilters = () => {
   setState({
      isFilterOpen: true
    });
  };

  const closeFilters = () => {
   setState({
      isFilterOpen: false
    });
  };

  const getFreshRecipes = () => {
   setState({
        recipes: [],
        page: 0,
        shouldFetchMore: true
      }
     );
  };

  const applySearchFilter = e => {
    if (e.key === "Enter") {
     setState({
        title: e.target.value
      });

      setTimeout(() => {
       getFreshRecipes();
      });
    }
  };

  const onRangeChange = (value: number[]) => {
    setState({
      time: [0, value[0]]
    })
  }


  const getFiltersWithProps = () => {
    return (
      <RecipesFilter
        season={state.seasons}
        type={state.types}
        time={state.time}
        reviews={state.reviews}
        onChange={changeFilter}
        onClearClick={clearFilters}
        search={state.search}
        onSearchChange={(e) => setState({ search: e.target.value })}
        onKeyPress={applySearchFilter}
        countFilters={countFilters}
        onFinalChange={(value: number[]) => changeFilter('time', [0, value[0]])}
        onRangeChange={onRangeChange}
        disableHeader={true}

        filtersCount={countFilters()}
        filteredRecipesCount={state.filteredRecipesCount}
        loadingCount={state.loadingCount}

        seasonsOptions={props.seasons}
        typesOptions={props.types}

        windowWidth={props.windowWidth}
        favorites={state.favorites}
        toggleFavorites={toggleFavorites}
        haveMade={state.haveMade}
        toggleHaveMade={toggleHaveMade}
      />
    );
  };
    // $$FlowFixMe
    // @ts-ignore
    const hasMorePages =state.page < state.pages;

    return (
      <FullScreenModal
      visible={props.visible}
      actionButton={
        state.isFilterOpen ? closeFilters : props.close
      }
      // mobileButton="Clear all"
      mobileButtonAction={clearFilters}
      mobileButtonVisible={state.isFilterOpen}
      innerRef={scrollable}
      content={state.isFilterOpen && "no-padding-modal"}
    >
      <div className="add-recipe add-recipe-modal">
        <div className={cx("sidebar sidebar-modal", { visible: state.isFilterOpen })}>
          {getFiltersWithProps()}
        </div>
        <div className={cx("content-container",{hidden: state.isFilterOpen})}>
          <PageHeader title="Add recipe" spaceless />
            {/* <div className="search-container inside-modal">
              <SearchInput
                innerRef={searchRef}
                placeholder="Search"
                onKeyPress={applySearchFilter}
              />
              <button className="filter-button" onClick={openFilters}>
                <span>{countFilters()}</span>
                <p className="small-uppercase">Filters</p>
                <img src={settings} alt="Filters" />
              </button>
            </div>
            <div className='filter-statistics'>
              <div className='title'>
                Filters Selected:
                <span className='count'>{countFilters()}</span>
              </div>
              <Button className='clear-all' link onClick={clearFilters}>
                Clear filters to see all recipes
              </Button>
            </div> */}
            <div className='search-container'>
              <SearchInput
                value={state.search}
                onChange={(e) => setState({ search: e.target.value })}
                placeholder='Search all recipes...'
                onKeyPress={applySearchFilter}
                classNames={{ input: "mobile-search" }}
              />
              <div className="buttons">
                <button className='filter-button' onClick={openFilters}>
                  <img src={settings} alt='Filters' />
                  <p className='small-uppercase'>show filters</p>
                </button>
                <div className='divider' />
                <button className='filter-button' onClick={clearFilters}>
                  <p className='small-uppercase'>Clear filters</p>
                </button>
              </div>
            </div>
          <div
            className={cx('recipes-container')}
          >
            <CustomSelect
              options={SORT_OPTIONS}
              value={state.sort}
              onChange={(opt: string) => {
                changeFilter('sort', opt);
              }}
              leftLabel='sort by:'
              wrapperClassName='self-end'
            />
            {(state.loading ||
              !!state.recipes.length ||
              state.shouldFetchMore) &&
            scrollable.current ? (
              <InfiniteScroll
                style={{ overflow: "initial" }}
                dataLength={state.recipes.length}
                next={handleLoad}
                hasMore={
                  !state.loading &&
                  (state.shouldFetchMore || hasMorePages)
                }
                loader={null}
                className="recipes-grid-container"
                scrollableTarget={scrollable.current as unknown as ReactNode}
              >
                <div
                  className={cx("recipes-grid", {
                    visible: state.isFilterOpen
                  })}
                >
                  {state.recipes.map((recipe, i) => (
                    <RecipeCard
                      key={i}
                      {...recipe}
                      onInfoClick={e => props.onInfoClick(paramCase(recipe.title), e)}
                      onClick={() => props.onRecipeClick(recipe)}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <NotFoundMessage />
            )}
          </div>
        </div>
      </div>
    </FullScreenModal>
    );
}

const mapStateToProps = state => ({
  seasons: state.enum.seasons,
  types: state.enum.types,
});

const mapDispatchToProps = dispatch => ({
  getEnums: (enums: string[]) => dispatch({ type: "GET_ENUMS", enums })
});

export default connect(mapStateToProps, mapDispatchToProps)(withWindowSize(AddRecipe));
