import { call, put, takeLatest } from "redux-saga/effects";

import { callContactUs } from '../../api/misc';

export function* contactUs(action) {
  try {
    yield put({ type: "CONTACT_US_REQUEST" });
    const response = yield call(callContactUs, action.name, action.email, action.message);

    action.onSuccess();

    yield put({ type: "CONTACT_US_SUCCESS" });
  } catch (error) {
    yield put({
      type: "CONTACT_US_FAILURE",
      error: error.message
    });
  }
}

const authSaga = [
  takeLatest("CONTACT_US", contactUs),
];

export default authSaga;
