import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { StripeElementStyle } from "@stripe/stripe-js";
import React from "react";
import { useFormContext } from "react-hook-form";

import { SignInput } from "./SignInput";

import amexp from "../../../assets/images/payments/amexp.png";
import mastercard from "../../../assets/images/payments/mastercard.png";
import visa from "../../../assets/images/payments/visa.png";
import { CardCountrySelect } from "./CardCountrySelect";

export interface IPaymentErrors {
  number: string | null;
  cvc: string | null;
  expire: string | null;
}

export interface IPaymentCardProps {
  paymentErrors: IPaymentErrors;
  setPaymentErrors: React.Dispatch<React.SetStateAction<IPaymentErrors>>;
}

const INPUT_STYLES: StripeElementStyle = {
  empty: { "::placeholder": { color: "#A6A6A6" } },
  base: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
  },
};

export function PaymentCard(props: IPaymentCardProps) {
  const { paymentErrors, setPaymentErrors } = props;
  const methods = useFormContext();

  return (
    <div className="payment-details__card">
      <div className="card-number">
        <div className="card-cards">
          <div className="card-cards__cards">
            {[visa, mastercard, amexp].map((item) => {
              return (
                <img className="card-cards__cards item" src={item} alt="card" />
              );
            })}
          </div>
        </div>
        <CardNumberElement
          className="card-field__input card-field__input-top"
          options={{
            style: INPUT_STYLES,
          }}
          onChange={(e) =>
            setPaymentErrors((prev) => ({
              ...prev,
              number: e?.error?.message,
            }))
          }
        />
      </div>
      <div className="second-line">
        <div className="card-expiry card-field">
          <CardExpiryElement
            className="card-field__input card-field__input-bottom-left"
            options={{
              style: INPUT_STYLES,
              placeholder: "MM/YY",
            }}
            onChange={(e) =>
              setPaymentErrors((prev) => ({
                ...prev,
                expire: e?.error?.message,
              }))
            }
          />
          <span className="error sign-up-form__form-error">
            {paymentErrors?.expire}
          </span>
        </div>
        <div className="card-cvc card-field">
          <CardCvcElement
            className="card-field__input card-field__input-bottom-right"
            options={{
              style: INPUT_STYLES,
            }}
            onChange={(e) =>
              setPaymentErrors((prev) => ({
                ...prev,
                cvc: e?.error?.message,
              }))
            }
          />
          <span className="error sign-up-form__form-error">
            {paymentErrors?.cvc}
          </span>
        </div>
      </div>
      <span className="error sign-up-form__form-error">
        {paymentErrors?.number}
      </span>
      <div className="card-holder card-field">
        <h4 className="card-title">Cardholder name</h4>
        <SignInput
          control={methods.control}
          {...methods.register("cardHolder")}
          placeholder="Full name on card"
          error={methods.formState.errors?.cardHolder?.message?.toString()}
        />
      </div>
      <div className="sign-up-form__country card-field">
        <h4 className="card-title">Country or region</h4>
        <CardCountrySelect
          control={methods.control}
          {...methods.register("country")}
        />

        <SignInput
          control={methods.control}
          {...methods.register("zip")}
          placeholder="ZIP"
          error={methods.formState.errors?.zip?.message?.toString()}
        />
      </div>
    </div>
  );
}
