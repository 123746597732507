// @flow
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";

import plans from "../../../../assets/images/header/plan.png";
import plansActive from "../../../../assets/images/header/plans-active.svg";
import recipes from "../../../../assets/images/header/recipes.png";
import recipesActive from "../../../../assets/images/header/recipes-active.png";
import rawkstars from "../../../../assets/images/header/rawkstars.svg";
import rawkstarsActive from "../../../../assets/images/header/rawkstars-active.svg";

import * as config from "../../../../config";
import { UpgradeDropdown } from "./UpgradeDropdown";
import { callUpgradeTrial } from "api/subscriptions";
import SuccessModal from "containers/MealPlan/components/SuccessModal";
import Modal from "components/Modal/Modal";
import ModalLayout from "containers/ModalLayout";
import Button from "components/Button";

import { useAppDispatch } from "store/hooks";

const links = [
  {
    path: "/recipes",
    label: "Recipes",
    icon: recipes,
    iconActive: recipesActive
  },
  { path: "/plans", label: "Plans", icon: plans, iconActive: plansActive },
  {
    path: "",
    label: "Community",
    icon: rawkstars,
    iconActive: rawkstarsActive,
    target: "_blank"
  }
];

const Links = ({ linksHidden, isTrial }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const dispatch = useAppDispatch();

  const handleUpgrade = async () => {
    try {
      setIsUpgrading(true);
      await callUpgradeTrial();
      dispatch({ type: "GET_USER_SUBSCRIPTIONS" });
      setIsOpenDropdown(false);
      setIsOpenSuccessModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsUpgrading(false);
    }
  };

  return (
    <div>
      <UpgradeDropdown
        onClick={handleUpgrade}
        isVisible={isOpenDropdown}
        onClose={() => setIsOpenDropdown(false)}
        isLoading={isUpgrading}
      />
      <SuccessModal
        close={() => setIsOpenSuccessModal(false)}
        visible={isOpenSuccessModal}
      >
        <div className="upgrade-success-modal">
          <p className="message">
            Your membership is live! Check your inbox for an email with
            directions to access the community. See ya soon!
          </p>
          <Button onClick={() => setIsOpenSuccessModal(false)}>OK</Button>
        </div>
      </SuccessModal>
      <div className={cx("links", { hidden: linksHidden })}>
        {links.map((link, index) =>
          link.path ? (
            <NavLink key={index} to={link.path} activeClassName="active">
              {link.label.toUpperCase()}
            </NavLink>
          ) : (
            <a
              key={index}
              href={config[link.label.toUpperCase() + "_LINK"]}
              target={link.target}
            >
              {link.label.toUpperCase()}
            </a>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    linksHidden: state.app.headerLinksHidden,
    isTrial: state.subscriptions.user.trial
  };
};

export default connect(mapStateToProps, null)(Links);
