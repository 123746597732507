import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useLocation } from "react-router-dom";
import { Subscription } from "store/subscriptions/types";

export const useGetSubscription = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const subscriptions: Subscription[] = useAppSelector(
    (state) => state.subscriptions.all
  );

  useEffect(() => {
    dispatch({ type: "GET_SUBSCRIPTIONS" });
  }, [dispatch]);

  const index = useMemo(() => {
    const chosenSub = new URLSearchParams(search).get("subscriptionType");
    const foundedIndex = subscriptions?.findIndex(
      ({ lookupKey }) => lookupKey === chosenSub
    );

    if (foundedIndex === -1) {
      return 0;
    }

    return foundedIndex;
  }, [search, subscriptions]);

  return { index };
};
