import React from 'react';
import ReactDOM from 'react-dom';
import usePortal from '../../utils/customHooks/usePortal'

const ModalLayout = (props) => {
    const portalElement = usePortal('fullscreen-root');

    return ReactDOM.createPortal(
        props.children,
        portalElement
    )
}

export default ModalLayout;