import * as React from "react";
import clock from "../../../../assets/images/common/clock.svg";
import { useHistory } from "react-router";
import { paramCase } from "change-case";
import { IImages } from "api/recipes/type";
import { useOptimizerImage } from "utils/useOptimizerImage";

export interface IRecipe {
  _id: string;
  images: IImages;
  title: string;
  avgRating: number;
  calories: number;
  servings: number;
  time: number;
}

export interface IRecipeCardProps extends IRecipe {
  imageAsBg?: boolean;
  className?: {
    wrapper?: string;
    content?: string;
    image?: string;
    rating?: string;
    title?: string;
  };
}

export function RecipeCard(props: IRecipeCardProps) {
  const {
    _id,
    avgRating,
    calories,
    images,
    title,
    className,
    servings,
    time,
    imageAsBg = true
  } = props;

  const history = useHistory();
  const goRecipeInfoPage = () => {
    const titleSlug = paramCase(title);
    history.push(`/recipes/${titleSlug}`);
  };

  const optimizedImageLink = useOptimizerImage({
    common: images?.vertical,
    retina: images?.verticalX2
  });

  const Wrapper = imageAsBg ? ImageBGWrapper : DefaultWrapper;

  return (
    <div
      className={`recipe-card-main embla__slide recipe ${className?.wrapper ??
        ""}`}
    >
      <Wrapper
        className={className}
        optimizedImageLink={optimizedImageLink}
        goRecipeInfoPage={goRecipeInfoPage}
        images={images}
      >
        <div className={`recipe-content ${className?.content}`}>
          {!imageAsBg && (
            <div
              className="recipe-image-new"
              style={
                images?.vertical
                  ? { ...optimizedImageLink }
                  : { backgroundColor: "#eeeeee" }
              }
            />
          )}
          <p className={`recipe-rating ${className?.rating}`}>
            {`${avgRating}`.length === 1 ? avgRating : avgRating.toFixed(1)}
          </p>
          <h4 className={`recipe-title ${className?.title}`}>{title}</h4>
          <div className="recipe-info">
            <span className="recipe-time">
              <img src={clock} alt={"time"} />
              {time} MIN
            </span>{" "}
            | <span>{(calories / servings).toFixed()} CAL</span>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

const DefaultWrapper: React.FC<React.PropsWithChildren<any>> = ({
  children,
  goRecipeInfoPage
}) => (
  <div style={{ height: "100%", width: "100%" }} onClick={goRecipeInfoPage}>
    {children}
  </div>
);

const ImageBGWrapper: React.FC<React.PropsWithChildren<{
  className: { image?: string };
  images: IImages;
  optimizedImageLink: React.CSSProperties;
  goRecipeInfoPage: () => void;
}>> = ({
  children,
  className,
  images,
  goRecipeInfoPage,
  optimizedImageLink
}) => (
  <div
    className={`recipe-image ${className?.image}`}
    style={
      images?.vertical
        ? { ...optimizedImageLink }
        : { backgroundColor: "#eeeeee" }
    }
    onClick={goRecipeInfoPage}
  >
    {children}
  </div>
);
