import React, { useState } from "react";
import ClickOutside from "react-click-outside";
import Collapse from "react-collapse";
import cx from "classnames";

import Checkbox from "../Checkbox";
import arrowDown from "../../assets/images/common/arrow-down-gray.svg";

const SelectBase = props => {
  const [isOpened, setIsOpened] = useState(false);

  const open = () => setIsOpened(true);
  const close = () => setIsOpened(false);

  return (
    <ClickOutside onClickOutside={close}>
      <p className={cx("label", props.classNames.label)}>{props.label}</p>
      <div
        className={cx("select-container", {
          open: isOpened,
          hoverable: props.hoverable
        })}
      >
        <div className="value-container" onClick={isOpened ? close : open}>
          <p className={cx("value", { "text-muted": !props.value })}>
            {props.value || "Select"}
          </p>
          <img className="arrow" src={arrowDown} alt="Arrow" />
        </div>
        <Collapse isOpened={isOpened}>
          <div className="options">
            {props.options.map(option =>
              props.option({
                option,
                onChange: props.onChange,
                values: props.values
              })
            )}
          </div>
        </Collapse>
      </div>
      <div className="error">{props.error}</div>
    </ClickOutside>
  );
};

const defaultOption = props => (
  <div className="option" onClick={() => props.onChange(props.option.value)}>
    {props.option.label}
  </div>
);

export const Select = props => {
  const activeOption = props.options.find(o => o.value === props.value);
  return (
    <SelectBase
      {...props}
      hoverable
      option={defaultOption}
      value={activeOption ? activeOption.label : ""}
    />
  );
};

const checkboxOption = props => (
  <div className="option">
    <Checkbox
      name={props.option.label}
      active={props.values.includes(props.option.value)}
      onChange={() => props.onChange(props.option.value)}
    />
  </div>
);

export const MultileSelect = props => {
  return (
    <SelectBase
      {...props}
      option={checkboxOption}
      value={props.values.length ? `Selected: ${props.values.length}` : ""}
    />
  );
};
