import { SignUpLayout } from "components/SignUpLayout/SignUpLayout";
import { SignUpForm } from "./components/SignUpForm";

import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { STRIPE_PK } from "config";
import { ToastContainer } from "react-toastify";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK);
export interface INewSignUpProps {}

const options: StripeElementsOptions = {
  mode: "setup",
  currency: "usd",
  paymentMethodCreation: "manual",

  fonts: [
    {
      // family: "JosefinSans",
      cssSrc: "https://fonts.googleapis.com/css?family=Josefin+Sans",
      // weight: "400",
    },
  ],
};

export function NewSignUp() {
  return (
    <SignUpLayout>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        className="toast-container"
        toastClassName="toast error"
      />
      <Elements stripe={stripePromise} options={options}>
        <SignUpForm />
      </Elements>
    </SignUpLayout>
  );
}
