import { call } from "../core";

export const callGetPlans = (start, end) => {
  return call(`/plans/custom`, "GET", { params: { start, end } });
};

export const callGetAdminPlans = () => {
  return call(`/plans/admin`, "GET", {});
};

export const callGetCustomPlan = (id) => {
  return call(`/plan/`, "GET", { params: { nameSlug: id } });
};

export const callGetCurrentWeekPlan = (start, end) => {
  return call(`/plan/`, "GET", { params: { start, end } });
};

export const callGetLastModifiedPlan = (limit, page) => {
  return call(`/plan/last-modified`, "GET", { params: { limit, page } });
};

export const callSavePlan = (body) => {
  return call(`/plan`, "POST", { body });
};

export const callUpdatePlan = (id, mealPlans, name = null) => {
  return call(`/plan/${id}`, "PUT", { body: { mealPlans, name } });
};

export const callUpdatePlanPart = (id, body) => {
  return call(`/plan/${id}`, "PUT", { body });
};

export const callUpdateMealInPlan = (planId, mealId, body) => {
  return call(`/plan/${planId}/${mealId}`, "PATCH", { body });
};

export const callGetGrocery = (params) => {
  return call('/grocery', "GET", { params });
};

export const callDeletePlan = (id) => {
  return call(`/plan/${id}`, "DELETE", {});
};

export const callGetShoppingList = (params, query = "") => {
  return call(`/shoppinglist${query}`, 'GET', { params });
}

export const callUpdateCurrentWeekPlan = (mealPlans, start, end) => {
  return call('/plan/current-week/manage', 'POST', { body: { mealPlans, start, end } });
}

export const callUpdateDraftPlan = (mealPlans, start, end, id) => {
  return call('/plan/draft/manage', 'POST', { body: { mealPlans, start, end, id } });
}

export const callgetAACPPlans = (slug) => {
  return call(`/plans/aacp?nameSlug=${slug ?? ''}`, "GET", {});
};

export const callSaveCustomAACPPlan = (mealPlans, nameSlug) => {
  return call('/plan/aacp/manage', 'POST', { body: { mealPlans, nameSlug } });
}