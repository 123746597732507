import Switch from "components/Switch/Switch";
import { useHistory, useLocation } from 'react-router'
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Subscription } from "store/subscriptions/types";

interface IProps {
  // promocode?: string;
}

export const SUBSCRIPTION_PERIOD = {
  month: "Monthly",
  year: "Yearly"
};

export const SUBSCRIPTION_PERIOD_SHORT = {
  month: "mo",
  year: "yr"
};

const SUBSCRIPTION_PERIOD_BILLED = {
  month: "monthly",
  year: "annually"
};

export const SUBSCRIPTION_PERIOD_MONTHS = {
  month: 1,
  year: 12,
};

export const getMonthlyPrice = (data: number, months: number) =>
  Math.floor((data / months) * 100) / 100;

const SubscriptionPlanInfo = ({ promocode }: { promocode: string }) => {
  const subscriptions: Subscription[] = useAppSelector(
    state => state.subscriptions.all
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const { setValue, watch } = useFormContext();
  const selectedSubscriptionIndex = watch("subscriptionIndex");

  const selectedSubscription = subscriptions[selectedSubscriptionIndex || 0];

  if (!subscriptions?.length) {
    return null;
  }

  return (
    <div className="subscription-plan-info">
      <Switch
        options={subscriptions?.map(sub => ({
          id: sub._id,
          title: (
            <p className="subscription-plan-info__title">
              {SUBSCRIPTION_PERIOD[sub.period]}{" "}
              {sub.sale ? (
                <span className="subscription-plan-info__title-sale">
                  {sub.sale}
                </span>
              ) : (
                ""
              )}
            </p>
          )
        }))}
        activeItemId={selectedSubscription._id}
        onSelectItem={option => {
          const selectedItemIndex = subscriptions.findIndex(
            sub => sub._id === option.id
          );
          if (selectedItemIndex !== undefined) {
            setValue("subscriptionIndex", selectedItemIndex);
            const params = new URLSearchParams({
              subscriptionType: subscriptions[selectedItemIndex].lookupKey
            });
            history.replace({
              pathname: location.pathname,
              search: params.toString()
            });
            dispatch({
              type: "GET_SUBSCRIPTION",
              crossSellsIds: [],
              id: subscriptions[selectedItemIndex]._id,
              promocode
            });
          }
        }}
      />
      <p className="subscription-plan-info__price">
        {`$${
          selectedSubscription.period === "year"
            ? getMonthlyPrice(
                selectedSubscription.price,
                SUBSCRIPTION_PERIOD_MONTHS[selectedSubscription.period]
              )
            : selectedSubscription.price
        }/`}
        <span>{SUBSCRIPTION_PERIOD_SHORT["month"]}</span>
      </p>
      <p className="subscription-plan-info__subtitle">{`BILLED ${SUBSCRIPTION_PERIOD_BILLED[
        selectedSubscription.period
      ]?.toUpperCase()}${
        selectedSubscription.period === "year"
          ? `: $${selectedSubscription.price}/${
              SUBSCRIPTION_PERIOD_SHORT[selectedSubscription.period]
            }`
          : ""
      }`}</p>
    </div>
  );
};

export default SubscriptionPlanInfo;
