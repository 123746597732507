import React from "react";

import cross from "../../../assets/images/cross.svg";

type Props = {
  name: string;
  total: number;
  subtotal?: number;
  onRemoveProduct?: () => void;
};

const CartSummaryLine = (props: Props) => {
  return (
    <div className="cart-summary-item">
      <div className="cart-summary-item__left">
        <span>{props.name}</span>
        {props.onRemoveProduct && (
          <button
            onClick={props.onRemoveProduct}
            className="cart-summary-item__remove"
            type="button"
          >
            <img src={cross} alt="Close" />
          </button>
        )}
      </div>
      <div className="cart-summary-item__end">
        {props.subtotal && props.subtotal !== props.total && (
          <span className="cart-summary-item__subtotal">{`$${props.subtotal.toFixed(
            2
          )}`}</span>
        )}
        <span>{`$${props.total.toFixed(2)}`}</span>
      </div>
    </div>
  );
};

export default CartSummaryLine;
