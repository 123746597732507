import * as React from "react";
import moment from "moment";

import quote from "../../../../assets/images/common/quote.svg";
import authorMark from "../../../../assets/images/common/quote-author.svg";

import { useHistory } from "react-router-dom";
import winterBg from "../../../../assets/images/winterBg.jpeg";
import springBg from "../../../../assets/images/springBg.jpeg";
import summerBg from "../../../../assets/images/summerBg.jpeg";
import autumnBg from "../../../../assets/images/autumnBg.jpeg";
import SearchInput from "forms/SearchInput";
import { StorageItem } from "utils/storage";
import { callGetQuoteBackgrounds } from "api/misc";
import { useOptimizerImage } from "utils/useOptimizerImage";
import { useMediaQuery } from "utils/customHooks/use-media-query";

type Props = {
  text: string;
  name: string;
};

const getSeasonBg = (): string => {
  const month = moment().month() + 1;
  let background = winterBg;

  if (month >= 3 && month <= 5) {
    background = springBg;
  } else if (month >= 6 && month <= 8) {
    background = summerBg;
  } else if (month >= 9 && month <= 11) {
    background = autumnBg;
  }

  return background;
};

const Quote = (props: Props) => {
  const seasonBackground = getSeasonBg();
  const [searchesRecipe, setSearchesRecipe] = React.useState("");
  const [backgrounds, setBackgrounds] = React.useState<{
    common: string;
    retina: string;
  }>({ common: "", retina: "" });
  const isDesktop = useMediaQuery("(min-width: 1440px)");
  const isTablet = useMediaQuery("(min-width: 468px)");
  const isMobile = useMediaQuery("(max-width: 468px)");
  const history = useHistory();

  const searchClickHandler = (e) => {
    if (e.key === "Enter") {
      const storageTitle = new StorageItem("recipe-filter__title");
      storageTitle.set(searchesRecipe);
      history.push("/recipes");
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const {
          data: { images },
        } = await callGetQuoteBackgrounds();

        if (isDesktop) {
          setBackgrounds({ common: images.pc, retina: images.pcX2 });
          return;
        }
        if (isTablet) {
          setBackgrounds({ common: images.tablet, retina: images.tabletX2 });
          return;
        }
        if (isMobile) {
          setBackgrounds({ common: images.mobile, retina: images.mobileX2 });
          return;
        }
      } catch (error) {
        if (error.code === 404) {
          setBackgrounds({
            common: seasonBackground,
            retina: seasonBackground,
          });
        }
      }

      // setBackgrounds(bgImages);
    })();
  }, [isDesktop, isMobile, isTablet, seasonBackground]);

  const optimizedImage = useOptimizerImage(backgrounds);

  return (
    <>
      <div className="quote-container" style={optimizedImage}>
        <div className="quote">
          <SearchInput
            className="search-input-home"
            value={searchesRecipe}
            onChange={(e) => setSearchesRecipe(e.target.value)}
            placeholder="Search all recipes..."
            onKeyPress={(e) => searchClickHandler(e)}
          />
        </div>
      </div>
      <div className="quote-info">
        {props.text ? (
          <>
            <div className="quote-info-content">
              <p className="text">
                {props.text} {props.name ? `- ${props.name}` : ""}
              </p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Quote;
