import { createSlice } from "@reduxjs/toolkit";
import { ISetToken } from "./types";

const initialState = {
  accessToken: null,
  expiration: null
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setToken: (state, action: ISetToken) => {
      Object.assign(state, action.payload);
    },
    clearToken: state => {
      Object.assign(state, initialState);
    }
  }
});

export const { actions } = sessionSlice;

export default sessionSlice.reducer;
