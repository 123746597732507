import moment from "moment";
import { useCallback } from "react";

export const useGetLabelForCustomWeekPlan = () => {
  return useCallback((startTime: string, endTime: string) => {
    const start = moment.utc(startTime);
    const end = moment.utc(endTime);
    if (start.format("MMM") !== end.format("MMM")) {
      return `${start.format("MMM D")} - ${end.format("MMM D")}`;
    }

    return `${start.format("MMM D")} - ${end.format("D")}`;
  }, []);
};
