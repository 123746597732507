import * as React from "react";
import cx from "classnames";

import leftArrow from "../../assets/images/common/left-arrow.svg";
export interface ISliderNavigationProps {
  classNames?: { root?: string; leftButton?: string; rightButton?: string };
  onLeftClick: () => void;
  onRightClick: () => void;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
}

export function SliderNavigation(props: ISliderNavigationProps) {
  const {
    classNames,
    onLeftClick,
    onRightClick,
    leftDisabled,
    rightDisabled,
  } = props;
  return (
    <div className={cx("slider-navigation", classNames?.root ?? "")}>
      <button
        onClick={onLeftClick}
        className={cx("slider-button", classNames?.leftButton ?? "", {
          disabled: leftDisabled,
        })}
      >
        <img src={leftArrow} alt="left" />
      </button>
      <button
        onClick={onRightClick}
        className={cx("slider-button", classNames?.rightButton ?? "", {
          disabled: rightDisabled,
        })}
      >
        <img src={leftArrow} alt="right" />
      </button>
    </div>
  );
}
