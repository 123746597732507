import * as React from 'react';

type State = {
  windowWidth: number,
}

const withWindowSize = (Component: React.ComponentType<any>) => {
  return class HOC extends React.Component<any, State> {
    state = {
      windowWidth: 0,
    };

    componentDidMount() {
      this.updateDeviceWidth();
      window.addEventListener('resize', this.updateDeviceWidth);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDeviceWidth);
    }

    updateDeviceWidth = () => {
      const width = document.body.clientWidth;

      this.setState({
        windowWidth: width,
      });
    };

    render() {
      return <Component { ...this.props } windowWidth={this.state.windowWidth} />;
    }
  }
};

export default withWindowSize;
