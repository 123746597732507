import React, { useEffect, useState } from "react";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";

import calendar from "../../assets/images/common/calendar.svg";
import location from "../../assets/images/common/location-big.svg";
import { callGetEvent } from "../../api/events";
import { getFormattedDate } from "../Wellness/components/Event";

type Props = {
  match: {
    params: {
      id: string,
    }
  }
};

type State = {
  photo: string,
  title: string,
  startDate: string,
  endDate: string,
  location: string,
  info: string,
  signUpUrl?: string,
  callToActionText?: string,
};

const Event = (props: Props) => {
  const [data, setData] = useState<State>({
    photo: "",
    title: "",
    startDate: "",
    endDate: "",
    location: "",
    info: "",
    signUpUrl: '',
    callToActionText: '',
  });

  const setState = (newState: Partial<State>) => setData((prevState) => ({ ...prevState, ...newState }));

  useEffect(() => {
    const fetchData = async () => {
      const response = await callGetEvent(props.match.params.id);
      setState(response.data)
    };
    fetchData();
  });

  return (
    <Layout>
      <div
        className="main-image event-photo"
        style={{ backgroundImage: `url(${data.photo})` }}
      >
        <a
          className="button button-primary button-limited"
          href={data.signUpUrl}
          target="_blank"
        >
          {data.callToActionText}
        </a>
      </div>
      <div className="rounded-content article">
        <div className="article-title section-header with-info">
          <PageHeader title={data.title} spaceless />
          <div className="info-container">
            <div className="element">
              <img src={calendar} alt="Calendar" />
              {getFormattedDate(data.startDate, data.endDate)}
            </div>
            <div className="element">
              <img src={location} alt="Location" />
              {location}
            </div>
          </div>
        </div>
        <div
          className="article-body"
          dangerouslySetInnerHTML={{ __html: data.info }}
        />
      </div>
    </Layout>
  );
};

export default Event;
