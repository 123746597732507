import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  excludedIngredients: {},
};

const ingredientsSlice = createSlice({
  name: "ingredients",
  initialState,
  reducers: {
    setExcludedIngredients: (state, action) => {
      state.excludedIngredients = {...action.payload.excludedIngredients}
    },
    clearExcludedIngredients:(state, action) => {
      Object.assign(state, initialState);
    }
  },
});

export const { actions } = ingredientsSlice;

export default ingredientsSlice.reducer;
