import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Tabs from "../../components/BigTab";
import Layout from "../../components/Layout";

import Article from "./components/Article";
import Event from "./components/Event";
import Trial from "./Trial";
import * as config from "../../config/index";

type Props = {
  getEvents: () => void;
  currentSubscription: {
    [key: string]: any;
  };
  events: {
    id: string;
    title: string;
    photo: string;
    location: string;
    startDate: string;
    endDate: string;
  }[];
  getArticles: () => void;
  getSeasons: () => void;
  seasons: { _id: string; name: string }[];
  articles: {
    id: string;
    title: string;
    photo: string;
    duration: string;
    season: {
      _id: string;
    };
  }[];
  history: {
    push: (string) => void;
    goBack: () => void;
  };
};

const seasonsOrderMap = {
  spring: 0,
  summer: 1,
  autumn: 2,
  winter: 3,
};

const Wellness = (props: Props) => {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    props.getSeasons();
    props.getArticles();
    props.getEvents();
  }, []);

  const changeTab = (tab: number) => {
    setTab(tab);
  };

  const navigateToArticle = (id: string) => {
    props.history.push(`/wellness/article/${id}`);
  };

  const navigateToEvent = (id: string) => {

    props.history.push(`/wellness/event/${id}`);
  };

  if (props.currentSubscription.trial) {
    return <Trial />;
  } else {
    window.open(config.CLEANSE_LINK, "_blanc");
    props.history.goBack();
  }

  return (
    <Layout medium>
      <Tabs
        tabs={[
          ...props.seasons
            .sort((a, b) => seasonsOrderMap[a.name] - seasonsOrderMap[b.name])
            .map((s) => s.name),
          "Calendar of Events",
        ]}
        active={tab}
        onChange={changeTab}
        thin
      >
        {[
          ...props.seasons.map((season) => {
            const articles = props.articles
              .filter((a) => a.season && a.season._id === season._id)
              .map((article) => (
                <Article
                  {...article}
                  onClick={() => navigateToArticle(article.id)}
                />
              ));

            return (
              <div className="wellness-grid">
                {!!articles.length ? (
                  articles
                ) : (
                  <p className="center text-muted">No articles found</p>
                )}
              </div>
            );
          }),
          <div className="wellness-grid">
            {props.events.map((event) => (
              <Event {...event} onClick={() => navigateToEvent(event.id)} />
            ))}
          </div>,
        ]}
      </Tabs>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  events: state.events.all,
  articles: state.articles.all,
  seasons: state.enum.seasons,
  currentSubscription: state.subscriptions.user,
});

const mapDispatchToProps = (dispatch) => ({
  getSeasons: () => dispatch({ type: "GET_ENUMS", enums: ["seasons"] }),
  getEvents: () => dispatch({ type: "GET_EVENTS" }),
  getArticles: () => dispatch({ type: "GET_ARTICLES" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wellness);
