import { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";

import { GA_TOKEN } from "../config";
import logger from "../utils/logger";

export const CATEGORY_TYPES = {
  ecommerce: "ecommerce",
  wellness: "wellness",
  recipes: "recipes",
  subscription: "subscription",
  plans: "plans",
};

export const ACTION_TYPES = {
  openShop: "open_shop",
  download: "download",
  search: "search",
  filterSeason: "filter_season",
  filterRecipeType: "filter_recipe_type",
  filterDiet: "filter_diet",
  filterIngredients: "filter_ingredients",
  filterCookingTime: "filter_cooking_time",
  filterReviews: "filter_reviews",
  cancel: "cancel",
  subscribe: "subscribe",
  register: "register",
  createPlan: "create_plan",
  experimentalGroupMember: "experimental_group_member",
  LandOnSalesPage: "LandOnSalesPage",
  LandOnNoCcSignUpPage: "LandOnNoCcSignUpPage",
  ClickedTrialUpgradeLink: "ClickedTrialUpgradeLink",
  CompleteNoCcSignUp: "CompleteNoCcSignUp",
  CompleteConvertToPaid: "CompleteConvertToPaid",
  ClickedFreeTrialCta: "ClickedFreeTrialCta",
  CompletedSignUpWillNowReqCc: "CompletedSignUpWillNowReqCc",
};

export const googleAnalitics = {
  setUserId: (userId = null) => {
    logger.info("GA.setUserId with: ", userId);

    ReactGA.set({ userId });
  },

  init: () => {
    if (window.GA_INITIALIZED) {
      return;
    }
    logger.info("GA.init");

    ReactGA.initialize(GA_TOKEN, {
      gaOptions: {
        userId: null,
      },
      testMode: false,
    });

    window.GA_INITIALIZED = true;
  },

  logPageView: () => {
    const url = `${window.location.origin}${window.location.pathname}`;
    logger.info("GA.logPageView with: ", url);

    googleAnalitics.init();

    ReactGA.set({ page: url });
    ReactGA.pageview(url);
  },

  logEvent: ({ category = "", action = "", label = "" } = {}) => {
    googleAnalitics.init();

    logger.info(
      `GA.logEvent with:\n category: "${category}",\n action: "${action}",\n label: "${label}"`
    );

    ReactGA.event({ category, action, label });
  },
};

const useGoogleAnalitics = () => {
  const { user } = useSelector(({ user }) => ({ user }));

  useEffect(() => {
    googleAnalitics.setUserId(user?.id);
  }, [user]);

  useEffect(() => {
    googleAnalitics.logPageView();
  }, []);
};

const GoogleAnalitics = () => {
  useGoogleAnalitics();

  return null;
};

export default GoogleAnalitics;
