import React from 'react';
import moment from 'moment';

import location from '../../../../assets/images/common/location-m.svg';

type Props = {
  title: string,
  photo: string,
  location: string,
  startDate: string,
  endDate: string,
  onClick: () => void,
};

export const getFormattedDate = (start: string, end: string) => {
  const startMoment = moment(start);

  if (!end) {
    return startMoment.format("MMM D, YYYY")
  }

  const endMoment = moment(end);

  if (startMoment.format('YYYY') !== endMoment.format('YYYY')) {
    return `${startMoment.format('MMM DD, YYYY')} - ${endMoment.format('MMM DD, YYYY')}`
  }

  if (startMoment.format('MMM') !== endMoment.format('MMM')) {
    return `${startMoment.format('MMM DD')} - ${endMoment.format('MMM DD, YYYY')}`
  }

  return `${startMoment.format('MMM DD')}-${endMoment.format('DD, YYYY')}`
};

const Event = (props: Props) => (
  <div className="wellness-event" onClick={props.onClick}>
    <div className="photo" style={{ backgroundImage: `url(${props.photo})`}}>
      <div className="badge">
        {getFormattedDate(props.startDate, props.endDate)}
      </div>
    </div>
    <p className="title">{props.title}</p>
    <p className="location text-muted">
      <img src={location} alt="Location" />
      {props.location}
    </p>
  </div>
);

export default Event;
