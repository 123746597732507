import { STRIPE_CHECKOUT_LINK } from '../config';
import { googleAnalitics, CATEGORY_TYPES, ACTION_TYPES } from './GoogleAnalitics';

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = email => emailRegEx.test(email);

export const getChangedFields = (fields, state) => {
  const body = {};

  fields.forEach(field => {
    body[field] = state[field];
  });

  return body;
};

export const gotoStripeCheckout = () => {
  googleAnalitics.logEvent({
    category: CATEGORY_TYPES.ecommerce,
    action: ACTION_TYPES.ClickedFreeTrialCta,
  });

  window.location.href = '/sign-up'
  // window.open(STRIPE_CHECKOUT_LINK, '_self');
}

export const monthsObject = {
  'jan' : '01',
  'feb' : '02',
  'mar' : '03',
  'apr' : '04',
  'may' : '05',
  'jun' : '06',
  'jul' : '07',
  'aug' : '08',
  'sep' : '09',
  'oct' : '10',
  'nov' : '11',
  'dec' : '12'
}
