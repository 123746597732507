import { fraction, multiply, add } from "mathjs";
export const round = (value) => {
  const pieces = value.toString().split(".");
  if (pieces.length === 1) {
    return value;
  } else {
    let decimals =
      pieces[1].length === 1
        ? pieces[1] * 10
        : parseInt(pieces[1].substring(0, 2));
    return Math.floor(value) + (decimals > 50 ? 1 : 0);
  }
};
export const mcm = (val, dividend = 100) => {
  if (!val) {
    return 1;
  }
  let result = 1;
  for (let i = parseInt(val); i > 1; i--) {
    if (dividend % i === 0 && parseInt(val) % i === 0) {
      result = i;
      break;
    }
  }
  if (result < 10 && dividend === 100) {
    return mcm(val, dividend - 1);
  }
  return result >= 10 ? result : 1;
};

export const toFraction = (val) => {
  let value = val.toString().substring(0, 2);
  value = value.length === 1 && val[0] !== "0" ? parseInt(value) * 10 : value;
  const divider = mcm(value);
  const dividend = round(100 / (divider > 1 ? divider : parseInt(value)));
  const divisor = Math.floor(
    parseInt(value) / (divider > 1 ? divider : parseInt(value))
  );
  if (dividend % divisor === 0) {
    return { n: 1, d: dividend / divisor };
  }
  return { n: divisor, d: dividend };
};
export const getStandardFractionValue = (fraction) => {
  if (fraction.n == 1 && fraction.d == 3) {
    return 0.33;
  } else if (fraction.n == 2 && fraction.d == 3) {
    return 0.66;
  } else if (fraction.n == 1 && fraction.d == 2) {
    return 0.5;
  } else if (fraction.n == 1 && fraction.d == 4) {
    return 0.25;
  } else if (fraction.n == 3 && fraction.d == 4) {
    return 0.75;
  } else {
    return fraction.n / fraction.d;
  }
};
export const roundToStandardFraction = (value) => {
  const fraction = value.toString().split(".");

  if (fraction.length === 1) {
    return value;
  } else {
    const decimals = parseFloat(`0.${fraction[1]}`);
    if (decimals === 0) {
      return parseInt(`${fraction[0]}`);
    } else if (decimals <= 0.25) {
      return parseFloat(`${fraction[0]}.25`);
    } else if (decimals <= 0.4) {
      return parseFloat(`${fraction[0]}.33`);
    } else if (decimals <= 0.6) {
      return parseFloat(`${fraction[0]}.5`);
    } else if (decimals <= 0.75) {
      return parseFloat(`${fraction[0]}.75`);
    } else {
      return parseInt(fraction[0]) + 1;
    }
  }
};
export const calculateAmount = (value, coef, standardFraction = true) => {
  const cleanValue = value.split(" ").reduce((total, current) => {
    if (!current) {
      return total;
    }
    const fractionResult = fraction(current);
    return total + getStandardFractionValue(fractionResult);
  }, 0);
  return standardFraction
    ? roundToStandardFraction(parseFloat(cleanValue) * (coef || 1))
    : cleanValue * (coef || 1);
};

const formatFract = (numerator, denominator, supered) => {
  const numeratorClass = supered ? "sup-numerator" : "numerator";
  const denominatorClass = supered ? "sup-denominator" : "denominator";
  return `<span class="fraction">
      <span class="${numeratorClass}">${numerator}</span>
      ⁄
      <span class="${denominatorClass}">${denominator}</span>
      </span>`;
};

export const getIngredientQuantity = (value, coef, measure) => {
  const cleanValue = value
    .toString()
    .split(" ")
    .reduce((total, current) => {
      if (!current) {
        return total;
      }
      const fractionResult = fraction(current);
      return total + getStandardFractionValue(fractionResult);
    }, 0);
  const result = roundToStandardFraction(parseFloat(cleanValue) * (coef || 1));
  const val = parseFloat(result)
    .toString()
    .split(".");
  const fract = val.length > 1 ? toFraction(val[1]) : null;
  let output = val[0] > 0 ? val[0] : "";
  output += fract ? ` ${formatFract(fract.n, fract.d)}` : "";
  output += ` ${measure}`;
  if (result < 0.25) {
    return ``;
  }
  return output;
};

export const getPrettyNumber = (value, coef) => {
  if (!value) {
    return value;
  }
  if (value.toString().indexOf("/") > 0) {
    if (coef === 1) {
      return value;
    }
    let total = multiply(fraction(value), fraction(coef));
    const divisor = total.s * total.n;
    return divisor % total.d === 0
      ? divisor
      : `${coef % parseInt(coef) === 0 ? coef : coef.toFixed(1)} ${value}`;
  }
  if (coef) {
    return value % coef !== 0 ? (value * coef).toFixed(1) : value * coef;
  }

  if (!value || value % 1 === 0) {
    return value;
  }

  return value.toFixed();
};

function roundPercent(percents) {
  let sum = 0;
  return percents.map((percent, index) => {
    if (index === percents.length - 1) {
      return 100 - sum;
    }

    const roundedPercent = Math.round(percent);

    sum += roundedPercent;
    return roundedPercent;
  });
}
export default {
  round,
  getIngredientQuantity,
  getPrettyNumber,
  mcm,
  toFraction,
  roundToStandardFraction,
  calculateAmount,
  roundPercent,
};
