import React, { useEffect } from "react";
import moment from "moment";
import cx from "classnames";
import { Sticky } from "react-sticky";

type Props = {
  date: string | number;
  first?: boolean;
  withWord?: boolean;
};

const Day = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, window.scrollY + 1);
    window.scrollTo(0, window.scrollY - 1);
  }, []);

  return (
    <div className={cx("day", { word: props.withWord })}>
      <div className={cx("date", { first: props.first })}>
        <p className="name">
          {typeof props.date === "number"
            ? props.withWord
              ? `Day ${props.date}`
              : props.date
            : moment(props.date).weekday() != 0
            ? moment(props.date).weekday()
            : 7}
        </p>
        {/* <p className="name-mobile">{moment(props.date).format("dddd")}</p> */}
        {/* <p className="number">{moment(props.date).format("MMM D")}</p> */}
      </div>
    </div>
  );
};

export default Day;
