import { call } from "../core";

export const callGetEvents = () => {
  return call('/event/list/', "GET", {});
};


export const callGetEvent = (id: string) => {
  return call(`/event/${id}`, "GET", {});
};
