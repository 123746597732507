// @ts-nocheck
import React, { ChangeEvent, KeyboardEvent } from "react";
import cx from "classnames";

import { Slider, Rating, Multiple } from "../FilterElements";
import Button from "../../../../components/Button";

import star from "../../../../assets/images/common/star-b.svg";
import starWhite from "../../../../assets/images/common/star-w.svg";
import check from "../../../../assets/images/common/check-w.svg";
import SearchInput from "forms/SearchInput";

type Props = {
  season: string[];
  seasonsOptions: string[];
  type: string[];
  typesOptions: { _id: string; name: string }[];
  time: number[];
  reviews: any;
  onChange: (string, any) => void;
  onClearClick: () => void;
  filtersCount: number;
  disableHeader?: boolean;
  filteredRecipesCount: number;
  loadingCount: boolean;
  windowWidth: number;
  favorites: boolean;
  haveMade: boolean;
  toggleFavorites: () => void;
  toggleHaveMade: () => void;
  search: string;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (e: KeyboardEvent) => void;
  countFilters: () => number;
  onFinalChange?: (val: number[]) => void;
  onRangeChange?: (val: number[]) => void;
};

const getRatingOption = (number: number, active?: boolean) => (
  <div className="rating-option">
    {new Array(number).fill("").map((_, i) => (
      <img key={i} src={active ? starWhite : star} alt="Star" />
    ))}{" "}
    {number}+
  </div>
);

const RecipesFilter = (props: Props) => {
  let buttonRecipesCount =
    props.filtersCount === 0 ? "All" : props.filteredRecipesCount;
  if (props.loadingCount) {
    buttonRecipesCount = "...";
  }

  return (
    <div className="recipes-filter">
      {!props.disableHeader && (
        <div className="filter-statistics">
          <div className="title">
            Filters
            <span className="count">{props.filtersCount}</span>
          </div>
          <Button className="clear-all" link onClick={props.onClearClick}>
            Clear all
          </Button>
        </div>
      )}
      <div className="filters-search">
        <SearchInput
          value={props.search}
          onChange={props.onSearchChange}
          placeholder="Search all recipes..."
          onKeyPress={props.onKeyPress}
        />
        <div className="filter-statistics">
          <div className="title">
            Filters Selected:
            <span className="count">{props.countFilters()}</span>
          </div>
          <div className="divider" />
          <Button className="clear-all" link onClick={props.onClearClick}>
            Clear filters
          </Button>
        </div>
      </div>
      <div className="top-options">
        <button
          className={cx("option", { active: props.favorites })}
          onClick={() => props.toggleFavorites()}
        >
          Favorites
        </button>
        <button
          className={cx("option", { active: props.haveMade })}
          onClick={() => props.toggleHaveMade()}
        >
          Have Made
        </button>
      </div>
      <Multiple
        values={props.type}
        title="Recipe Type"
        options={props.typesOptions.map(t => ({
          value: t._id,
          label: t.name
        }))}
        onChange={values => props.onChange("types", values)}
      />
      <Multiple
        values={props.season}
        title="Season"
        options={props.seasonsOptions
          .filter(s => s.forRecipe)
          .map(s => ({
            value: s._id,
            label: s.name
          }))}
        onChange={values => props.onChange("seasons", values)}
      />
      <Slider
        value={[props.time[0] || 0, props.time[1] || 120]}
        onChange={props.onRangeChange}
        isCollapseDisabled={true}
        onlyMax={true}
        onFinalChange={props.onFinalChange}
      />
    </div>
  );
};

export default RecipesFilter;
