import * as React from "react";
import { IPaymentErrors, PaymentCard } from "./PaymentCard";
import lock from "../../../assets/images/lock.svg";

export interface IPaymentDetailsProps {
  paymentErrors: IPaymentErrors;
  setPaymentErrors: React.Dispatch<React.SetStateAction<IPaymentErrors>>;
}

export function PaymentDetails(props: IPaymentDetailsProps) {
  return (
    <div className="payment-details">
      <div className="payment-details__header">
        <h3>Card information</h3>
        <div className="payment-details__description">
          <img className="lock" src={lock} alt="lock" />
          <p className="payment-details__description-text">All transactions are secure and encrypted.</p>
        </div>
      </div>
      <PaymentCard {...props} />
    </div>
  );
}
