import * as React from "react";
import { useGetLabelForCustomWeekPlan } from "utils/customHooks/use-get-label-for-custom-week";
import PlanCard from "../PlanCard";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useGetCurrentWeek } from "utils/customHooks/use-get-current-week";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/hooks";
import { callGetQuote } from "../../../../api/misc";
import { callGetLastModifiedPlan } from "../../../../api/plans";
import useEmblaCarousel from "embla-carousel-react";
import cx from "classnames";

import { IImages } from "api/recipes/type";
import { SliderNavigation } from "components/SliderNavigation";
import { useMediaQuery } from "utils/customHooks/use-media-query";

export interface IPlanListProps {
  title: string;
  plans: any[];
  rows?: number;
  rating?: number;
  withTooltips?: boolean;
  header?: React.ReactNode;
  bgGray?: boolean;
  emptyText?: string;
  classNames?: {
    container?: string;
  };
  onRenamePlan?: (plan) => void;
  onDeletePlan?: (plan) => void;
  disabled?: boolean;
  onAACPClick?: () => void;
  isAACP?: boolean;
  titleIcon?: React.ReactNode;
}

export function PlanList(props: IPlanListProps) {
  const {
    plans,
    title,
    bgGray = false,
    header,
    rows = 1,
    emptyText = "Empty",
    onDeletePlan,
    onRenamePlan,
    disabled,
    onAACPClick,
    classNames,
    isAACP,
    titleIcon
  } = props;
  const getLabelForCustomWeekPlan = useGetLabelForCustomWeekPlan();
  const { start: startWeek } = useGetCurrentWeek();
  const mobileView = useMediaQuery("screen and (max-width: 475px)");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: true,
    dragFree: true,
    align: "start",
    loop: true,
    breakpoints: {
      "(min-width: 768px)": { active: false },
      "(max-width: 768px)": { active: true }
    }
  });

  useEffect(() => {
    emblaApi?.reInit();
  }, [emblaApi, plans]);

  const getWeekPlanNameSlug = startTime => {
    if (startTime) {
      const start = moment.utc(startTime);
      return `${start.format("MMM-DD-YY")}`;
    }

    return startWeek;
  };

  const history = useHistory();

  const goPlanPage = (id: string, isAACP?: boolean, daysSubstract?: number) => {
    history.push({
      pathname: isAACP ? `/plans/aacp/${id}` : `/plans/${id}`,
      state: {
        // WARN: HERE IS ALWAYS UNDEFINED???
        daysSubstract
      }
    });
  };

  const onTooltipToggle = (isOpen: boolean) => {
    setIsTooltipOpen(isOpen);
  };

  return (
    <div className={cx("top-recipe", { gray: bgGray })}>
      <div
        className={cx(
          `container wide lessPadding embla ${classNames?.container ?? ""}`
        )}
      >
        <div className="">
          <h2
            className={cx(
              "recipe-h2 recipe-header",
              !!titleIcon && "with-icon"
            )}
          >
            {titleIcon}
            {title}
            <>{header}</>
          </h2>
        </div>
        <div
          ref={emblaRef}
          className={cx("embla__viewport_default", { tooltip: isTooltipOpen })}
        >
          {plans?.length ? (
            <div className="recipes-grid embla__container_default grid-4 g-gap25">
              {plans?.map(plan => {
                return (
                  <PlanCard
                    {...plan}
                    label={plan?.name}
                    custom={isAACP ? false : !!plan?.nameSlug}
                    placeholder={getLabelForCustomWeekPlan(
                      plan.start,
                      plan.end
                    )}
                    images={
                      isAACP ? plan.mealPlans?.[0]?.recipe?.images : plan.photo
                    }
                    onClick={() =>
                      disabled
                        ? onAACPClick?.()
                        : goPlanPage(
                            plan.nameSlug || getWeekPlanNameSlug(plan.start),
                            isAACP
                          )
                    }
                    openDeleteModal={() => onDeletePlan(plan)}
                    openRenameModal={() => onRenamePlan(plan)}
                    onTooltipToggle={onTooltipToggle}
                  />
                );
              })}
            </div>
          ) : (
            <p className="plans-list__empty">{emptyText}</p>
          )}
        </div>
        {plans?.length > 1 && mobileView && (
          <SliderNavigation
            classNames={{
              root: `top-recipe-navigation ${isTooltipOpen && "z-1"}`
            }}
            onLeftClick={() => emblaApi?.scrollPrev()}
            onRightClick={() => emblaApi?.scrollNext()}
          />
        )}
      </div>
    </div>
  );
}
