import { call } from "../core";

export const callCreateRecipe = (rating: number, review: string, recipe: string) => {
  return call(`/review`, "POST", { body: { rating, review, recipe } });
};

export const callUpdateReview = (id: string, body) => {
  return call(`/review/${id}`, "PUT", { body });
};

export const callRemoveReview = (id: string) => {
  return call(`/review/${id}`, "DELETE", {});
};
