import React from 'react';
import cx from 'classnames';

import starBlack from '../../../../assets/images/common/star-b.svg';
import starBlackEmpty from '../../../../assets/images/common/star-empty.svg';

type Props = {
  value: number,
  black?: boolean,
}

const Rating = (props: Props) => {
  const rating = Math.round(props.value);
  const stars = [
    ...new Array(rating).fill(true),
    ...new Array(5 - rating).fill(false)
  ];

  return (
    <div className="rating">
      <div className="images">
        {stars.map((active, index) => <img key={index} src={active ? starBlack : starBlackEmpty} className={cx({ disabled: !active })} alt="Rating" />)}
      </div>
      <p className="text-muted">
        {props.value}
      </p>
    </div>
  );
};

export default Rating;
