import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    all: []
  };

const recipesSlice = createSlice({
    name: "recipes",
    initialState,
    reducers: {
        setRecipes: (state, action) => {
            state.all = [...state.all, action.payload.recipes];
        }
    }
});

export const { actions } = recipesSlice;

export default recipesSlice.reducer;
