import * as React from "react";
import cx from "classnames";
import loader from "../../assets/images/loader.gif";
type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  primary?: boolean;
  outlined?: boolean;
  small?: boolean;
  limited?: boolean;
  text?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  className?: string;
  link?: boolean;
  children?: React.ReactNode;
  isLoading?: boolean;
};

const Button = (props: Props) => (
  <button
    {...props}
    className={cx("button", props.className, {
      "button-primary": props.primary,
      "button-outlined": props.outlined,
      "button-small": props.small,
      "button-limited": props.limited,
      "button-text": props.text,
      "button-transparent": props.transparent,
      "button-disabled": props.disabled,
      "button-link": props.link,
    })}
    
  >
    {!props.isLoading ? (
      props.children
    ) : (
      <>
        <img src={loader} className="button-loader" alt="loading..." />
      </>
    )}
  </button>
);

export default Button;
