import React, { useState } from "react";
import ClickOutside from "react-click-outside";

type Button = {
  title: string;
  icon: string;
  onClick: () => void;
};

type Props = {
  buttons?: Button[];
  body?: React.ReactElement;
  dotsClassName?: string;
  onToggle?: (isOpen: boolean) => void;
  customAction?: (cb: () => void) => void;
};

const Tooltip = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = e => {
    const handler = () => {
      e.stopPropagation();
      setIsOpen(!isOpen);
      props.onToggle?.(!isOpen);
    };

    if (props.customAction) {
      props.customAction(handler);
    } else {
      handler();
    }
  };

  const close = () => {
    setIsOpen(false);
    props.onToggle?.(false);
  };

  const handleClick = (callback: () => void) => {
    close();
    callback();
  };

  if (props.buttons && props.buttons.filter(Boolean).length === 0) {
    return null;
  }

  return (
    <ClickOutside onClickOutside={close}>
      <div className={`tooltip ${props.dotsClassName ?? ""}`}>
        <div
          className={`tooltip-dots ${props.dotsClassName ?? ""}`}
          onClick={toggle}
        >
          <div />
          <div />
          <div />
        </div>
        <div className={`tooltip-body ${isOpen ? "open" : ""}`}>
          {props.body && (
            <div className="tooltip-extrabody">
              {React.cloneElement(props.body, { open: isOpen })}
            </div>
          )}
          {props.buttons &&
            props.buttons.filter(Boolean).map(button => (
              <button
                key={button.title}
                className="tooltip-button"
                onClick={e => {
                  e.stopPropagation();
                  handleClick(button.onClick);
                }}
              >
                <img src={button.icon} alt={button.title} />
                {button.title}
              </button>
            ))}
        </div>
      </div>
    </ClickOutside>
  );
};

export default Tooltip;
