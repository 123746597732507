import React, { useState, useEffect } from "react";
import Pagination from 'react-paginate';
import { connect } from 'react-redux';

import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import Map from './components/Map';
import SearchInput from "../../forms/SearchInput";
import User from "./components/User";
import Review from "../../components/Review";
import NotFoundMessage from "./components/NotFoundMessage";
import rawkstars from '../../assets/images/rawkstars-image.png';
import arrowBack from '../../assets/images/common/arrow-back.svg';
import arrowNext from '../../assets/images/common/arrow-next.svg';

import type {Props as ReviewType} from "../../components/Review";
import type {Props as UserType} from './components/User';

import * as config from '../../config';



type Props = {
  users: UserType[],
  getUsers: (page: number, search: string, onPagesFetch: (number) => void) => void,
  reviews: ReviewType[],
  getReviews: (page: number, onPagesFetch: (number) => void) => void,
  getStatistics: () => void,
  statistics: Array<any>
};

type State = {
  search: string,
  usersPage: number,
  usersPages: number,
  reviewsPage: number,
  reviewsPages: number,
};

const Rawkstars = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    search: '',
    usersPage: 1,
    usersPages: 0,
    reviewsPage: 1,
    reviewsPages: 0
  });

  const setState = (newState: Partial<State>) => setCurrentState((prevState) => ({ ...prevState, ...newState }));

  useEffect(() =>{
    props.getStatistics();
    getUsersWithPage();
    getReviewsWithPage();
  }, [])

  const getUsersWithPage = (): void => {
    props.getUsers(
      state.usersPage,
      state.search,
      (pages) => updateTotalPages('usersPages', pages)
    );
  };

  const getReviewsWithPage = (): void => {
    props.getReviews(
      state.reviewsPage,
      (pages) => updateTotalPages('reviewsPages', pages)
    );
  };

  const updateCurrentPage = (field: string, value: number): void => {
    const map = {
      usersPage: getUsersWithPage,
      reviewsPage: getReviewsWithPage,
    };

    setState({ [field]: value });

    setTimeout(() => {
      map[field]();
    })
  };

  const updateTotalPages = (field: string, pages: number): void => {
    setState({ [field]: pages })
  };

  const changeSearchValue = (e): void => {
    setState({ search: e.target.value });
  };

  const applySearch = (e): void => {
    if (e.key === 'Enter') {
      updateCurrentPage('usersPage', 1);
    }
  };

    return (
      <Layout>
        <div className="rawkstars-page">
          <div className="main-image" style={{ backgroundImage: `url(${rawkstars})` }}>
            <PageHeader title="Join the Rawkstar Collective"/>
            <a className="button button-primary button-limited" href={config.JOIN_US_LINK} target="_blank">
              Join Us
            </a>
          </div>
          <div className="rounded-content">
            <PageHeader title="We’re the Rawkstars!"/>
            <Map data={props.statistics && props.statistics.reduce((acc, curr) => {
              acc[curr._id] = curr;
              return acc;
            }, {})}/>
            <PageHeader title="Roll Call!"/>
            <SearchInput placeholder="Search" value={state.search} onChange={changeSearchValue} onKeyPress={applySearch} />
            {!!props.users.length ? (
              <>
                <div className="rawkstars-user-grid">
                  {props.users.map(u => <User {...u} />)}
                </div>
                {state.usersPages > 1 && (
                  <div className="pagination">
                    <Pagination
                      forcePage={state.usersPage - 1}
                      pageCount={state.usersPages}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={1}
                      activeClassName="active"
                      onPageChange={({ selected }) => updateCurrentPage('usersPage', selected + 1)}
                      previousLabel={<img src={arrowBack} alt="back"/>}
                      nextLabel={<img src={arrowNext} alt="next"/>}
                    />
                  </div>
                )}
              </>
              ) : (
              <NotFoundMessage>No Users Found</NotFoundMessage>
            )}
            <PageHeader title="Recent reviews"/>
            <div className="reviews">
              {props.reviews.map((r, i) => <Review key={i} disableEditing {...r} />)}
            </div>
            <div className="pagination">
              <Pagination
                forcePage={state.reviewsPage - 1}
                pageCount={state.reviewsPages}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                activeClassName="active"
                onPageChange={({ selected }) => updateCurrentPage('reviewsPage', selected + 1)}
                previousLabel={<img src={arrowBack} alt="back"/>}
                nextLabel={<img src={arrowNext} alt="next"/>}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

const mapStateToProps = state => ({
  users: state.rawkstars.users,
  reviews: state.rawkstars.reviews,
  statistics: state.rawkstars.statistics,
});

const mapDispatchToProps = dispatch => ({
  getUsers: (page: number, search: string, onPagesFetch) => dispatch({ type: 'GET_USERS', page, search, onPagesFetch }),
  getReviews: (page: number, onPagesFetch) => dispatch({ type: 'GET_REVIEWS', page, onPagesFetch }),
  getStatistics: () => dispatch({ type: 'GET_STATISTICS' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rawkstars);
