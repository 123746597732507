import React from "react";
import cx from "classnames";

import FullScreenModal from "../../../../components/FullScreenModal";
import close from "../../../../assets/images/common/close-black.svg";
import ModalLayout from "../../../../containers/ModalLayout";
import closeWhite from '../../../../assets/images/common/close-white.svg';

const Modal = (props) =>
  props.windowWidth < 1200 ? (
    <FullScreenModal {...props} />
  ) : (
    <ModalLayout>
      <div className={cx("modal", { visible: props.visible })}>
        <div className="scrollable-layer">
          <div className="body">
            <div className="button-container">
              <button
                className="close-button"
                onClick={props.actionButton}
              >
                <img src={closeWhite} alt="Close"/>
              </button>
              {/*<Button link onClick={props.mobileButtonAction}>*/}
              {/*  Clear all*/}
              {/*</Button>*/}
            </div>
            {props.children}
          </div>
        </div>
      </div>
      ,
    </ModalLayout>
  );

export default Modal;
