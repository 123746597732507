import React from "react";
import cx from "classnames";

import Button from "../Button";
import ModalLayout from "containers/ModalLayout";

type Props = {
  onClick: () => void;
  close: () => void;
  visible: boolean;
  content: string;
};

const Modal = (props: Props) => {
  const { close, content, onClick, visible } = props;
  
  const handleSave = () => {
    onClick();
    close();
  };

  return (
    <ModalLayout>
      <div className={cx("modal save-plan-modal", { visible })}>
        <div className="scrollable-layer">
          <div className="body">
            <p className="message">{content}</p>
            <div className="footer-buttons">
              <Button small onClick={close}>
                No
              </Button>
              <Button primary small onClick={handleSave}>
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default Modal;
