import { callSendAbandonedUser } from "api/authentication";
import { IInputProperties } from "forms/Input";
import React from "react";
import { useFormContext } from "react-hook-form";

import { useDebounce } from "utils/customHooks/use-debounce";
import { IPaymentErrors } from "./PaymentCard";
import { PaymentDetails } from "./PaymentDetails";
import { ICommentProps } from "./SignComment";
import { SignInput } from "./SignInput";

import arrowLeft from "../../../assets/images/arrow-left.svg";
import { useMediaQuery } from "utils/customHooks/use-media-query";

export interface ISignUpFormFieldsProps {
  comment: ICommentProps;
  isPaymentStep: boolean;
  onBack: () => void;
}

const classNames = {
  root: "sign-up-form__form-root",
  input: "sign-up-form__form-input",
  label: "sign-up-form__form-label",
  error: "sign-up-form__form-error"
};

interface IInputField {
  label: string;
  value: string;
  options?: IInputProperties;
}

const INPUT_FIELDS: IInputField[] = [
  {
    label: "First Name",
    value: "firstName",
    options: {
      type: "text",
      placeholder: "First Name",
      classNames: {
        ...classNames,
        input: "sign-up-form__form-input sign-up-form__form-input-left"
      }
    }
  },
  {
    label: "Last Name",
    value: "lastName",
    options: {
      type: "text",
      placeholder: "Last Name",
      classNames: {
        ...classNames,
        input: "sign-up-form__form-input sign-up-form__form-input-right"
      }
    }
  },
  {
    label: "Email Address",
    value: "email",
    options: {
      type: "email",
      placeholder: "Email Address",
      onCopy: e => {
        e.preventDefault();
      },
      onCut: e => {
        e.preventDefault();
      },
      classNames
    }
  },
  {
    label: "Retype Email Address",
    value: "emailConfirmation",
    options: {
      type: "email",
      placeholder: "Retype Email Address",
      autoComplete: "off",
      onPaste: e => {
        e.preventDefault();
      },
      classNames
    }
  },
  {
    label: "Create Password (used to access membership site)",
    value: "password",
    options: { type: "password", placeholder: "Min 6 characters", classNames }
  }
];

export function SignUpFormFields(props: ISignUpFormFieldsProps) {
  const methods = useFormContext();
  const isMobile = useMediaQuery("(max-width: 993px)");

  const debouncedEmail = useDebounce(methods.watch("email"), 500);
  const debouncedEmailConfirmation = useDebounce(
    methods.watch("emailConfirmation"),
    500
  );

  React.useEffect(() => {
    if (!debouncedEmail || debouncedEmail !== debouncedEmailConfirmation) {
      return;
    }

    (async () => {
      await callSendAbandonedUser({
        email: debouncedEmail,
        firstName: methods.getValues("firstName"),
        lastName: methods.getValues("lastName")
      });
    })();
  }, [debouncedEmail, debouncedEmailConfirmation, methods]);

  const [paymentErrors, setPaymentErrors] = React.useState<IPaymentErrors>({
    cvc: "",
    expire: "",
    number: ""
  });

  return (
    <div className="sign-up-form__container">
      <div className="sign-up-form">
        <div className="sign-up-form__title-wrapper">
          <h2 className="sign-up-form__title">
            {!props.isPaymentStep
              ? "Activate your free trial"
              : "Enter payment details"}
          </h2>
          {props.isPaymentStep && (
            <button
              className="sign-up-form__title-button"
              type="button"
              onClick={props.onBack}
            >
              <img
                src={arrowLeft}
                className="sign-up-form__title-button-icon"
              />
              {isMobile ? "Back" : "Back to personal info"}
            </button>
          )}
        </div>
        {!props.isPaymentStep && (
          <>
            <div className="sign-up-form__info">
              {INPUT_FIELDS.slice(0, 2).map(({ label, value, options }) => {
                const error =
                  methods.formState.errors?.[value]?.message?.toString() ?? "";
                const classNames = options.classNames?.input
                  ? {
                      ...options.classNames,
                      input: `${options.classNames.input} ${
                        error ? "sign-up-form__form-input-error" : ""
                      }`
                    }
                  : {
                      ...options,
                      classNames: {
                        input: "sign-up-form__form-input-error"
                      }
                    };
                return (
                  <SignInput
                    key={value}
                    control={methods.control}
                    {...methods.register(value)}
                    label={label}
                    {...{ ...options, classNames }}
                    error={error}
                  />
                );
              })}
            </div>
            <div className="sign-up-form__form">
              {INPUT_FIELDS.slice(2).map(({ label, value, options }) => {
                const error =
                  methods.formState.errors?.[value]?.message?.toString() ?? "";
                const classNames = options.classNames
                  ? {
                      ...options.classNames,
                      input: `${options.classNames?.input || ""} ${
                        error ? "sign-up-form__form-input-error" : ""
                      }`
                    }
                  : {
                      ...options,
                      classNames: {
                        input: "sign-up-form__form-input-error"
                      }
                    };
                return (
                  <SignInput
                    key={value}
                    control={methods.control}
                    {...methods.register(value)}
                    label={label}
                    {...{ ...options, classNames }}
                    error={error}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>

      {props.isPaymentStep && (
        <div className="sign-up-payment">
          <PaymentDetails
            paymentErrors={paymentErrors}
            setPaymentErrors={setPaymentErrors}
          />
        </div>
      )}
    </div>
  );
}
