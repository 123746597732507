import React, { useEffect, useState } from "react";
import cx from "classnames";
import UAParser from "ua-parser-js/dist/ua-parser.min";

import Counter from "../../../../components/Counter";
import Tooltip from "../../../../components/Tooltip";
import Button from "../../../../components/Button";

import refresh from "../../../../assets/images/common/refresh.svg";
import trash from "../../../../assets/images/common/trash.svg";
// import move from "../../../../assets/images/common/move.svg";
import addRecipe from "../../../../assets/images/common/add-recipe.svg";
import addRecipeBlack from "../../../../assets/images/common/add-recipe-black.svg";
import { IImages } from "api/recipes/type";
import { useOptimizerImage } from "utils/useOptimizerImage";

export type CardType = {
  portions?: number;
  images?: IImages;
  title?: string;
  onDragStart?: any;
  _id?: any;
};

type Props = CardType & {
  empty?: boolean;
  addRecipe?: () => void;
  showAcceptModal: any;
  acceptMove: any;
  recipe: any;
  draggingId: any;
  updatePortions: any;
  onSwapClick: any;
  onRemoveClick: any;
  onClick: any;
  moving: any;
  emptyText: string;
  desktopSize: any;
  onTooltipClick?: (cb: () => void) => void;
};

const Card = (props: Props) => {
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [hovered, setHovered] = useState(false);
  // const [modal, setModal] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const optimizedImageLink = useOptimizerImage({
    common: props?.recipe?.images?.square,
    retina: props?.recipe?.images?.squareX2
  });

  useEffect(() => {
    const parser = new UAParser();
    parser.setUA(window.navigator.userAgent);
    const { device } = parser.getResult();
    setIsDesktop(!device.type);
  }, []);

  const handleDragStart = () => {
    props.onDragStart(props._id);
    setTooltipVisible(false);

    setTimeout(() => {
      setDragging(true);
    });
  };

  const resetState = () => {
    setTooltipVisible(true);
    setDragging(false);
  };

  const allowDrop = e => {
    e.preventDefault();
  };

  const handleDrop = () => {
    if (!props.empty) {
      props.showAcceptModal(props.recipe.title, props.acceptMove, props._id);
    } else {
      props.acceptMove();
    }
  };

  const addBorder = () => {
    setTimeout(() => {
      setHovered(true);
    });
  };

  const hideBorder = () => {
    setHovered(false);
  };

  return (
    <div
      className={cx("card-border", {
        active: hovered && (!props._id || props._id !== props.draggingId)
      })}
      onDrop={isDesktop ? handleDrop : f => f}
      onDragOver={isDesktop ? allowDrop : f => f}
      onDragEnter={isDesktop ? addBorder : f => f}
      onDragLeave={isDesktop ? hideBorder : f => f}
    >
      {!props.empty && props.recipe && !dragging ? (
        <div
          className="card"
          draggable="true"
          onDragStart={isDesktop ? handleDragStart : f => f}
          // WARN: Is it an error? 'desktopSize' appeared from nowhere.
          onDragEnd={props?.desktopSize ? resetState : f => f}
        >
          <div
            onClick={() => props.onClick(props.portions)}
            className="card-image"
            style={optimizedImageLink}
          >
            {/* <span className="card-potrions">{props.portions}</span> */}
          </div>
          <div className="card-body">
            <p className="card-name">{props.recipe.title}</p>
            <div className="card-controls">
              <Counter
                value={props.portions}
                onChange={props.updatePortions}
                className={{
                  button: "card-counter-button",
                  value: "card-counter-value"
                }}
              />
              {tooltipVisible && (
                <div className="tootip-absolute">
                  <Tooltip
                    buttons={[
                      {
                        icon: refresh,
                        title: "Swap Out",
                        onClick: props.onSwapClick
                      },
                      {
                        icon: trash,
                        title: "Delete",
                        onClick: props.onRemoveClick
                      }
                    ]}
                    dotsClassName="no-padding"
                    customAction={props.onTooltipClick}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="card card--empty"
          onDragEnd={isDesktop ? resetState : f => f}
        >
          <Button
            className="card-button"
            onClick={props.moving ? props.acceptMove : props.addRecipe}
          >
            <img src={addRecipeBlack} alt="Add recipe" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Card;
