import React, { useState, useEffect, SyntheticEvent } from "react";
import { connect } from "react-redux";

import Layout from "../../components/Layout";
import Input from "../Input";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";

import background from "../../assets/images/contact-us-image.png";
import fb from "../../assets/images/socials/fb.svg";
import instagram from "../../assets/images/socials/instagram.svg";
import youtube from "../../assets/images/socials/youtube.svg";
import pinterest from "../../assets/images/socials/pinterest.svg";
import web from "../../assets/images/socials/web.svg";
import check from "../../assets/images/common/check-w.svg";

import { isValidEmail } from "../../utils";

type Props = {
  loading: boolean;
  error?: string;
  contactUs: (
    name: string,
    email: string,
    message: string,
    onSuccess: () => void
  ) => void;
};

type State = {
  email: string;
  name: string;
  message: string;
  errors: {
    email?: string | null;
    name?: string | null;
    message?: string | null;
  };
  success: boolean;
};

const ContactUs = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    email: "",
    name: "",
    message: "",
    errors: {},
    success: false,
  });
  const [buttonText, setButtonText] = useState("Submit");
  const setState = (newState: Partial<State>) => setCurrentState((prevState) => ({ ...prevState, ...newState }));

  useEffect(() => {
    setButtonText(props.loading ? "Submitting..." : "Submit");

    if (state.success) setButtonText("Sent");
  }, [props.loading, state.success]);

  const updateField = (e: SyntheticEvent<HTMLInputElement>, field: string) => {
    setState({
      [field]: e.currentTarget.value,
      errors: {},
      success: false,
    });
  };

  const validateFields = () => {
    const isEmailValid = isValidEmail(state.email);
    const nameExists = !!state.name;
    const messageExists = !!state.message;

    if (isEmailValid && nameExists && messageExists) return true;

    setState({
      errors: {
        name: !nameExists ? "Name is required" : null,
        email: !isEmailValid ? "Email is invalid" : null,
        message: !messageExists ? "Message is required" : null,
      },
    });
  };

  const onSuccess = () => {
    setState({ success: true });
  };

  const contactRequest = () => {
    if (validateFields()) {
      props.contactUs(state.name, state.email, state.message, onSuccess);
    }
  };

  return (
    <Layout center background={background}>
      <div className="login-box contact-box">
        <PageHeader title="Contact Us" />
        <Input
          disabled={props.loading}
          error={state.errors.name}
          label="Name"
          placeholder="Your Name"
          type="text"
          value={state.name}
          onChange={(e) => updateField(e, "name")}
        />
        <Input
          disabled={props.loading}
          error={state.errors.email}
          label="Email"
          placeholder="Your Email"
          type="email"
          value={state.email}
          onChange={(e) => updateField(e, "email")}
        />
        <Input
          textarea
          rows={7}
          disabled={props.loading}
          error={state.errors.message}
          label="Message"
          placeholder="Want to share your plant-powered results? Have a question? Just want to say hello? Write your message here!"
          type="text"
          value={state.message}
          onChange={(e) => updateField(e, "message")}
        />
        <Button
          className="action-button"
          disabled={props.loading || state.success}
          primary={state.success}
          limited
          onClick={contactRequest}
        >
          {state.success && <img src={check} alt="Sent" />}
          {buttonText}
        </Button>
        <p className="error">{props.error}</p>
        <div className="socials">
          <PageHeader title="Find us here" />
          <div className="links">
            <a
              href="https://www.facebook.com/simplegreensmoothies"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <img src={fb} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/simplegreensmoothies/"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <img src={instagram} alt="Instagram" />
            </a>
            <a
              href="https://www.youtube.com/user/SimpleGreenSmoothies"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <img src={youtube} alt="Youtube" />
            </a>
            <a
              href="https://www.pinterest.com/simplesmoothies/"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <img src={pinterest} alt="Pinterest" />
            </a>
            <a
              href="https://simplegreensmoothies.com"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <img src={web} alt="Web" />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading["CONTACT_US"],
  error: state.error["CONTACT_US"],
});

const mapDispatchToProps = (dispatch) => ({
  contactUs: (name, email, message, onSuccess) =>
    dispatch({ type: "CONTACT_US", name, email, message, onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
