import { call, put, takeLatest } from "redux-saga/effects";
import { actions as EventsActions } from '../../store/events/reducer';
import { callGetEvents } from '../../api/events';

export function* getEvents(action) {
  try {
    yield put({ type: "GET_EVENTS_REQUEST" });
    const response = yield call(callGetEvents);

    yield put(EventsActions.setEvents(response.data));

    yield put({ type: "GET_EVENTS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_EVENTS_FAILURE",
      error: error.message
    });
  }
}

const authSaga = [
  takeLatest("GET_EVENTS", getEvents),
];

export default authSaga;
