import React, { useState, useEffect } from "react";
import { countries } from "countries-list";

import ClickOutside from "react-click-outside";
import cx from "classnames";
import Collapse from "react-collapse";

const CountrySelect = props => {
  const [isOpened, setIsOpened] = useState(false);
  // TODO: Unused values. Is it a used value and component
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (props.value) setInputValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!props.value) props.onChange("United States", "US");
  }, []);

  const open = () => setIsOpened(true);
  const close = () => setIsOpened(false);

  // const handleInputChange = (e) => {
  //   e.preventDefault();
  //   open();
  //   setInputValue(e.target.value);
  // };

  const handleSelect = (country, countryCode) => {
    setIsOpened(false);
    setInputValue(country);

    props.onChange(country, countryCode);
  };

  return (
    <div className={`country-select ${props.className}`}>
      <ClickOutside onClickOutside={close}>
        <p className="label">{props.label}</p>
        <div
          className={cx("select-container", {
            open: isOpened,
            hoverable: props.hoverable
          })}
        >
          <div className="value-container" onClick={isOpened ? close : open}>
            <input
              value={props.value}
              placeholder={props.placeholder}
              onChange={e => {
                const value = e.target.value;
                setInputValue(value);
                props.onChangeInputValue?.(value);
                open();
              }}
            />
          </div>
          <Collapse isOpened={isOpened}>
            <div className="options">
              {Object.keys(countries)
                .sort((a, b) =>
                  countries[a].name >= countries[b].name ? 1 : -1
                )
                .slice(0, Object.keys(countries).length - 1)
                .filter(code => {
                  return countries[code]?.name
                    ?.toLowerCase()
                    .includes(inputValue.toLowerCase());
                })
                .map(code => (
                  <div
                    key={countries[code].name}
                    className="option"
                    onClick={() => handleSelect(countries[code].name, code)}
                  >
                    {countries[code].name}
                  </div>
                ))}
            </div>
          </Collapse>
        </div>
        <div className="error">{props.error}</div>
      </ClickOutside>
    </div>
  );
};

export default CountrySelect;
