import React, { useEffect } from 'react';

import { callCreatePortalSession } from '../api/subscriptions';
import { googleAnalitics, CATEGORY_TYPES, ACTION_TYPES } from '../utils/GoogleAnalitics';

const UpgradePlan = () => {
  const gotoPortal = async () => {
    try {
      const res = await callCreatePortalSession('/success-upgrade');
      window.open(res.data.url, '_self');
    } catch (e) {
      console.error('[subscription.error]',e);
    }
  }

  useEffect(() => {
    googleAnalitics.logEvent({
      category: CATEGORY_TYPES.subscription,
      action: ACTION_TYPES.ClickedTrialUpgradeLink
    });

    gotoPortal();
  }, []);

  return <div>Redirecting...</div>
};

export default UpgradePlan;
