import React from 'react';

import location from '../../../../assets/images/common/location-m.svg';
import user from '../../../../assets/images/user.svg';

export type Props = {
  profileImage?: string,
  since: string,
  firstName: string,
  lastName: string,
  country?: string,
  passions: string[],
  location: any,
  countryCode: string,
};

const User = (props: Props) => {
  return (
    <div className="rawkstar-user">
      <div className="photo-container">
        <div className="photo" style={{ backgroundImage: `url(${props.profileImage || user})`}} />
        <div className="badge">since {props.since}</div>
      </div>
      <div className="info-container">
        <p className="name">{props.firstName} {props.lastName.substring(0,1).toUpperCase()}.</p>
        {props.location && (
          <p className="location text-muted">
            <img src={location} alt="Location" />
            {props.location}, {props.countryCode}
          </p>
        )}
      </div>
    </div>
  );
}

export default User;
