import createSagaMiddleware from "redux-saga";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { WITH_REDUX_LOGGER } from "config";

import rootReducer from "./rootReducer";
import sagas from "../sagas";
import Cookies from "js-cookie";
import { actions as SessionActions } from "./session/reducer";

const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  collapsed: true,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session", "user", "subscriptions", "ingredients"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleWare = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: false,
    }).prepend(sagaMiddleware);

    if (!WITH_REDUX_LOGGER) {
      return defaultMiddleWare;
    }

    return defaultMiddleWare.concat(logger);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// @ts-ignore
export const persistor = persistStore(store, {}, () => {
  const loginAsTokenCookieName = "rawk_loginAsToken";
  const loginAsToken = Cookies.get(loginAsTokenCookieName);
  if (loginAsToken) {
    const domain = new URL(window.location.href).hostname;
    const rootDomain = domain
      .split(".")
      .reverse()
      .splice(0, 2)
      .reverse()
      .join(".");
    Cookies.remove(loginAsTokenCookieName, { domain: rootDomain });
    store.dispatch({
      type: "LOGIN_AS",
      loginAsToken
    });
  }
});

sagaMiddleware.run(sagas);
